import React from 'react';
import '../App.css';
import './LocationBody.css';


function LocationBody() {
    
    // const loca_img_01 = require("../images/Location/Cleaning and Laundry Services.png");
    // const loca_img_02 = require("../images/Location/Professional Laundry Shop.png");

    return (
        <div className='wrap-locationbody'>

            <div className="loca-heading">
                <h2>We Provides Dry Cleaning & Laundry Service in PJ and KL</h2>
                <p>We are a one-stop clothing cleaning and care brand offering a full-fledged laundry service tailored to your needs, from wash & fold, professional dry cleaning to special fabrics cleaning. We are
                    currently expanding our services in Klang Valley so that everyone has a chance to provide their beloved items with a “laundry-cation” with our signature cleaning liquid and service — so you can
                    have a sparkling clean and refreshed laundry.</p>
            </div>

            <div className="loca-body-container-1">
                <div className='loca-body-heading'>
                    <h3>Klang Valley</h3>
                </div>

                <div className='loca-info-container'>
                    <div className='loca-info-cards'>
                        <div className='loca-info-heading'>
                            <h4>Wash La! Operations Sdn Bhd</h4>
                            <p>Petaling Jaya</p>
                        </div>

                        <div className='loca-info-contents'>
                            <div className='loca-info-desc'>
                                <span aria-hidden="true" data-icon="&#xe90b;" className='loca-contact-icon'></span>
                                {/* <span className='loca-contact-icon'><i className='bi bi-telephone-outbound-fill' ></i></span> */}
                                <a href='tel:+60146222371' rel='noreferrer noopener'>+6014-6222371</a>
                            </div>

                            <div className='loca-info-desc'>
                                <span aria-hidden="true" data-icon="&#xe909;" className='loca-contact-icon'></span>
                                {/* <span className='loca-contact-icon'><i className='bi bi-geo-alt-fill' ></i></span> */}
                                <p>60 Jalan SS2/72, SS2, 47300 Petaling Jaya, Selangor </p>
                            </div>
                        </div>
                    </div>

                    {/* Puchong Address
                    <div className='loca-info-cards'>
                        <div className='loca-info-heading'>
                            <h3>Wash La! Sdn Bhd</h3>
                            <p>Puchong</p>
                        </div>

                        <div className='loca-info-contents'>
                            <div className='loca-info-desc'>
                                <span aria-hidden="true" data-icon="&#xe90b;" className='loca-contact-icon'></span>
                                <a href='tel:+60178469472'>+6017-8469472</a>
                            </div>

                            <div className='loca-info-desc'>
                                <span aria-hidden="true" data-icon="&#xe909;" className='loca-contact-icon'></span>
                                <p>15G, Jalan Kenari 3, Bandar Puchong Jaya 47100 Puchong, Selangor Puchong, 47100 Selangor </p>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>
            
            <div className="loca-body-container-2">
                <div className="loca-content-1">
                    <div className="loca-cont-1-item-1">
                        <img src="assets/images/Location/Cleaning and Laundry Services.png" alt="Cleaning and Laundry Services" className="loca-content-img-01"/>
                    </div>
                    
                    <div className="loca-cont-1-item-2">
                        <h2>Most Convenient Dry Cleaning and Laundry Services</h2>
                        <div>
                            <p>No time to do your laundry?</p>
                            <p>Lazy to drive out to the laundry shop to send your laundry?</p>
                            <p>Not sure how to clean your favorite pair of sneakers?</p>
                            <p>Need to dry clean your evening gown?</p>
                        </div>
                        <div>
                            <p>Wash La! is here ever-ready for you. You can rely on us for we are the laundry cleaning experts that save your time, anytime anywhere in and around Petaling Jaya and Kuala Lumpur.</p>
                        </div>
                        <div>
                            <p>With just a few clicks on our mobile app (available on iOS and Android), you can book an on-demand door-to-door pickup and delivery service for your laundry without having to step out of your home.</p>
                        </div>
                        <div>
                            <p>It is the easiest and fastest way to get your laundry cleaned. That is why our clients absolutely adore us for the superb experience!</p>
                        </div>
                    </div>
                </div>
                
                <div className="loca-content-2">
                    <div className="loca-cont-2-item-1">
                        <h2>A Professional Laundry Shop With 10 Years of Experience</h2>
                        <p>We are a full-fledged laundry shop with more than 10 years of experience and equipped with professional skills to ensure all your iteam and garments are handled and cared for in the manner they deserve!</p>
                        <p>Rest assured, our key employees were regularly trained by our in-house specialists in order to
                            ensure standards of the highest quality when it comes to cleaning and caring for your items. At our
                            headquarters in Petaling Jaya, we only employ the most modern technologies and techniques
                            because your items are worth it! You can now enjoy the benefit of our full range service. Pickup &
                            Delivery is available for our riders will come to your doorstep! covered within these area:
                        </p>
                        <ul>
                            <li>Kuala Lumpur</li>
                            <li>Klang</li>
                            <li>Kajang</li>
                            <li>Subang Jaya</li>
                            <li>Petaling Jaya</li>
                            <li>Selayang</li>
                            <li>Shah Alam</li>
                            <li>Ampang Jaya</li>
                            <li>Putrajaya</li>
                            <li>Sepang</li>
                        </ul>
                    </div>
                    
                    <div className="loca-cont-2-item-2">
                        <img src="assets/images/Location/Professional Laundry Shop.png" alt="Professional Laundry Shop" className="loca-content-img-02"/>
                    </div>
                </div>
            
            </div>
            
        </div>
    );
}

export default LocationBody;