import React, { useEffect } from 'react';
import '../../App.css';
import ContactUs from '../ContactUs';
import TermsOfUseBody from '../TermsOfUseBody';
import DocumentMeta from "react-document-meta";

function TermsOfUse() {

    useEffect(() => {
        document.title = "Terms of Use | Wash La!";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/terms-of-use"
            }
        },

        meta: {
            name: {
                title: "Terms of Use | Wash La!",                
                description: "By using any WashLa! services, you had acknowledged and accepted the practices and policies outlined below as described in this Terms of Use.",
                canonical:"https://washla.my/terms-of-use",
                keywords: "clean, cleaning, cleaning service, bag, restoration, delivery, privacy, refund, policy, privacy policy, data, personal data, tracking, data security",
            },
            property: {
                "og:title": "Terms of Use | Wash La!",
                "og:description": "By using any WashLa! services, you had acknowledged and accepted the practices and policies outlined below as described in this Terms of Use.",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <TermsOfUseBody />
            <ContactUs />    
        </>
    );
}

export default TermsOfUse;