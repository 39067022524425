import React from 'react';
import '../App.css';
import './PrivacyBody.css';



function PrivacyBody () {

    return (

        <div className='wrap-privacy'>
            <div className='priv-heading-container'>
                <div className='priv-heading'>
                    <h1>Privacy Policy</h1>
                </div>
                <div className='priv-date-eff'>
                    <p>Effective Date: July 1, 2021</p>
                </div>
                <div className='priv-heading-desc'>
                    <p>At Wash La!, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data. By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will collect, use and share your information as described in this Privacy Policy.</p>
                    <p>Remember that your use of Wash La!'s Services is at all times subject to our Terms of Use, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the <span className="priv-desc-style"><a href='/terms-of-use' target='_parent' rel='noreferrer'> Terms of Use.</a></span></p>
                    <p>You may <button className="priv-desc-style" onClick={() => window.print()} >print a copy of this Privacy Policy here.</button> If you have a disability, you may access this Privacy Policy in an alternative format by contacting <span className="priv-desc-style"><a href = 'mailto:cs@washla.my'>cs@washla.my</a></span></p>
                </div>
            </div>

            <div className='priv-table-container'>
                <div className='priv-tablecontent'>
                    <h2>Privacy Policy Table of Contents</h2>
                </div>

                <div className='priv-tablecontent-list'>
                    <ol>
                        <li><a href='/privacy-policy/#privpolicy-1' target='_top'>What this Privacy Policy Covers</a></li>
                        <li><a href='/privacy-policy/#privpolicy-2' target='_top'>Personal Data</a>
                            <ol>
                                <li><a href='/privacy-policy/#privpolicy-2-01' target='_top'>Categories of Personal Data We Collect</a></li>
                                <li><a href='/privacy-policy/#privpolicy-2-02' target='_top'>Categories of Sources of Personal Data</a></li>
                                <li><a href='/privacy-policy/#privpolicy-2-03' target='_top'>Our Commercial or Business Purposes for Collecting Personal Data</a></li>
                            </ol>
                        </li>
                        <li><a href='/privacy-policy/#privpolicy-3' target='_top'>How We Share Your Personal Data</a>
                            <ol>
                                <li><a href='/privacy-policy/#privpolicy-3-01' target='_top'>Legal Obligations</a></li>
                                <li><a href='/privacy-policy/#privpolicy-3-02' target='_top'>Business Transfers</a></li>
                                <li><a href='/privacy-policy/#privpolicy-3-03' target='_top'>Data That Is Not Personal Data</a></li>
                            </ol>
                        </li>
                        <li><a href='/privacy-policy/#privpolicy-4' target='_top'>Data Deletion</a> </li>
                        <li><a href='/privacy-policy/#privpolicy-5' target='_top'>Tracking Tools, Advertising and Opt-Out</a>
                            <ol>
                                <li><a href='/privacy-policy/#privpolicy-5-01' target='_top'>Information about Interest-Based Advertisements</a></li>
                                <li><a href='/privacy-policy/#privpolicy-5-02' target='_top'>Opt-out Choices</a></li>
                            </ol>
                        </li>
                        <li><a href='/privacy-policy/#privpolicy-6' target='_top'>Data Security and Retention</a></li>
                        <li><a href='/privacy-policy/#privpolicy-7' target='_top'>Personal Data of Children</a></li>
                        <li><a href='/privacy-policy/#privpolicy-8' target='_top'>Changes to this Privacy Policy</a></li>
                        <li><a href='/privacy-policy/#privpolicy-9' target='_top'>Contact Information</a></li>
                    </ol>
                </div>
            </div>

            <div className='priv-policy-container' id='privpolicy-1'>
                <div className='priv-policy-header'>
                    <h2>1. WHAT THIS PRIVACY POLICY COVERS</h2>
                </div>
                <div className='priv-policy-desc'>
                    <p>This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. “Personal Data” means any information that identifies or relates to a particular individual and also includes information referred to as “personally identifiable information” or “personal information” under applicable data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we don’t own or control or people we don’t manage.</p>
                </div>
            </div>

            <div className='priv-policy-container' id='privpolicy-2'>
                <div className='priv-policy-header'>
                    <h2>2. PERSONAL DATA</h2>
                </div>

                <div className='priv-policy-desc' id='privpolicy-2-01'>
                    <h4>2.1. CATEGORIES OF PERSONAL DATA WE COLLECT</h4>
                </div>
                <div className='priv-policy-desc'>
                    <h4>This chart details the categories of Personal Data that we collect and have collected over the past 12 months:</h4>
                </div>
                <div className='priv-policy-desc-2'>
                    <table className='priv-policy-wrap-table'>
                        {/* <colgroup>
                            <col width="20%">
                            <col span="3" width="33%">
                        </colgroup> */}
                        <tbody className='priv-policy-tbody'>
                            <tr className='priv-policy-tbody-header'>
                                <td>
                                    <p><strong>Category of Personal Data</strong></p>
                                </td>
                                <td>
                                    <p><strong>Examples of Personal Data We Collect</strong></p>
                                </td>
                                <td>
                                    <p><strong>Categories of Third Parties With Whom We Share this Personal Data:</strong></p>
                                </td>
                            </tr>
                            <tr className='priv-policy-tbody-content'>
                                <td className='pp-tbody-content-title'>
                                    <p>Profile or Contact Data</p>
                                </td>
                                <td>
                                    <ul>
                                        <li>First and last name</li>
                                        <li>Email</li>
                                        <li>Phone number</li>
                                        <li>Pick-up or Delivery address</li>
                                        <li>IP address</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners (such as Google and Facebook)</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className='priv-policy-tbody-content'>
                                <td className='pp-tbody-content-title'>
                                    <p>Payment Data</p>
                                </td>
                                <td>
                                    <ul>
                                        <li>Payment card type</li>
                                        <li>Last 4 digits of payment card</li>
                                        <li>CVV, expiration date, and billing address</li>
                                        <li>Phone number, and email</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Service Providers (specifically our payment processing partner, currently Braintree (offered by PayPal, Inc.))</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className='priv-policy-tbody-content'>
                                <td className='pp-tbody-content-title'>
                                    <p>Commercial Data</p>
                                </td>
                                <td>
                                    <ul>
                                        <li>Order history</li>
                                        <li>Consumer profiles</li>
                                        <li>Information about consumer’ garments and other items for Wash La!’s Cleaning Services</li>
                                        <li>Records of date for Wash La!’s Cleaning Services</li>
                                    </ul>
                                </td>
                                <td >
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className='priv-policy-tbody-content'>
                                <td className='pp-tbody-content-title'>
                                    <p>Device/IP Data</p>
                                </td>
                                <td >
                                    <ul>
                                        <li>IP address</li>
                                        <li>Device ID</li>
                                        <li>Type of device/operating system/browser used to access the Services</li>
                                    </ul>
                                </td>
                                <td >
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners (such as Google and Facebook)</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className='priv-policy-tbody-content'>
                                <td className='pp-tbody-content-title'>
                                    <p>Web Analytics</p>
                                </td>
                                <td >
                                    <ul>
                                        <li>Web page interactions</li>
                                        <li>Referring webpage/source through which you accessed the Services</li>
                                        <li>Non-identifiable request IDs</li>
                                        <li>Statistics associated with the interaction between device or browser and the Services</li>
                                    </ul>
                                </td>
                                <td >
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners (such as Google and Facebook)</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className='priv-policy-tbody-content'>
                                <td className='pp-tbody-content-title'>
                                    <p>Social Network Data</p>
                                </td>
                                <td >
                                    <ul>
                                        <li>Email</li>
                                        <li>Phone number</li>
                                        <li>User name</li>
                                        <li>IP address</li>
                                        <li>Device ID</li>
                                    </ul>
                                </td>
                                <td >
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners (such as Google and Facebook)</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className='priv-policy-tbody-content'>
                                <td className='pp-tbody-content-title'>
                                    <p>Consumer Demographic Data</p>
                                </td>
                                <td >
                                    <ul>
                                        <li>ZIP code</li>
                                    </ul>
                                </td>
                                <td >
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className='priv-policy-tbody-content'>
                                <td className='pp-tbody-content-title'>
                                    <p>Geolocation Data</p>
                                </td>
                                <td >
                                    <ul>
                                        <li>Pick-up or Delivery address</li>
                                        <li>IP-address-based location information</li>
                                    </ul>
                                </td>
                                <td >
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className='priv-policy-tbody-content'>
                                <td className='pp-tbody-content-title'>
                                    <p>Internet or other similar network activity information</p>
                                </td>
                                <td >
                                    <ul>
                                        <li>information on a consumer's interaction with website(s), application(s) or advertisement</li>
                                    </ul>
                                </td>
                                <td >
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className='priv-policy-tbody-content'>
                                <td className='pp-tbody-content-title'>
                                    <p>Other Identifying Information that You Voluntarily Choose to Provide</p>
                                </td>
                                <td >
                                    <ul>
                                        <li>Identifying information in text messages, emails or letters you send us</li>
                                    </ul>
                                </td>
                                <td >
                                    <ul>
                                        <li>Service Providers</li>
                                        <li>Advertising Partners</li>
                                        <li>Parties You Authorize, Access or Authenticate</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='priv-policy-desc' id='privpolicy-2-02'>
                    <h4>2.2. CATEGORIES OF SOURCES OF PERSONAL DATA</h4>
                </div>

                <div className='priv-policy-desc'>
                    <h4>We collect Personal Data about you from the following categories of sources:</h4>
                    
                    <ul className='priv-policy-list-2-1'>
                        <li><span className='priv-policy-list-2-1-bold'>You</span></li>
                        <ul>
                            <li><strong>When you provide such information directly to us.</strong></li>
                            <ul>
                                <li>When you create an account or use our interactive tools and Services.</li>
                                <li>When you place an order for the Cleaning Services, we collect payment data, depending on the form of payment you choose.</li>
                                <li>When you send in your garments or other items for the Cleaning Services, we may collect information on such items, including type, service requested, brand, color, size, and photos.</li>
                                <li>When you voluntarily provide information in text messages or in free-form text boxes through the Services or through responses to surveys or questionnaires.</li>
                                <li>When you choose to refer a friend through the Services, we will ask for your friend's contact information.</li>
                                <li>When you send us an email or otherwise contact us.</li>
                            </ul>
                            <li><strong>When you use the Services and such information is collected automatically.</strong></li>
                            <ul>
                                <li>Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section below).</li>
                                <li>If you download our mobile application or use a location-enabled browser, we may receive information about your location and mobile device, as applicable.</li>
                                <li>If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.</li>
                            </ul>
                        </ul>
                    </ul>
                    <ul className='priv-policy-list-2-1'>
                        <li><span className='priv-policy-list-2-1-bold'>Third Parties</span></li>
                        <ul>
                            <li><strong>Vendors</strong></li>
                            <ul>
                                <li>We may use analytics providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support.</li>
                                <li>We may use vendors to obtain information to generate leads and create user profiles.</li>
                            </ul>
                            <li><strong>Advertising Partners</strong></li>
                            <ul>
                                <li>We receive information about you from some of our vendors who assist us with marketing or promotional services for the Wash La! Services related to how you interact with our websites, applications, products, Services, advertisements or communications.</li>
                            </ul>
                            <li><strong>Social Networks</strong></li>
                            <ul>
                                <li>If you provide your social network account credentials to us or otherwise sign in to the Services through a third-party site or service, some content and/or information in those accounts may be transmitted into your account with us.</li>
                            </ul>
                        </ul>
                    </ul>
                    
                </div>

                <div className='priv-policy-desc' id='privpolicy-2-03'>
                    <h4>2.3. OUR COMMERCIAL OR BUSINESS PURPOSES FOR COLLECTING PERSONAL DATA? </h4>
                </div>

                <div className='priv-policy-desc'>
                    <ul className='priv-policy-list-2-1'>
                        <li><strong>Providing, Customizing and Improving the Services</strong></li>
                        <ul>
                            <li>Creating and managing your account or other user profiles</li>
                            <li>Processing orders or other transactions; billing</li>
                            <li>Providing you with the Cleaning Services, or other products, services or information you request</li>
                            <li>Meeting or fulfilling the reason you provided the information to us, such as to message to your friend inviting him or her to sign-up for the Service</li>
                            <li>Providing support and assistance for the Services</li>
                            <li>Analyzing trends and consumers’ interactions with the Services</li>
                            <li>Improving the Services, including testing, research, internal analytics and product development</li>
                            <li>Personalizing the Services, website content and communications based on your preferences</li>
                            <li>Doing fraud protection, security and debugging</li>
                            <li>Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws</li>
                        </ul>
                        <li><strong>Marketing the Services</strong></li>
                        <ul>
                            <li>Marketing and selling the Services</li>
                            <li>Providing you with customized offers or promotions that we think might be interested to you</li>
                            <li>Showing you advertisements, including interest-based or online behavioral advertising, and retargeted online and mobile advertisements to you across computers or devices you may use</li>
                        </ul>
                        <li><strong>Corresponding with You</strong></li>
                        <ul>
                            <li>Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information about Wash La! or the Services</li>
                            <li>Sending emails and other communications according to your preferences or that display content that we think will interest you</li>
                        </ul>
                        <li><strong>Meeting Legal Requirements and Enforcing Legal Terms</strong></li>
                        <ul>
                            <li>Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities</li>
                            <li>Protecting the rights, property or safety of you, Wash La! or another party</li>
                            <li>Enforcing any agreements with you</li>
                            <li>Responding to claims that any posting or other content violates third-party rights</li>
                            <li>Resolving disputes</li>
                        </ul>
                    </ul>
                    <p>We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or incompatible purposes without providing you notice.</p>
                </div>

            </div>


            <div className='priv-policy-container' id='privpolicy-3'>
                <div className='priv-policy-header'>
                    <h2>3. HOW WE SHARE YOUR PERSONAL DATA </h2>
                </div>
                <div className='priv-policy-desc'>
                    <p>We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a “sale” of your Personal Data. For more information, please refer to the state-specific sections below.</p>
                    
                    <ul className='priv-policy-list-2-1'>
                        <li><strong>Service Providers.</strong>&nbsp;These parties help us provide the Services or perform business functions on our behalf. They include:</li>
                        <ul>
                            <li>Hosting, technology and communication providers, such as AWS</li>
                            <li>Security and fraud prevention consultants</li>
                            <li>Analytics providers, such as Google Analytics and Facebook Pixel</li>
                            <li>Support and customer service vendors</li>
                            <li>Product fulfillment and delivery providers</li>
                            <li>Payment processors</li>
                            <ul>
                                <li>Our payment processing partner ipay88 (“ipay88”) collects your voluntarily-provided payment card information necessary to process your payment.</li>
                                <li>Please see ipay88’s terms of service and privacy policy for information on its use and storage of your Personal Data.</li>
                            </ul>
                        </ul>
                        <li><strong>Advertising Partners.</strong>&nbsp;These parties help us market our services and provide you with other offers that may be of interest to you. They include:</li>
                        <ul>
                            <li>Ad networks</li>
                            <li>Data brokers</li>
                            <li>Marketing providers</li>
                        </ul>
                        <li><strong>Parties You Authorize, Access or Authenticate</strong></li>
                        <ul>
                            <li>Third parties you access through the services</li>
                            <li>Social media services</li>
                        </ul>
                    </ul>
                </div>

                <div className='priv-policy-desc' id='privpolicy-3-01'>
                    <h4>3.1. LEGAL OBLIGATIONS </h4>
                    <p>We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under “Meeting Legal Requirements and Enforcing Legal Terms” in the “Our Commercial or Business Purposes for Collecting Personal Data” section above.</p>
                </div>

                <div className='priv-policy-desc' id='privpolicy-3-02'>
                    <h4>3.2. BUSINESS TRANSFERS </h4>
                    <p>All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.</p>
                </div>

                <div className='priv-policy-desc' id='privpolicy-3-03'>
                    <h4>3.3. DATA THAT IS NOT PERSONAL DATA </h4>
                    <p>We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and share it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, provided that we will not share such data in a manner that could identify you.</p>
                </div>

            </div>

            <div className='priv-policy-container'  id='privpolicy-4'>
                <div className='priv-policy-header'>
                    <h2>4. DATA DELETION </h2>
                </div>
                <div className='priv-policy-desc'>
                    <p>Users of Wash La! mobile app can delete data upon request (via email to <a href="mailto:cs@washla.my">cs@washla.my</a>) whenever they wish. This data will be deleted from the system immediately and cannot be recovered by any users or Wash La employees after this point. Data which has been deleted or otherwise destroyed can not be recovered at any time. Sufficient warning is given to the account holder before data are permanently deleted.</p>
                </div>
            </div>

            <div className='priv-policy-container' id='privpolicy-5'>
                <div className='priv-policy-header'>
                    <h2>5. TRACKING TOOLS, ADVERTISING AND OPT-OUT </h2>
                </div>
                <div className='priv-policy-desc'>
                    <p>The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and when you visit and use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data– usually text files – placed on your computer, tablet, phone or similar device when you use that device to access our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Services do not support “Do Not Track” requests sent from a browser at this time.</p>
                    
                    <p>We use the following types of Cookies:</p>
                    <ul className='priv-policy-list-2-1'>
                        <li><strong>Essential Cookies.</strong> Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable.</li>
                        <li><strong>Functional Cookies.</strong> Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to our Services. These Cookies help us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
                        <li><strong>Performance/Analytical Cookies.</strong> Performance/Analytical Cookies allow us to understand how visitors use our Services. They do this by collecting information about the number of visitors to the Services, what pages visitors view on our Services and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services’ content for those who engage with our advertising. For example, Google Inc. (“Google”) uses cookies in connection with its Google Analytics services. Google’s ability to use and share information collected by Google Analytics about your visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google’s use of Cookies by visiting the Google advertising opt-out page at <span className='p-links-desc-span-1'><a href="https://www.google.com/privacy_ads.html" target='_blank' rel="noreferrer nofollow"><strong>www.google.com/privacy_ads.html</strong></a></span> or the Google Analytics Opt-out Browser Add-on a <span className='p-links-desc-span-1'><a href="https://tools.google.com/dlpage/gaoptout/" target='_blank' rel="noreferrer nofollow">https://tools.google.com/dlpage/gaoptout/</a></span>.</li>
                        <li><strong>Retargeting/Advertising Cookies.</strong> Retargeting/Advertising Cookies collect data about your online activity and identify your interests so that we can provide advertising that we believe is relevant to you. For more information about this, please see the section below titled “Information about Interest-Based Advertisements.”</li>
                    </ul>
                    <p>You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your device. If you do this, however, you may have to manually adjust some preferences every time you visit our website and some of the Services and functionalities may not work.</p>
                    <p>To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your browser’s menu. To find out more information about Cookies, including information about how to manage and delete Cookies, please visit <span className='p-links-desc-span-1'><a href="http://www.allaboutcookies.org/" target='_blank' rel="noreferrer noopener">http://www.allaboutcookies.org/</a></span>.</p>
                </div>

                <div className='priv-policy-desc' id='privpolicy-5-01'>
                    <h4>5.1. INFORMATION ABOUT INTEREST-BASED ADVERTISEMENTS </h4>
                    <p>We may serve advertisements, and also allow third-party ad networks, including third-party ad servers, ad agencies, ad technology vendors and research firms, to serve advertisements through the Services. These advertisements may be targeted to users who fit certain general profile categories or display certain preferences or behaviors (“Interest-Based Ads”). Information for Interest-Based Ads (including Personal Data) may be provided to us by you, or derived from the usage patterns of particular users on the Services and/or services of third parties. Such information may be gathered through tracking users’ activities across time and unaffiliated properties, including when you leave the Services. To accomplish this, we or our service providers may deliver Cookies, including a file (known as a “web beacon”) from an ad network to you through the Services. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Web beacons allow ad networks to view, edit or set their own Cookies on your browser, just as if you had requested a web page from their site.</p>
                </div>

                <div className='priv-policy-desc' id='privpolicy-5-02'>
                    <h4>5.2. OPT-OUT CHOICES </h4>
                    <p>We comply with the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for Online Behavioral Advertising. Through the DAA and Network Advertising Initiative (“NAI”), several media and marketing associations have developed an industry self-regulatory program to give consumers a better understanding of, and greater control over, ads that are customized based a consumer’s online behavior across different websites and properties. To make choices about Interest-Based Ads from participating third parties, including to opt-out of receiving behaviorally targeted advertisements from participating organizations, please visit the DAA’s or NAI’s consumer opt-out pages, which are located at <span className='p-links-desc-span-1'><a href="http://www.networkadvertising.org/choices" target='_blank' rel="noreferrer noopener">http://www.networkadvertising.org/choices</a></span> or <span className='p-links-desc-span-1'><a href="https://www.aboutads.info/choices" target='_blank' rel="noreferrer noopener">www.aboutads.info/choices</a></span>. To opt-out of the use of your mobile device ID for targeted advertising, see&nbsp;<a href="http://www.aboutads.info/appchoices" target='_blank' rel="noreferrer noopener"><strong>http://www.aboutads.info/appchoices</strong></a>.</p>
                    <p>We provide our consumers with the opportunity to opt-out of having their information used for purposes not directly related to placement, processing, fulfilment, or delivery of a product or service. To opt-out of marketing communications, you may use one of these convenient methods:</p>
                    <ul className='priv-policy-list-2-1'>
                        <li><strong><em>Electronic Promotional Offers.</em></strong>&nbsp;If you do not want to receive emails from us regarding special promotions or offers, you may click the unsubscribe link in the footer of any email, or contact us at&nbsp;<a href="mailto:cs@washla.my" target='_blank' rel="noreferrer noopener"><strong>cs@washla.my</strong></a>&nbsp;with “Unsubscribe Promotional Offers” in the subject line.</li>
                        <li><strong><em>Mobile Promotional Offers.</em></strong>&nbsp;When you provide us with your mobile number for marketing purposes, we may send you certain marketing alerts via text message. Standard data and message rates will apply. If you no longer wish to receive mobile alerts from us, you can follow the instructions provided in those messages or otherwise reply “STOP” to any Wash La! alert.</li>
                    </ul>
                    <p>Your instructions to limit the use of your information for these purposes will be processed as soon as reasonably practicable. Additionally, we are not responsible for informing third parties (including without limitation our third party service providers or partners) with whom we have already shared your personal information of any changes requested pursuant to this section, or for removing information from or causing information to be removed from the databases or records of such entities.</p>
                </div>
            </div>

            <div className='priv-policy-container'  id='privpolicy-6'>
                <div className='priv-policy-header'>
                    <h2>6. DATA SECURITY AND RETENTION </h2>
                </div>
                <div className='priv-policy-desc'>
                    <p>We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or storing data is completely secure.</p>
                    <p>We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you with our Services. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.</p>
                </div>
            </div>

            <div className='priv-policy-container'  id='privpolicy-7'>
                <div className='priv-policy-header'>
                    <h2>7. PERSONAL DATA OF CHILDREN </h2>
                </div>
                <div className='priv-policy-desc'>
                    <p>As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children under 16 years of age; if you are a child under the age of 16, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 16 years of age, we will delete that information as quickly as possible. If you believe that a child under 16 years of age may have provided Personal Data to us, please contact us at&nbsp;<a href="mailto:cs@washla.my"><strong>cs@washla.my</strong></a>.</p>
                </div>
            </div>

            <div className='priv-policy-container'  id='privpolicy-8'>
                <div className='priv-policy-header'>
                    <h2>8. CHANGES TO THIS PRIVACY POLICY </h2>
                </div>
                <div className='priv-policy-desc'>
                    <p>We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time, but we will alert you to any such changes by placing a notice on the Wash La! website, by sending you an email and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.</p>
                </div>
            </div>

            <div className='priv-policy-container'  id='privpolicy-9'>
                <div className='priv-policy-header'>
                    <h2>9. CONTACT INFORMATION </h2>
                </div>
                <div className='priv-policy-desc'>
                    <p>Email: <a href="mailto:cs@washla.my">cs@washla.my</a></p>
                    <p>Phone: <a href="tel:+60178469472">+6017-8469472</a></p>
                    <p>Website: <a href="https://washla.my/" target='_parent'>https://washla.my/</a></p>
                    <p>Address: <span className='p-links-desc-span-2'>60 Jalan SS2/72, SS2, 47300 Petaling Jaya, Selangor</span></p>
                </div>
            </div>

        </div>
    );
}

export default PrivacyBody;