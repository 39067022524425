import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import './ServicesBody.css';
import './SP All CSS.css';

// import ourservices_1 from '../images/ServicesMain/Sneaker-Care.jpg'; 

function OurServCards(props) {
    return (
        <>
            <li className='servcards_item'>
                <Link className='servcards_item__link' to={props.path}  target="_top">
                    <figure className='servcards_item_pic-wrap'>
                        <img
                            className='servcards_item_img'
                            src={props.imgsrc}
                            alt={props.imgalttext}
                        />
                    </figure>
                    <div className='servcards_item_info'>
                        <p className='servcards_item_title'>{props.title}</p>
                    </div>
                </Link>
            </li>
        </>
    );
}

function OurServChoose(props) {
    return (
        <>
            <div className='servchoose-container'>
                <div className="servchoose_content-1">
                    <img className="servchoose_img"
                        src={props.ch_imgsrc}
                        alt={props.ch_imgalttext}
                    />
                </div>

                <div className="servchoose_content-2">
                    <h3>{props.ch_title}</h3>
                    <p>{props.ch_desc}</p>
                </div>
            </div>
        </>
    );
}

function SPMainPage() {

    // const ourservices_1 = require('../images/ServicesMain/Sneaker-Care.jpg');
    // const ourservices_2 = require('../images/ServicesMain/wash-and-fold.jpg');
    // const ourservices_3 = require('../images/ServicesMain/dry-cleaning.jpg');
    // const ourservices_4 = require('../images/ServicesMain/bag-cleaning.jpg');
    // const ourservices_5 = require('../images/ServicesMain/carpet-cleaning.jpg');

    // const ourservices_6 = require('../images/ServicesMain/Best-Shoe-Cleaning-Service-1.png');
    // const ourservices_7 = require('../images/ServicesMain/Best-Shoe-Cleaning-Service-2.png');
    // const ourservices_8 = require('../images/ServicesMain/Best-Shoe-Cleaning-Service-3.png');
    // const ourservices_9 = require('../images/ServicesMain/Best-Shoe-Cleaning-Service-4.png');
    // const ourservices_10= require('../images/ServicesMain/Best-Shoe-Cleaning-Service-5.png');

    return (
        <>
            <div className='wrap-serviceshero'>
                <div className='servprichero-container'>
                    <div className='servpric-hero-heading'>
                        <h1>Services and Pricing</h1>
                    </div>

                    <div className='servpric-hero-desc'>
                        <p className='servpric-hero-desc-1'>We are Expert in Washing</p>
                        <p className='servpric-hero-desc-2'>Our in-house specialist will evaluate your shoes and ensure the services chosen are sufficient for the care of your shoes. If additional services are required, you will be contacted via WhatsApp or a direct call on your mobile.</p>
                    </div>
                </div>
            </div>

            <div className="wrap-serv-tabs">
                <div class="services-tabs">
                    <ol class="tab-list">
                        <a href="/services-and-pricing/" class="tab-list-item tab-list-active" target="_top">Our Services</a>
                        <a href="/services-and-pricing/sneaker-care" class="tab-list-item" target="_top">Sneaker Care</a>
                        <a href="/services-and-pricing/wash-and-fold" class="tab-list-item" target="_top">Wash & Fold</a>
                        <a href="/services-and-pricing/dry-cleaning" class="tab-list-item" target="_top">Dry Cleaning</a>
                        <a href="/services-and-pricing/bag-cleaning" class="tab-list-item" target="_top">Bag Cleaning</a>
                        <a href="/services-and-pricing/other-services" class="tab-list-item" target="_top">Other Services</a>
                    </ol>
                </div>
            </div>


            <div className='wrap-ourservices' id='ourservices'>
                <div className='serv-ourservices-banner'>
                    <div className='serv-body-heading'>
                        <h2>Shoe Cleaning & Sneaker Care Services in Petaling Jaya</h2>
                        <p>Wash La! has a team of in-house specialists and experts to provide you with the best shoe cleaning and sneaker care service in and around
                            Petaling Jaya and Kuala Lumpur. Our Wash La! team will give your shoe a good check to ensure the services chosen are sufficient for the care
                            of your shoes. If additional services are required, you will be contacted via WhatsApp or a direct call</p>               
                    </div>
                </div>

                <div className='serv-content-container'>
                    <h2>Our Services </h2>
                    <p>We provide a wide range of cleaning services apart from shoe cleaning and sneaker care to tailor to your needs. Anything about caps, bags and backpacks, We are your best bet！</p>

                    <div className='serv-cards_container'>
                        <div className='serv-cards__wrapper'>
                            <ul className='serv-cards_items'>
                                <OurServCards
                                path="/services-and-pricing/sneaker-care"
                                imgsrc="../assets/images/ServicesMain/Sneaker-Care.jpg"
                                imgalttext="Sneaker Care"
                                title='Sneaker Care'
                                />
                                <OurServCards
                                path="/services-and-pricing/wash-and-fold"
                                imgsrc="../assets/images/ServicesMain/wash-and-fold.jpg"
                                imgalttext="Wash and fold"
                                title='Wash & Fold'
                                />
                                <OurServCards
                                path="/services-and-pricing/dry-cleaning"
                                imgsrc="../assets/images/ServicesMain/dry-cleaning.jpg"
                                imgalttext="Dry cleaning"
                                title='Dry Cleaning'
                                />
                                <OurServCards
                                path="/services-and-pricing/bag-cleaning"
                                imgsrc="../assets/images/ServicesMain/bag-cleaning.jpg"
                                imgalttext="Bag cleaning"
                                title='Bag Cleaning'
                                />
                                <OurServCards
                                path="/services-and-pricing/other-services"
                                imgsrc="../assets/images/ServicesMain/carpet-cleaning.jpg"
                                imgalttext="Carpet cleaning"
                                title='Other Services'
                                />
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='serv-content-container'>
                    <h2>You Get The Best Shoe Cleaning & Sneaker Care Service Here </h2>
                    <p>We are a one-stop professional cleaning service ready to serve you and clean all your garments, shirt, shoes, caps, bags and backpacks the way it needs to with personalized care. We take great pride in providing high-quality cleaning, repair and restoration for every item you entrust us with.</p>

                    
                    <div className='serv-cards_container-2'>
                        <OurServChoose
                            ch_imgsrc="../assets/images/ServicesMain/Best-Shoe-Cleaning-Service-1.png"
                            ch_imgalttext="Best Shoe Cleaning Service 1"
                            ch_title="We Rely On Our Experience"
                            ch_desc="It takes experience to recognize a great cleaner. One of our co-founders has had years of experience in the washing and dry cleaning business."
                        />
                        <OurServChoose
                            ch_imgsrc="../assets/images/ServicesMain/Best-Shoe-Cleaning-Service-2.png"
                            ch_imgalttext="Best Shoe Cleaning Service 2"
                            ch_title="We work with the best"
                            ch_desc="We have a thorough vetting process and always make sure that our cleaning specialists are well-managed, environmentally conscious, and follow industry best practices."
                        />
                        <OurServChoose
                            ch_imgsrc="../assets/images/ServicesMain/Best-Shoe-Cleaning-Service-3.png"
                            ch_imgalttext="Best Shoe Cleaning Service 3"
                            ch_title="We provide constant feedback"
                            ch_desc="We work closely with all our cleaning suppliers and provide them with thorough, detailed, and actionable feedback to ensure that they deliver a consistently great product for our operations."
                        />
                        <OurServChoose
                            ch_imgsrc="../assets/images/ServicesMain/Best-Shoe-Cleaning-Service-4.png"
                            ch_imgalttext="Best Shoe Cleaning Service 4"
                            ch_title="We have quality built into our internal process"
                            ch_desc="No item can pass our “quality control” check and be returned to you without a member of our internal team reviewing it."
                        />
                        <OurServChoose
                            ch_imgsrc="../assets/images/ServicesMain/Best-Shoe-Cleaning-Service-5.png"
                            ch_imgalttext="Best Shoe Cleaning Service 5"
                            ch_title="We’ll do what it takes to get everything right"
                            ch_desc="We are confident your items will receive the best quality cleaning possible. If you find a stain that has slipped through the cracks or doesn’t meet our (or your) quality standards, you can email."
                        />
                    </div>
                </div>

            </div>

            
        </>
    
    );
}

export default SPMainPage;