import React, { useState } from 'react';
import { MenuItems } from './Navbar2dropMenuItem';
import './Navbar2.css';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavLink = styled(Link)`
    &.active {
        color: #012066;
        font-weight: bold;
    }

`;


function Dropdown() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>

      <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu1 clicked' : 'dropdown-menu1'}
      >

        {MenuItems.map((item, index) => {
          return (
            <li key={index} className='dropdown-link-items'>
                <NavLink
                    className={item.cName}
                    to={item.path}
                    onClick={() => setClick(false)}
                    target='_parent'
                >
                    {item.title}

                    <i className='fas fa-caret-right' />

                </NavLink>
            </li>
          );
        })}
      </ul>

    </>
  );
}

export default Dropdown;