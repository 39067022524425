import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';    /* Switch = Routes in react-router-dom v6 */

import Navbar2 from "./components/Navbar2";
import Home from "./components/pages/Home";
import Services from "./components/pages/Services"; /* Services */
import SPSneakerCare from "./components/pages/SP SneakerCare";
import SPWashAndFold from "./components/pages/SP WashFold";
import SPDryCleaning from "./components/pages/SP DryCleaning";
import SPBagCleaning from "./components/pages/SP BagCleaning";
import SPOtherServices from "./components/pages/SP OtherServ";
import Locations from "./components/pages/Locations";
import Careers from "./components/pages/Careers";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import RefundPolicy from "./components/pages/RefundPolicy";
import TermsOfUse from "./components/pages/TermsOfUse";
import FAQpage from "./components/pages/FAQpage";
import SchedulePickup from "./components/pages/ScheduleAPickupPage";
import BlogPage from "./components/pages/BlogPage";
import BlogPage01 from "./components/pages/BlogPage_1";
// import BlogPage02 from './components/pages/BlogPage_2';
import ContactPage from "./components/pages/ContactUsPage";
import Footer from "./components/Footer";
import NotFoundPage from "./components/pages/NotFoundPage";

import "bootstrap/dist/css/bootstrap.min.css";
import DataDeletion from "./components/pages/DataDeletion";

function App() {
  return (
    <>
      <Router>
        <Navbar2 />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services-and-pricing" element={<Services />} />
          <Route
            path="/services-and-pricing/sneaker-care"
            element={<SPSneakerCare />}
          />
          <Route
            path="/services-and-pricing/wash-and-fold"
            element={<SPWashAndFold />}
          />
          <Route
            path="/services-and-pricing/dry-cleaning"
            element={<SPDryCleaning />}
          />
          <Route
            path="/services-and-pricing/bag-cleaning"
            element={<SPBagCleaning />}
          />
          <Route
            path="/services-and-pricing/other-services"
            element={<SPOtherServices />}
          />

          <Route path="/locations" element={<Locations />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/faq" element={<FAQpage />} />
          <Route path="/schedule-a-pickup" element={<SchedulePickup />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route
            path="/blogs/6-steps-to-clean-your-gucci-purse-or-leather-bag"
            element={<BlogPage01 />}
          />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/data-deletion" element={<DataDeletion />} />

          <Route path="*" element={<NotFoundPage />} />
          <Route path="/assets" element={<NotFoundPage />} />
          <Route path="/assets/images" element={<NotFoundPage />} />
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;
