import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// const logo = require('../images/Home/Washla-logo.png');

function Footer() {
  return (
    <footer className="footer-container">
      <section className="footer-disclaimer">
        <p className="footer-disclaimer-heading">
          *The Washla One free trial includes only services that you are not
          currently using through a free trial or a subscription. Plan is
          automatically renewed after trial until cancelled. Restrictions and
          other terms apply. RM 19.90/month after free trial. One subscription
          per Family Sharing group. Offer is valid for three months after
          eligible device activation. Plan is automatically renewed until
          cancelled. Restrictions and other terms apply.
        </p>
      </section>
      <div className="Line"></div>
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-main-items">
            <Link to="/" className="footer-logo" target="_parent">
              <img
                src="../assets/images/Home/Washla-logo.png"
                width="120px"
                alt="Washla logo"
              />
            </Link>
            <h2>WASH LA SDN. BHD.</h2>
            <p>202101021098 (1421398-A)</p>

            <a
              href="https://www.myweekendplan.asia/top-8-best-shoe-cleaning-services-in-pj-and-kl-2022/#no1"
              target="_blank"
              rel="noreferrer"
            >
              Featured in: <br />
              <img
                src="../assets/images/myWeekendPlan.webp"
                width="150px"
                alt="myWeekendPlan WashLa"
              />
            </a>
          </div>

          <div className="footer-link-items">
            <h3>Company</h3>
            <Accordion id="serv-footer-accordion">
              <Accordion.Item className="serv-footer-accordion" eventKey="0">
                <Accordion.Header className="serv-footer-accordion-header">
                  <div className="serv-footer-link-items">
                    <p>Services & Pricing</p>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="serv-footer-link-items">
                    <Link
                      to="/services-and-pricing"
                      target="_parent"
                      rel="noreferrer noopener"
                    >
                      • Our Services
                    </Link>
                    <br />
                    <Link
                      to="/services-and-pricing/sneaker-care"
                      target="_parent"
                      rel="noreferrer noopener"
                    >
                      • Sneaker Care
                    </Link>
                    <br />
                    <Link
                      to="/services-and-pricing/wash-and-fold"
                      target="_parent"
                      rel="noreferrer noopener"
                    >
                      • Wash & Fold
                    </Link>
                    <br />
                    <Link
                      to="/services-and-pricing/dry-cleaning"
                      target="_parent"
                      rel="noreferrer noopener"
                    >
                      • Dry Cleaning
                    </Link>
                    <br />
                    <Link
                      to="/services-and-pricing/bag-cleaning"
                      target="_parent"
                      rel="noreferrer noopener"
                    >
                      • Bag Cleaning
                    </Link>
                    <br />
                    <Link
                      to="/services-and-pricing/other-services"
                      target="_parent"
                      rel="noreferrer noopener"
                    >
                      • Other Services
                    </Link>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Link to="/locations" target="_parent" rel="noreferrer noopener">
              Locations
            </Link>
            <Link
              to="/schedule-a-pickup"
              target="_parent"
              rel="noreferrer noopener"
            >
              Schedule a Pickup
            </Link>
            <Link to="/blogs" target="_parent" rel="noreferrer noopener">
              Blogs
            </Link>
          </div>

          <div className="footer-link-items">
            <h3>Policy</h3>
            <Link
              to="/privacy-policy"
              target="_parent"
              rel="noreferrer noopener"
            >
              Privacy Policy
            </Link>
            <Link to="/terms-of-use" target="_parent" rel="noreferrer noopener">
              Terms of Use
            </Link>
            <Link
              to="/refund-policy"
              target="_parent"
              rel="noreferrer noopener"
            >
              Sales & Refund
            </Link>
            <Link
              to="/data-deletion"
              target="_parent"
              rel="noreferrer noopener"
            >
              Data Deletion
            </Link>
            {/* <Link to='/'>Legal</Link> */}
          </div>

          <div className="footer-link-items">
            <h3>Connect</h3>
            <Link to="/contact-us" target="_parent" rel="noreferrer noopener">
              Contact Us
            </Link>
            {/* <Link to='/'>Blog</Link> */}
            <a
              href="https://www.instagram.com/washla.my/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.facebook.com/washla.my/?ref=py_c"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
            {/* <Link to='/'>Twitter</Link> */}
          </div>

          <div className="footer-link-items">
            <h3>More</h3>
            <Link to="/faq" target="_parent" rel="noreferrer noopener">
              FAQs
            </Link>
            <Link to="/careers" target="_parent" rel="noreferrer noopener">
              Careers
            </Link>
            <Link
              to="/services-and-pricing"
              target="_parent"
              rel="noreferrer noopener"
            >
              How it works
            </Link>
            <Link to="/#downloadapp" target="_parent" rel="noreferrer noopener">
              Download App
            </Link>
            {/* <Link to='/'>Guarantee</Link> */}
          </div>
        </div>
      </div>

      <div className="mobile-footer-links">
        <div className="footer-main-items">
          <Link
            to="/"
            className="footer-logo"
            target="_parent"
            rel="noreferrer noopener"
          >
            <img
              src="../assets/images/Home/Washla-logo.png"
              width="120px"
              alt="WashLa! logo"
            />
          </Link>
          <h2>WASH LA SDN. BHD.</h2>
          <p>202101021098 (1421398-A)</p>

          <a
            href="https://www.myweekendplan.asia/top-8-best-shoe-cleaning-services-in-pj-and-kl-2022/#no1"
            target="_blank"
            rel="noreferrer"
          >
            Featured in: <br />
            <img
              src="../assets/images/myWeekendPlan.webp"
              width="150px"
              alt="myWeekendPlan WashLa"
            />
          </a>
        </div>

        {/* MobileView Footer */}
        <Accordion id="mobile-footer-accordion">
          <Accordion.Item className="footer-accordion" eventKey="0">
            <Accordion.Header className="footer-accordion-header">
              <div className="footer-link-items">
                <h3>Company</h3>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="footer-link-items">
                <Link
                  to="/services-and-pricing"
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  Services & Pricing
                </Link>
                <Link
                  to="/services-and-pricing/sneaker-care"
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  Services & Pricing - Sneaker Care
                </Link>
                <Link
                  to="/services-and-pricing/wash-and-fold"
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  Services & Pricing - Wash & Fold
                </Link>
                <Link
                  to="/services-and-pricing/dry-cleaning"
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  Services & Pricing - Dry Cleaning
                </Link>
                <Link
                  to="/services-and-pricing/bag-cleaning"
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  Services & Pricing - Bag Cleaning
                </Link>
                <Link
                  to="/services-and-pricing/other-services"
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  Services & Pricing - Other Services
                </Link>
                <Link
                  to="/locations"
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  Locations
                </Link>
                <Link to="/schedule-a-pickup" target="_parent">
                  Schedule a Pickup
                </Link>
                <Link to="/blogs" target="_parent" rel="noreferrer noopener">
                  Blogs
                </Link>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="footer-accordion" eventKey="1">
            <Accordion.Header className="footer-accordion-header">
              <div className="footer-link-items">
                <h3>Policy</h3>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="footer-link-items">
                <Link to="/privacy-policy" target="_parent">
                  Privacy Policy
                </Link>
                <Link to="/terms-of-use" target="_parent">
                  Terms of Use
                </Link>
                <Link to="/refund-policy" target="_parent">
                  Sales & Refund
                </Link>
                <Link to="/data-deletion" target="_parent">
                  Data Deletion
                </Link>
                {/* <Link to='/'>Legal</Link> */}
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="footer-accordion" eventKey="2">
            <Accordion.Header className="footer-accordion-header">
              <div className="footer-link-items">
                <h3>Connect</h3>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="footer-link-items">
                <Link to="/contact-us" target="_parent">
                  Contact Us
                </Link>
                {/* <Link to='/'>Blog</Link> */}
                <a
                  href="https://www.instagram.com/washla.my/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
                <a
                  href="https://www.facebook.com/washla.my/?ref=py_c"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
                {/* <Link to='/'>Twitter</Link> */}
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="footer-accordion" eventKey="3">
            <Accordion.Header className="footer-accordion-header">
              <div className="footer-link-items">
                <h3>More</h3>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="footer-link-items">
                <Link to="/faq" target="_parent" rel="noreferrer noopener">
                  FAQs
                </Link>
                <Link to="/careers" target="_parent" rel="noreferrer noopener">
                  Careers
                </Link>
                <Link
                  to="/services-and-pricing"
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  How it works
                </Link>
                <Link
                  to="/#downloadapp"
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  Download App
                </Link>
                {/* <Link to='/'>Guarantee</Link> */}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="Line"></div>

      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo"></div>
          <p className="website-rights">
            Copyright © 2022 Washla. All rights reserved.
          </p>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
