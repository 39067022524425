import React from 'react';
import '../App.css';
import './CareersBody.css';


// const carlogo = require('../images/Home/Washla-logo.png');

function CareersBody () {

    return (

        <div className='wrap-careers'>
            <div className='car-heading-container'>
                <div className='car-heading'>
                    <h1>Careers</h1>
                </div>
                <div className='car-heading-desc'>
                    <p>Join the team of passionate people building the best brand in clothing and shoe care!</p>
                </div>
            </div>

            <div className='car-content-container'>
                <div className='car-content-logo'>
                    <img src="../assets/images/Home/Washla-logo.png" width="180px" alt="Washla logo"/>
                </div>
                
                <div className='car-content-desc'>
                    <h2>About WashLa!</h2>
                    <p>Wash La! was born out of laziness! Laziness by a group of sneaker lovers' OCD for cleaniness. The drag of bringing ourselves out from the house to the cleaners is only circumvented by our intense love for our holy grails.
                        While the retail market have various choices for cleaning services, none had a standalone app that would cater specifically for sneakers or provide customization on the services through one-click buttons, right up to your doorstep. 
                        And well, we all know what happened in 2019 and all that could go downhill, went downhill from then onwards. Consumer behavior shifted immediately to online services, as did us, as true mascots of laziness can testify.
                    </p>
                </div>

                <div className='car-cards-container'>
                    <div className='car-cards-item'>
                        <div className='car-info-cards'>
                            <div className='car-info-heading'>
                                <h3>Valet</h3>
                                <p>Wash La! Valet</p>
                            </div>

                            <div className='car-info-desc'>
                                <p>Unique opportunity for evening, part-time job with flexible scheduling. Help to deliver a seamless customer experience. Predictable shift-based income plus tips and mileage reimbursement.</p>
                            </div>
                        </div>

                        <div className='car-info-cards'>
                            <div className='car-info-heading'>
                                <h3>Operations</h3>
                                <p>Field Operations Associate</p>
                            </div>

                            <div className='car-info-desc'>
                                <p>As a member of the Field Operations team, you will play a foundation role in both the Penang region supply chain, as well as establishing new processes and protocol company-wide. This role will be fundamental in the expansion of our Penang operations.</p>
                            </div>
                        </div>
                    </div>

                    <div className='car-cards-item'>
                        <div className='car-info-cards'>
                            <div className='car-info-heading'>
                                <h3>Engineering</h3>
                                <p>Software Engineer</p>
                            </div>

                            <div className='car-info-desc'>
                                <p>Build polished customer experiences, mobile valet tools, and backend processing systems. Bring the latest technology to an under-served industry. Automate and optimize a growing business.</p>
                            </div>
                        </div>

                        <div className='car-info-cards'>
                            <div className='car-info-heading'>
                                <h3>Operations</h3>
                                <p>Customer Service Associate</p>
                            </div>

                            <div className='car-info-desc'>
                                <p>As our representative, you will be responsible for providing product and service information and resolving product and service problems. You will need to clarify the customer’s complaint, determining the cause of the problem, selecting and explaining the best solution to solve the problem, expediting correction or adjustment, and following up to ensure resolution.</p>
                            </div>
                        </div>
                    </div>
                    


                </div>

                
            </div>
        </div>
    );
}

export default CareersBody;