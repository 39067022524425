import React, { useEffect } from 'react';
import '../../App.css';
import SPBagCleanPage from '../SP BagCleaning Page';
import ContactUs03 from '../ContactUs03';
import DocumentMeta from "react-document-meta";

function SPBagCleaning() {

    useEffect(() => {
        document.title = "Bag Cleaning Service Malaysia | Gucci Purse Repair";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/services-and-pricing/bag-cleaning"
            }
        },

        meta: {
            name: {
                title: "Bag Cleaning Service Malaysia | Gucci Purse Repair",                
                description: "Want to clean your purse or leather bag? We provide Bag Cleaning Services to transform your bag to look as pristine as it is like they are brand new!",
                canonical:"https://washla.my/services-and-pricing/bag-cleaning",
                keywords: "clean, cleaning, cleaning service, bag, wash, restoration, delivery service, Malaysia, care, Gucci, LV, coach, leather bag, treatment",
            },
            property: {
                "og:title": "Cleaning Services Kuala Lumpur, Petaling Jaya",
                "og:description": "Want to clean your purse or leather bag? We provide Bag Cleaning Services to transform your bag to look as pristine as it is like they are brand new!",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <SPBagCleanPage />
            <ContactUs03 />        
        </>
    );
}

export default SPBagCleaning;