import React, { useState } from 'react';
import './FAQ.css';
import './ContactUs.css';

import { Accordion } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { TopQuestions, FirstWashLa, 
        OurServices, Referral, 
        PickUp, Billing,
        Policies, LoveWashLa } from './FAQanswers';


function Faq() { 

    // const [clicked, setClicked] = useState(false);
    const [query, setQuery] = useState("");

    return (

        <div className='wrap-faq' id='faqs'>
            
            <div className='faq-container'>
                <div className='faq-header'>
                    <h1>FAQs</h1>
                </div>

                <div className='faq-contents-wrapper' > 
                    
                    <Tabs defaultActiveKey="first">

                        <Tab eventKey="first"  title="Top Questions">
                            <div className='faq-searchbar-container'> 
                                <span aria-hidden="true" data-icon="&#xe903;" className='faq-searchbar-icon'></span>
                                <input className='accordion_searchbar' placeholder="Search question..." onChange={event => setQuery(event.target.value)} />
                            </div>
                            <h4 className='accordion_titlename'>Top Question</h4>

                            <Accordion id="faq_accordion" flush>
                                {
                                    TopQuestions.filter (item => {
                                        if (query === '') {
                                            return item;
                                        } else if (item.question.toLowerCase().includes(query.toLowerCase())) {
                                            return item;
                                        }
                                    }).map((item, index) => (
                                        <Accordion.Item className="faq-accordion" key={index} eventKey={index.toString()} >
                                            <Accordion.Header>
                                                <div className='faq-heading'>
                                                    <p>{item.category}</p>
                                                    <h4>{item.question}</h4>
                                                </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                                {item.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </Tab>

                        <Tab eventKey="second"  title="My First Wash La!">
                            <div className='faq-searchbar-container'> 
                                <span aria-hidden="true" data-icon="&#xe903;" className='faq-searchbar-icon'></span>
                                <input className='accordion_searchbar' placeholder="Search question..." onChange={event => setQuery(event.target.value)} />
                            </div>
                            <h4 className='accordion_titlename'>My First Wash La!</h4>

                            <Accordion id="faq_accordion" flush>
                                {
                                    FirstWashLa.filter (item => {
                                        if (query === '') {
                                            return item;
                                        } else if (item.question.toLowerCase().includes(query.toLowerCase())) {
                                            return item;
                                        }
                                    }).map((item, index) => (
                                        <Accordion.Item className="faq-accordion" key={index} eventKey={index.toString()} >
                                            <Accordion.Header>
                                                <div className='faq-heading'>
                                                    <p>{item.category}</p>
                                                    <h4>{item.question}</h4>
                                                </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                                {item.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </Tab>

                        <Tab eventKey="third" title="Our Services"> 
                            <div className='faq-searchbar-container'> 
                                <span aria-hidden="true" data-icon="&#xe903;" className='faq-searchbar-icon'></span>
                                <input className='accordion_searchbar' placeholder="Search question..." onChange={event => setQuery(event.target.value)} />
                            </div>
                            <h4 className='accordion_titlename'>Our Services</h4>

                            <Accordion id="faq_accordion" flush>
                                {
                                    OurServices.filter (item => {
                                        if (query === '') {
                                            return item;
                                        } else if (item.question.toLowerCase().includes(query.toLowerCase())) {
                                            return item;
                                        }
                                    }).map((item, index) => (
                                        <Accordion.Item className="faq-accordion" key={index} eventKey={index.toString()} >
                                            <Accordion.Header>
                                                <div className='faq-heading'>
                                                    <p>{item.category}</p>
                                                    <h4>{item.question}</h4>
                                                </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                                {item.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </Tab>

                        <Tab eventKey="fourth" title="Referral">
                            <div className='faq-searchbar-container'> 
                                <span aria-hidden="true" data-icon="&#xe903;" className='faq-searchbar-icon'></span>
                                <input className='accordion_searchbar' placeholder="Search question..." onChange={event => setQuery(event.target.value)} />
                            </div>
                            <h4 className='accordion_titlename'>Referral</h4>

                            <Accordion id="faq_accordion" flush>
                                {
                                    Referral.filter (item => {
                                        if (query === '') {
                                            return item;
                                        } else if (item.question.toLowerCase().includes(query.toLowerCase())) {
                                            return item;
                                        }
                                    }).map((item, index) => (
                                        <Accordion.Item className="faq-accordion" key={index} eventKey={index.toString()} >
                                            <Accordion.Header>
                                                <div className='faq-heading'>
                                                    <p>{item.category}</p>
                                                    <h4>{item.question}</h4>
                                                </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                                {item.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </Tab>

                        {/* <Tab eventKey="fourth" title="My Account">
                            <Accordion id="faq_accordion" flush>
                                {MyAccount.map((item,index)=>{ console.log(index.toString());

                                return (
                                    <Accordion.Item className="faq-accordion" key={index} eventKey={index.toString()}>
                                    <Accordion.Header>
                                        <div className='faq-heading'>
                                            <p>{item.category}</p>
                                            <h4>{item.question}</h4>
                                        </div>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        {item.answer}
                                    </Accordion.Body>
                                    </Accordion.Item>
                                )
                                })}
                            </Accordion>
                        </Tab> */}

                        <Tab eventKey="fifth" title="Pickup and Delivery">
                            <div className='faq-searchbar-container'> 
                                <span aria-hidden="true" data-icon="&#xe903;" className='faq-searchbar-icon'></span>
                                <input className='accordion_searchbar' placeholder="Search question..." onChange={event => setQuery(event.target.value)} />
                            </div>
                            <h4 className='accordion_titlename'>Pickup and Delivery</h4>

                            <Accordion id="faq_accordion" flush>
                                {
                                    PickUp.filter (item => {
                                        if (query === '') {
                                            return item;
                                        } else if (item.question.toLowerCase().includes(query.toLowerCase())) {
                                            return item;
                                        }
                                    }).map((item, index) => (
                                        <Accordion.Item className="faq-accordion" key={index} eventKey={index.toString()} >
                                            <Accordion.Header>
                                                <div className='faq-heading'>
                                                    <p>{item.category}</p>
                                                    <h4>{item.question}</h4>
                                                </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                                {item.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </Tab>

                        <Tab eventKey="sixth" title="Billing and Payments">
                            <div className='faq-searchbar-container'> 
                                <span aria-hidden="true" data-icon="&#xe903;" className='faq-searchbar-icon'></span>
                                <input className='accordion_searchbar' placeholder="Search question..." onChange={event => setQuery(event.target.value)} />
                            </div>
                            <h4 className='accordion_titlename'>Billing and Payments</h4>

                            <Accordion id="faq_accordion" flush>
                                {
                                    Billing.filter (item => {
                                        if (query === '') {
                                            return item;
                                        } else if (item.question.toLowerCase().includes(query.toLowerCase())) {
                                            return item;
                                        }
                                    }).map((item, index) => (
                                        <Accordion.Item className="faq-accordion" key={index} eventKey={index.toString()} >
                                            <Accordion.Header>
                                                <div className='faq-heading'>
                                                    <p>{item.category}</p>
                                                    <h4>{item.question}</h4>
                                                </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                                {item.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </Tab>

                        <Tab eventKey="seventh" title="Policies">
                            <div className='faq-searchbar-container'> 
                                <span aria-hidden="true" data-icon="&#xe903;" className='faq-searchbar-icon'></span>
                                <input className='accordion_searchbar' placeholder="Search question..." onChange={event => setQuery(event.target.value)} />
                            </div>
                            <h4 className='accordion_titlename'>Policies</h4>

                            <Accordion id="faq_accordion" flush>
                                {
                                    Policies.filter (item => {
                                        if (query === '') {
                                            return item;
                                        } else if (item.question.toLowerCase().includes(query.toLowerCase())) {
                                            return item;
                                        }
                                    }).map((item, index) => (
                                        <Accordion.Item className="faq-accordion" key={index} eventKey={index.toString()} >
                                            <Accordion.Header>
                                                <div className='faq-heading'>
                                                    <p>{item.category}</p>
                                                    <h4>{item.question}</h4>
                                                </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                                {item.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </Tab>

                        <Tab eventKey="eighth" title="Love Wash La!">
                            <div className='faq-searchbar-container'> 
                                <span aria-hidden="true" data-icon="&#xe903;" className='faq-searchbar-icon'></span>
                                <input className='accordion_searchbar' placeholder="Search question..." onChange={event => setQuery(event.target.value)} />
                            </div>
                            <h4 className='accordion_titlename'>Love Wash La!</h4>

                            <Accordion id="faq_accordion" flush>
                                {
                                    LoveWashLa.filter (item => {
                                        if (query === '') {
                                            return item;
                                        } else if (item.question.toLowerCase().includes(query.toLowerCase())) {
                                            return item;
                                        }
                                    }).map((item, index) => (
                                        <Accordion.Item className="faq-accordion" key={index} eventKey={index.toString()} >
                                            <Accordion.Header>
                                                <div className='faq-heading'>
                                                    <p>{item.category}</p>
                                                    <h4>{item.question}</h4>
                                                </div>
                                            </Accordion.Header>

                                            <Accordion.Body>
                                                {item.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </Tab>

                    </Tabs>
                </div>

            </div>
            
        </div>
    );
    
}

export default Faq;