import React from 'react';
import './LandingCard.css';
// import LandingCardItems from './LandingCardItems';
// import { Link } from 'react-router-dom';

function LandingCardItems(props) {
  return (
      <>
          <li className='cards__item'>
              {/* <Link className='cards__item__link' to={props.path}> */}
              <figure className='cards__item__pic-wrap' data-category={props.label}>
                  <img
                  className='cards__item__img'
                  alt={props.imgalttext}
                  src={props.imgsrc}
                  />
              </figure>
              <div className='cards__item__info'>
                  <h3 className='cards__item__text'>{props.text}</h3>
              </div>
              <div className='cards__item__desc'>
                  <p className='cards__item__text'>{props.desc}</p>
              </div>
              {/* </Link> */}
          </li>
      </>
  );
}


// const c1_cardimg1 = require('../images/Home/Shoe-Premium-Wash.jpg');
// const c1_cardimg2 = require('../images/Home/Shoe-Repainting.jpg');
// const c1_cardimg3 = require('../images/Home/Shoe-Restoration.jpg');
// const c1_cardimg4 = require('../images/Home/Shoe-Blackout.jpg');
// const c1_cardimg5 = require('../images/Home/Shoe-Water-Repellent-Treatment-And-Protection.jpg');

// const c2_cardimg3 = require('../images/Home/Schedule-a-pickup-6-days-a-week.png');
// const c2_cardimg2 = require('../images/Home/Lets-enjoy-your-free-time.png');
// const c2_cardimg1 = require('../images/Home/Relax-with-delivery-right-to-your-door.png');

// const story_img_1 = require('../images/Home/our-story.png');

function LandingCard() {
    return (

      <>
      <div className='wrap-cards' id='washla-experience' data-toggle='tab'>
          
          <div className="cards-container-1">
              <div className='cards-heading'>
                  <h2>Leading Shoe Wash & Sneaker Cleaning Service in KL and PJ</h2>
                  <p>Wash La! is the Leading One-stop solution for Professional Shoe Wash & Sneaker cleaning service with door-to-door pickup and delivery within Klang Valley.</p>
                  <p>We are dedicated to providing your beloved shoes with our signature shoe cleaning and sneaker care services — premium wash, dry cleaning, repair, full cleaning restoration, repainting, odour removal, blackout, water repellent treatment and protection.</p>
                  <p>There’s nothing more satisfying for us to bring your shoes back to life.</p>
                  <p>We use only 100% handwash standardized processes to take the best care of your shoes so they can have a sparkling clean and refreshed look. </p>
                  <p><strong>We help your shoes transform with:</strong></p>
              </div>

              <div className='cards__container'>
                  <div className='cards__wrapper'>
                      <ul className='cards__items'>
                          <LandingCardItems
                          imgsrc="assets/images/Home/Shoe-Premium-Wash.jpg"
                          imgalttext="Premium Wash/Dry Clean"
                          text='Premium Wash/Dry Clean'
                          />
                          <LandingCardItems
                          imgsrc="assets/images/Home/Shoe-Repainting.jpg"
                          imgalttext="Shoe Repainting"
                          text='Shoe Repainting'
                          />
                          <LandingCardItems
                          imgsrc="assets/images/Home/Shoe-Restoration.jpg"
                          imgalttext="Shoe Restoration"
                          text='Shoe Restoration'
                          />
                          <LandingCardItems
                          imgsrc="assets/images/Home/Shoe-Blackout.jpg"
                          imgalttext="Shoe Blackout"
                          text='Blackout'
                          />
                          <LandingCardItems
                          imgsrc="assets/images/Home/Shoe-Water-Repellent-Treatment-And-Protection.jpg"
                          imgalttext="Shoe Water Repellent Treatment And Protection"
                          text='Water Repellent Treatment And Protection'
                          />
                      </ul>
                  </div>
                  <div className='cards-landing-btn'>
                    <button className='cards_learnmore-btn'><a href='/services-and-pricing' target="_parent">View Our Services</a></button>
                  </div>  
              </div>
          </div>


          <div className="cards-container-2">
                <div className='cards-heading'>
                    <h2>What to expect on your first Wash La! Experience.</h2>
                    <p>We are the leading one-stop shoe wash & sneaker cleaning service provider with door-to-door pick-up and delivery service anywhere within Klang Valley. With our latest mobile app 
                        (available on iOS and Android), you can just sit back, relax and book a pick-up and delivery from the comfort of your home, at your choice of place and time. Save you the hassle!</p>
                </div>

                <div className='cards__container'>
                    <div className='cards__wrapper'>
                        <ul className='cards__items'>
                            <LandingCardItems
                            imgsrc="assets/images/Home/Schedule-a-pickup-6-days-a-week.png"
                            imgalttext="Schedule a pickup 6 days a week"
                            text='Schedule a pickup 6 days a week'
                            desc='Schedule via our interactive app. Your valet will always arrive between 3pm to 5pm.'
                            // path='/'
                            />
                            <LandingCardItems
                            imgsrc="assets/images/Home/Lets-enjoy-your-free-time.png"
                            imgalttext="Let’s enjoy your free time"
                            text='Let’s enjoy your free time'
                            desc='You get extra hours in your week, sneaker & laundry care from experts who are masters of their craft.'
                            // path='/'
                            />
                            <LandingCardItems
                            imgsrc="assets/images/Home/Relax-with-delivery-right-to-your-door.png"
                            imgalttext="Relax with delivery right to your door"
                            text='Relax with delivery right to your door'
                            desc='We deliver your sneakers and laundry clean, crisp and ready to wear.'
                            // path='/'
                            />
                        </ul>
                    </div>
                    <div className='cards-landing-btn'>
                        <button className='cards_learnmore-btn'><a href='/services-and-pricing' target="_parent">Learn More</a></button>
                    </div>
                </div>
            </div>
            
        </div>


        <div className="wrap-ourstory">
            <div className="ourstory-container">
              <div className="ourstory-container-1">
                  <img src="assets/images/Home/our-story.png" alt="Our story" />
              </div>

              <div className="ourstory-container-2">
                  <h2>Our Story</h2>
                  <p>Wash La! was born out of laziness!</p>
                  <p>A group of sneaker lovers who have OCD for cleanliness finds it to be a chore to bring themselves out of the house. While the retail market has various choices for cleaning services, none had a standalone app that would cater specifically for sneakers or provide customization on the services through one-click buttons, right up to your doorstep.</p>
                  <p>And since 2019 when the whole covid-19 pandemic took place, we noticed customers behaviour shifted to online services, and so did we, as the true mascots of laziness.</p>
                  <p>Seeing this gap in Malaysia, we decided to bring together our combined experiences in overseas training on sneaker care and numerous years of servicing the local industry to bring about sneaker care to the market in the form of a mobile app.</p>
                  <p>Wash La! Allows you to design custom care services for your sneakers and other items, all from the comfort of your home.</p>
                  <p>And while our laziness has given birth to Wash La!, please rest assured that we always strive to give our best in fine-tuning the apps as well as providing premium sneaker care services at an affordable and fair price right up to your doorstep.</p>
                  <p className="ourstory-desc-2"><strong>So... got dirt? Wash La!</strong></p>
              </div>
            </div>
        </div>
      </>
        
      );
}

export default LandingCard;