import React, { useEffect } from 'react';
import '../../App.css';
import ContactUs from '../ContactUs';
import PrivacyBody from '../PrivacyBody';
import DocumentMeta from "react-document-meta";

function PrivacyPolicy() {

    useEffect(() => {
        document.title = "Privacy Policy | Wash La!";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/privacy-policy"
            }
        },

        meta: {
            name: {
                title: "Privacy Policy | Wash La!",                
                description: "Please read this Privacy Policy Statement provided to you to understand how we use the personal information we may collect from you.",
                canonical:"https://washla.my/privacy-policy",
                keywords: "clean, cleaning, cleaning service, bag, restoration, delivery, privacy, policy, privacy policy, data, personal data, tracking, data security",
            },
            property: {
                "og:title": "Privacy Policy | Wash La!",
                "og:description": "Please read this Privacy Policy Statement provided to you to understand how we use the personal information we may collect from you.",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <PrivacyBody />
            <ContactUs />     
        </>
    );
}

export default PrivacyPolicy;