import React, { useState } from 'react';
// import { NavLink } from './NavbarElements';
import { NavLink as Link, useLocation } from 'react-router-dom';
import './Navbar2.css';
import Dropdown  from "./Navbar2dropdown";

import { Offcanvas, Accordion } from 'react-bootstrap';
import styled from 'styled-components';



export const NavLink = styled(Link)`
    &.active {
        color: #012066;
        font-weight: bold;
    }

`;



const Navbar = () => {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [dropdown, setDropdown] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    // mobile btn
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    window.addEventListener('resize', showButton);

    // dropdown
    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
          setDropdown(false);
        } else {
          setDropdown(true);
        } 
    };
    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };

    const currentPath = useLocation();

    return (
        <>
            <nav className='navbar1'>
                <div className='menu-icon' onClick={handleShow}>
                    {/* <i className={click ? 'fas fa-times' : 'fas fa-bars'} /> */}
                    <span aria-hidden="true" data-icon="&#xe902;" className='burger-icon-ico'></span>
                </div>

                <div>
                    <NavLink to='/' className='navbar-logo'  target='_parent'>
                        <img src="../assets/images/Home/Washla-logo.png" width="100px" alt="WashLa! logo"/>
                    </NavLink>
                </div> 

                <div className='navbar-container'>

                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <div className='nav-mobile-header'>
                            <i className='fas fa-times' onClick={closeMobileMenu} />
                            <img src="../assets/images/Home/Washla-logo.png" width="100px" alt="WashLa! logo"/>
                        </div>

                        <li className='nav-item' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            <NavLink to='/services-and-pricing' className='nav-links'  >
                                Services & Pricing <i className='fas fa-caret-down' />
                            </NavLink>
                            
                            {dropdown && <Dropdown  />}
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/locations' className='nav-links' onClick={closeMobileMenu} target='_parent' >
                                Locations
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/schedule-a-pickup' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                Schedule a Pickup
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/blogs' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                Blogs
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/contact-us' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                Contact Us
                            </NavLink>
                        </li>

                    </ul>
                </div> {/*  close navbar-container */}



                {/* Mobile view  */}

                <div className='navbar-container-mobile'>
                    {/* <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <div className='nav-mobile-header'>
                            <i className='fas fa-times' onClick={closeMobileMenu} />
                            <img src="../assets/images/Home/Washla-logo.png" width="100px" alt="WashLa! logo"/>
                        </div>

                        <li className='nav-item'>
                            <NavLink to='/#storyline' className='nav-links' onClick={closeMobileMenu} target='_parent' >
                                Storyline
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#roadmap' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                Roadmap
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#faqs' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                FAQS
                            </NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink to='/#team' className='nav-links' onClick={closeMobileMenu} target='_parent'>
                                Team
                            </NavLink>
                        </li>
                        <li className='nav-item-dropdown'  >
                            <p className='dropdown-button'>MEMBER <span className='nav-dropdown-icon'><i class="bi bi-chevron-down"></i></span></p>
                            <div className='dropdown-wrapper'>
                                <div class="dropdown-content">
                                    <a href="/akc-pets">AKC PETS </a>
                                    <a href="/akc-merch">Merch</a>
                                    <a href="/akfc">AKFC </a>
                                    <a href="/">Mobile game <span class='mb-dd-coming-soon'>Coming Soon</span></a>
                                    <a href="/">Hold to Earn <span class='mb-dd-coming-soon'>Coming Soon</span></a>
                                    <a href="/">... <span class='mb-dd-coming-soon'>Coming Soon</span></a>
                                </div>
                            </div>
                        </li>
                        <li className='nav-item'>
                            <a href='/akfc' className='nav-mint-btn'>AKFC Mint</a>
                        </li>

                    </ul> */}
                    
                </div> 

                <Offcanvas show={show} onHide={handleClose} placement="end">
                    <Offcanvas.Header closeButton style={{paddingTop: "1.5rem"}}></Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='nav-mobile-header'>
                            <img src="../assets/images/Home/Washla-logo.png" width="100px" alt="WashLa! logo"/>
                            <br/>
                        </div>
                        <div className="offcanvas-content">
                            <div className="offcanvas-line"></div>
                            <Accordion defaultActiveKey="0" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Services & Pricing</Accordion.Header>
                                    <div className="offcanvas-line"></div>
                                    <Accordion.Body>
                                        <div className="offcanvas-menu-dropdown-wrapper">
                                            <div className="dropdown-wrapper-left"></div>
                                            <div className="inner-wrapper">
                                                <Link to="/services-and-pricing/sneaker-care" onClick={handleClose} target="_parent"><div className="offcanvas-menu-title">Sneaker Care</div></Link>
                                                <div className="offcanvas-line"></div>
                                                <Link to="/services-and-pricing/wash-and-fold" onClick={handleClose} target="_parent"><div className="offcanvas-menu-title">Wash & Fold</div></Link>
                                                <div className="offcanvas-line"></div>
                                                <Link to="/services-and-pricing/dry-cleaning" onClick={handleClose} target="_parent"><div className="offcanvas-menu-title">Dry Cleaning</div></Link>
                                                <div className="offcanvas-line"></div>
                                                <Link to="/services-and-pricing/bag-cleaning" onClick={handleClose} target="_parent"><div className="offcanvas-menu-title">Bag Cleaning</div></Link>
                                                <div className="offcanvas-line"></div>
                                                <Link to="/services-and-pricing/other-services" onClick={handleClose} target="_parent"><div className="offcanvas-menu-title">Other Services</div></Link>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div className="offcanvas-line"></div>
                            <Link to="/locations" onClick={handleClose} target="_parent"><div className="offcanvas-menu-title">Locations</div></Link>
                            <div className="offcanvas-line"></div>
                            <Link to="/schedule-a-pickup" onClick={handleClose} target="_parent"><div className="offcanvas-menu-title">Schedule a Pickup</div></Link>
                            <div className="offcanvas-line"></div>
                            <Link to="/blogs" onClick={handleClose} target="_parent"><div className="offcanvas-menu-title">Blogs</div></Link>
                            <div className="offcanvas-line"></div>
                            <Link to="/contact-us" onClick={handleClose} target="_parent"><div className="offcanvas-menu-title">Contact Us</div></Link>
                            <div className="offcanvas-line"></div>
                            
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

                <div className='nav-social1'>
                        <a className='social-icon-link1 instagram' href="https://www.instagram.com/washla.my/" target='_blank' rel='noreferrer noopener' aria-label='Instagram'  >
                            <span aria-hidden="true" data-icon="&#xe910;" className='social-icon-ico'></span>
                        </a>
                        <a className='social-icon-link1 facebook' href='https://www.facebook.com/washla.my/?ref=py_c' target='_blank' rel='noreferrer noopener' aria-label='Facebook'  >
                            <span aria-hidden="true" data-icon="&#xe90f;" className='social-icon-ico'></span>
                        </a>
                    
                    
                </div>
                 

            </nav>
        </>
        
    );
}

export default Navbar;