import React from 'react';
import './SchedulePopUp.css';
import { Link } from 'react-router-dom';

// const popup_schedule_img1 = require('../images/schedule-pickup/laundry-delivery-step-1.png');
// const popup_schedule_img2 = require('../images/schedule-pickup/laundry-delivery-step-2.png');
// const popup_schedule_img3 = require('../images/schedule-pickup/laundry-delivery-step-3.png');

function PopUp(props) {
    return (props.trigger) ? (
        <div className='wrap-popup'>
            <div className='schedule-popup'>
                <p className='popup-name'>Schedule a pickup</p>
                <button className='close-btn' onClick={() => props.setTrigger(false)}>
                    x 
                </button>
                
                <div className='popup-content'>
                    <div className='popup-heading'>
                        <h2>Schedule Your First Pickup</h2>
                        <p>Link to download app on AppStore or Google Playstore and also a “Find if we serve your neighbourhood” postcode entry which will list nearest locations to a particular postcode. </p>
                    </div>

                    <div className='popup-desc'>
                        <div className='popup-container'>
                        {/* <div className="circle">Step 1</div> */}
                            <div className='popup-item'>
                                <img src="../assets/images/schedule-pickup/laundry-delivery-step-1.png" className='schedule-img' alt='Wash La!'/>
                            </div>
                            <div className='popup-item'>
                                <h3 className='schedule-heading'>Pick Up </h3>
                                <p className='schedule-desc'>
                                    Between 3pm to 5pm on the evening of your scheduled pickup, you’ll receive a text message with a 30-minute estimated time of arrival for your Valet. Your Valet will bring your personalized Wash La! Bags so you have everything you need to use our services. All you have to do is have your shoes, clothes, bags or caps ready in a bag by the door. All pickups and deliveries are no-contact. Your Valet will walk you through your first socially-distanced pickup and answer any questions you may have.
                                </p>
                            </div>
                        </div>

                        <div className='popup-container'>
                        {/* <div className="circle">Step 2</div> */}
                            <div className='popup-item'>
                                <img src="../assets/images/schedule-pickup/laundry-delivery-step-2.png" className='schedule-img' alt='Wash La!' />
                            </div>
                            <div className='popup-item'>
                                <h3 className='schedule-heading'>Cleaning </h3>
                                <p className='schedule-desc'>
                                    Once we pick up your items, we’ll take them to the Wash La! Headquarters. You’ll receive a detailed email including a list of items you’ve entrusted to our care. Your items will then be inspected for stains and items that shouldn’t be washed and professionally cleaned according to the service you selected.
                                </p>
                            </div>
                        </div>

                        <div className='popup-container'>
                        {/* <div className="circle">Step 3</div> */}
                            <div className='popup-item'>
                                <img src="../assets/images/schedule-pickup/laundry-delivery-step-3.png" className='schedule-img' alt='Wash La!' />
                            </div>
                            <div className='popup-item'>
                                <h3 className='schedule-heading'>Delivery</h3>
                                <p className='schedule-desc'>
                                    We’ll send you a text message on the morning of your scheduled delivery to confirm your delivery between 10am to 2pm. Once confirmed, you’ll receive another text message with an estimated time of arrival for your Valet. Your Valet will delivery your clean items, ready to wear! Our standard turnaround for orders is 5 - 7 days.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="PopupLine"></div>

                    <div className='popup-download'>
                        <Link to='/schedule-a-pickup' className='btn-mobile' target='_parent'>
                            <button className='popup-download-btn' onClick={() => props.setTrigger(false)} >View More</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    ) : "";

}

export default PopUp;