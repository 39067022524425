import React from 'react';
import '../App.css';
import './HeroSection.css';
import PopUp from './SchedulePopUp';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import { HiXCircle } from "react-icons/hi";

function HeroSection() {

  const [buttonPopup, setButtonPopup] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className='wrap-hero'>

        <div className='hero-container'> 
          <div className='hero-heading'>
            <h1>Got Dirt? Wash La!</h1>
          </div>

          <div className='hero-desc'>
            <p>Wash La! picks up, cleans and delivers your sneakers, bags, caps, laundry and dry cleaning.</p>
          </div>

          <div className='hero-btns'>
            {/* <button className='heroschedule-btn' onClick={() => setButtonPopup(true)}>
              Schedule a Pickup
            </button> */}

            <PopUp trigger={buttonPopup} setTrigger={setButtonPopup}> </PopUp>

            <button className='heroschedule-btn' onClick={handleShow}>
              View Promotion
            </button>
          </div>

          <div className='hero-scrolldown'>
            <Link to='/#washla-experience' target='_parent'><span aria-hidden="true" data-icon="&#xe913;" className='hero-scroll-icon'></span></Link>
          </div>

        </div>
      </div>


      <div>
        <Modal show={show} onHide={handleClose} className="promo-modal">
            {/* <Modal.Header closeButton>
                <Modal.Title className="modal-heading">
                    <div className="modal-heading-1">
                        <img src="../assets/images/Home/Washla-logo.png" alt="Wash La! Promotion" />
                    </div> 
                </Modal.Title>
            </Modal.Header> */}

            <Modal.Body className="promo-modal-img-desktop" >
              <HiXCircle onClick={handleClose} className="promo-modal-btnclose"/>
              <div className='wrap-promo-modal'>
                <div className="promo-modal-img-type">
                  <img className="promo-modal-img-type-2" src="/assets/images/Promotions/Washla-February-2023.jpg"
                    alt="1 Price for 2 Pairs of Shoes - WashLa!"
                  />
                </div>
                {/* <Carousel fade>
                  <Carousel.Item>
                    <img
                      className="d-block w-100" style={{borderRadius: '10px'}}
                      src="/assets/images/Promotions/Washla-Feb-2023.jpg"
                      alt="12.12 Promotion - WashLa! - Shoe Wash Service"
                    />
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100" style={{borderRadius: '10px'}}
                      src="/assets/images/Promotions/Washla-CNY-2.jpg"
                      alt="12.12 Promotion - WashLa! - Shoe Wash Service"
                    />
                  </Carousel.Item>
                </Carousel> */}
              </div>
            </Modal.Body>

            {/* <Modal.Footer> 
              <a className="modal-download" href="#downloadapp" target="_parent">
                  <Button className="promo-modal-btn" onClick={handleClose}>
                      Download Our App Now
                  </Button>
              </a> 
            </Modal.Footer> */}
        </Modal>  
    </div>
    </>
    
  );
}

export default HeroSection;