import React, { useEffect } from 'react';
import '../../App.css';
import SPDryCleanPage from '../SP DryCleaning Page';
// import ContactUs02 from '../ContactUs02';
import DocumentMeta from "react-document-meta";

function SPDryCleaning() {

    useEffect(() => {
        document.title = "Dry Cleaning Service KL & Selangor | Laundry Dry Cleaner Malaysia";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/services-and-pricing/dry-cleaning"
            }
        },

        meta: {
            name: {
                title: "Dry Cleaning Service KL & Selangor | Laundry Dry Cleaner Malaysia",                
                description: "At Wash La!, we have a team of skilled and experienced dry cleaning experts to clean your most beloved garments so that they are well taken care of!",
                canonical:"https://washla.my/services-and-pricing/dry-cleaning",
                keywords: "clean, cleaning, cleaning service, shirt, wash, laundry, Petaling Jaya, KL, delivery service, Malaysia, dry cleaning",
            },
            property: {
                "og:title": "Cleaning Services Kuala Lumpur, Petaling Jaya",
                "og:description": "At Wash La!, we have a team of skilled and experienced dry cleaning experts to clean your most beloved garments so that they are well taken care of!",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <SPDryCleanPage />
            {/* <ContactUs02 />         */}
        </>
    );
}

export default SPDryCleaning;