import React, { useEffect } from "react";
import "../../App.css";
import ContactUs from "../ContactUs";
import DocumentMeta from "react-document-meta";
import DataDeletionBody from "../DataDeletionBody";

function DataDeletion() {
  useEffect(() => {
    document.title = "Data Deletion | Wash La!";
  }, []);

  const meta = {
    link: {
      rel: {
        canonical: "https://washla.my/data-deletion",
      },
    },

    meta: {
      name: {
        title: "Data Deletion  | Wash La!",
        description:
          "If the client wishes to proceed with the refund process from Wash La! all communications can be done via our apps or contacting our customer service.",
        canonical: "https://washla.my/refund-policy",
        keywords:
          "clean, cleaning, cleaning service, bag, delivery, privacy, policy, refund policy, data, personal data, sales and refund",
      },
      property: {
        "og:title": "Data Deletion  | Wash La!",
        "og:description":
          "If the client wishes to proceed with the refund process from Wash La! all communications can be done via our apps or contacting our customer service.",
      },
    },
  };

  return (
    <>
      <DocumentMeta {...meta} />

      <DataDeletionBody />
      <ContactUs />
    </>
  );
}

export default DataDeletion;
