

export const MenuItems = [
    {
      title: 'Sneaker Care',
      path: '/services-and-pricing/sneaker-care',
      cName: 'dropdown-link'
    },
    {
      title: 'Wash & Fold',
      path: '/services-and-pricing/wash-and-fold',
      cName: 'dropdown-link'
    },
    {
      title: 'Dry Cleaning',
      path: '/services-and-pricing/dry-cleaning',
      cName: 'dropdown-link'
    },
    {
      title: 'Bag Cleaning',
      path: '/services-and-pricing/bag-cleaning',
      cName: 'dropdown-link'
    },
    {
      title: 'Other Services',
      path: '/services-and-pricing/other-services',
      cName: 'dropdown-link'
    }
  ];