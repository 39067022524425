import React, { useEffect } from 'react';
import '../../App.css';
import ContactUs from '../ContactUs';
import BlogMain from '../BlogMain';
// import DownloadApp from '../DownloadApp';
import DocumentMeta from "react-document-meta";

function BlogPage() {

    useEffect(() => {
        document.title = "Blogs | Wash La! PJ";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/blogs"
            }
        },

        meta: {
            name: {
                title: "Blogs | Wash La! Cleaning Services",                
                description: "Let’s talk about cleaning from variety of stuffs from your personal favortite sneakers to your everyday uses! ",
                canonical:"https://washla.my/blogs",
                keywords: "clean, cleaning, cleaning service, bag, restoration, delivery service, malaysia, care, leather bag, treatment, blog, article, news",
            },
            property: {
                "og:title": "Blogs | Wash La! Cleaning Services",
                "og:description": "Let’s talk about cleaning from variety of stuffs from your personal favortite sneakers to your everyday uses! ",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 
            
            <BlogMain />
            {/* <DownloadApp /> */}
            <ContactUs />    
        </>
    );
}

export default BlogPage;