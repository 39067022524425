import React from 'react';
import '../App.css';
import './TermsOfUseBody.css';
import { Link } from 'react-router-dom';


function TermsOfUseBody () {

    return (

        <div className='wrap-termsofuse'>
            <div className='terms-heading-container'>
                <div className='terms-heading'>
                    <h1>Terms of Use</h1>
                </div>
                <div className='terms-date-eff'>
                    <p>Effective Date: July 1, 2021</p>
                </div>
                <div className='terms-heading-desc'>
                    <p>Welcome to Wash La!. Please read on to learn the rules and restrictions that govern your use of our website(s), mobile app(s), products, services, applications, and or other online or offline communications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at:</p>
                    <p>Email: <a href="mailto:cs@washla.my">cs@washla.my</a></p>
                    <p>Phone: <a href="tel:+60178469472">+6017-8469472</a></p>
                    <p>Address: <span className='t-heading-desc-span-1'>60 Jalan SS2/72, SS2, 47300 Petaling Jaya, Selangor</span></p>
                </div>
                <div className='terms-heading-desc'>
                    <p>PLEASE NOTE: THESE TERMS OF USE CONTAIN AN <strong>ARBITRATION CLAUSE AND CLASS ACTION WAIVER</strong>. THE WAIVER AFFECTS HOW DISPUTES WITH THE COMPANY ARE RESOLVED. ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR
                        CERTAIN TYPES OF DISPUTES DESCRIBED IN THE <strong>ARBITRATION AGREEMENT SECTION BELOW</strong>, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO
                        PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. BY ACCEPTING THESE TERMS OF USE, YOU AGREE TO BE BOUND BY THE <strong>ARBITRATION SECTION BELOW</strong>. PLEASE READ IT CAREFULLY.
                    </p>
                </div>
                <div className='terms-heading-desc'>
                    <p>These Terms of Use (the “Terms”) are a binding contract between you and <strong>Wash La Sdn. Bhd.</strong>. (“Wash La!,” “we” and “us”). Your use of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include 
                        the provisions in this document as well as those in the <strong>Privacy Policy</strong> and any other relevant policies. <strong>Your use of or participation in certain Services may also be subject to additional policies, rules and/or conditions (“Additional Terms”), which are incorporated herein by reference, 
                        and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms.</strong>
                    </p>
                </div>
                <div className='terms-heading-desc'>
                    <p><strong>Please read these Terms carefully. </strong>They cover important information about Services provided to you and any charges, taxes, and fees we bill you. <strong>These Terms include information about </strong><strong>future changes to these Terms</strong><strong>, </strong><strong>automatic renewals</strong><strong>, </strong><strong>limitations of liability</strong><strong>, a class action waiver 
                            and resolution of</strong><strong>disputes by arbitration instead of in court</strong><strong>. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR 
                            ACCESS THE SERVICES IN ANY MANNER.</strong>
                    </p>
                </div>
            </div>

            <div className='terms-table-container'>
                <div className='terms-tablecontent'>
                    <h2>QUICK GUIDE TO CONTENTS</h2>
                </div>

                <div className='terms-tablecontent-list'>
                    <ol>
                        <li><a href='/terms-of-use/#termsofuse-1' target='_top'>Will these Terms ever change?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-2' target='_top'>What about my privacy?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-3' target='_top'>What are the basics of using Wash La!?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-4' target='_top'>What about messaging?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-5' target='_top'>Are there restrictions in how I can use the Services?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-6' target='_top'>What are my rights in the Services?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-7' target='_top'>What about anything I contribute to the Services – do I have to grant any licenses to Wash La! or to other users?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-8' target='_top'>What if I see something on the Services that infringes my copyright?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-9' target='_top'>Who is responsible for what I see and do on the Services?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-10' target='_top'>Will Wash La! ever change the Services?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-11' target='_top'>Do the Services cost anything?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-12' target='_top'>What if I want to stop using the Services?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-13' target='_top'>What about Mobile Applications?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-14' target='_top'>I use the Wash La! App available via the Apple App Store – should I know anything about that?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-15' target='_top'>Can I refer other users?</a></li>
                        <li><a href='/terms-of-use/#termsofuse-16' target='_top'>What else do I need to know?</a>
                            <ol>
                                <li><a href='/terms-of-use/#termsofuse-16-01' target='_top'>Warranty Disclaimer</a></li>
                                <li><a href='/terms-of-use/#termsofuse-16-02' target='_top'>Limitation of Liability</a></li>
                                <li><a href='/terms-of-use/#termsofuse-16-03' target='_top'>Indemnity</a></li>
                                <li><a href='/terms-of-use/#termsofuse-16-04' target='_top'>Assignment</a></li>
                                <li><a href='/terms-of-use/#termsofuse-16-05' target='_top'>Choice of Law</a></li>
                                <li><a href='/terms-of-use/#termsofuse-16-06' target='_top'>Arbitration Agreement</a></li>
                                <li><a href='/terms-of-use/#termsofuse-16-07' target='_top'>Miscellaneous</a></li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-1'>
                <div className='terms-header'>
                    <h2>1. WILL THESE TERMS EVER CHANGE?</h2>
                </div>
                <div className='terms-desc'>
                    <p>We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located at https://washla.my/, send you an email, and/or notify you by some other means.</p>
                    <p>If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes.</p>
                    <p>Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-2'>
                <div className='terms-header'>
                    <h2>2. WHAT ABOUT MY PRIVACY</h2>
                </div>
                <div className='terms-desc'>
                    <p>Wash La! takes the privacy of its users very seriously. <span className='t-heading-desc-span-2'>For the current Wash La! Privacy Policy, please <Link to='/privacy-policy' target='_parent'>click here</Link></span>.</p>
                    <h4>CHILDREN’S ONLINE PRIVACY PROTECTION ACT</h4>
                    <p>The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 16 years of age. We do not knowingly collect or solicit personally identifiable information from children under 16 years of age; if you are a child under 16 years of age, please do not attempt to register for or otherwise use the Services or send us any personal information. If we learn we have collected personal information from a child under 16 years of age, we will delete that information as quickly as possible. If you believe that a child under 16 years of age may have provided us personal information, please contact us at <span><a href="mailto:cs@washla.my">cs@washla.my</a></span>.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-3'>
                <div className='terms-header'>
                    <h2>3. WHAT ARE THE BASICS OF USING “Wash La!”?</h2>
                </div>
                <div className='terms-desc'>
                    <p>You may be required to sign up for an account, select a password and user name (“Wash La! User ID”), and provide us with certain information or data, such as your contact information. You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your Wash La! User ID a name that you do not have the right to use, or another person’s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission. If you change your contact information, including any mobile number, you agree that you will notify us immediately.</p>
                    <p>Additionally, you may be able to access certain parts or features of the Services by using your account credentials from other services (each, a “Third Party Account”), such as those offered by Facebook. We also accept credentials from corporate SAML. By using the Services through a Third Party Account, you permit us to access certain information from such account for use by the Services. You are ultimately in control of how much information is accessible to us and may exercise such control by adjusting your privacy settings on your Third Party Account.</p>
                    <p>You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you’ve received your parent’s or guardian’s permission to use the Services and have gotten your parent or guardian to agree to these Terms on your behalf).</p>
                    <p>You will only use the Services for your own internal, personal, non-commercial use, and not on behalf of or for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If your use of the Services is prohibited by applicable laws, then you aren’t authorized to use the Services. We can’t and won’t be responsible for your using the Services in a way that breaks the law.</p>
                    <p>You will not share your Wash La! User ID, account or password with anyone, and you must protect the security of your Wash La! User ID, account, password and any other access tools or credentials. You’re responsible for any activity associated with your Wash La! User ID and account.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-4'>
                <div className='terms-header'>
                    <h2>4. WHAT ABOUT MESSAGING?</h2>
                </div>
                <div className='terms-desc'>
                    <p>As part of the Services, you may receive communications through the Services, including messages that Wash La! sends you (for example, via email, Whatsapp or SMS). You may opt in to receive Wash La!’s text messages (using autodialer technology) at the mobile number you provide to us. Your consent to receive our promotional texts is not a condition of purchase or use of the Cleaning Services (defined below). Message and data rates may apply. If you have any questions about your text plan or data plan, please contact your wireless provider. You can opt out of receiving our texts by following the instructions provided in those messages or otherwise reply to our texts with the “STOP” command. If you have any questions, you may reply “HELP” to any text from Wash La! or contact us by texting “HELP” to 222-22.</p>                    
                    <p>When signing up for the Services, you will receive a welcome message and instructions on how to stop receiving messages.&nbsp;<strong>By signing up for the Services and providing us with your wireless number, you confirm that you want Wash La! to send you information that we think may be of interest to you, which may include Wash La! using automated dialing technology to text you at the wireless number you provided, and you agree to receive communications from Wash La!, and you represent and warrant that each person you register for the Services or for whom you provide a wireless phone number has consented to receive communications from Wash La!.</strong>&nbsp;You agree to indemnify and hold Wash La! harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to your breach of the foregoing.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-5'>
                <div className='terms-header'>
                    <h2>5. ARE THERE RESTRICTIONS IN HOW I CAN USE THE SERVICES?</h2>
                </div>
                <div className='terms-desc'>
                    <p>You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those terms is defined below) or otherwise use the Services or interact with the Services in a manner that: </p>                    
                    <ol>
                        <li>infringes or violates the intellectual property rights or any other rights of anyone else (including Wash La!);</li>
                        <li>violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not reasonably intended by Wash La!;</li>
                        <li>is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</li>
                        <li>jeopardizes the security of your Wash La! User ID, account or anyone else’s (such as allowing someone else to log in to the Services as you);</li>
                        <li>attempts, in any manner, to obtain the password, account, or other security information from any other user;</li>
                        <li>violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
                        <li>runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);</li>
                        <li>“crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);</li>
                        <li>copies or stores any significant portion of the Content;</li>
                        <li>decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services;</li>
                        <li>threatens our employees, contractors, agents, or other personnel; or</li>
                        <li>engages in any other act that is offensive or otherwise objectionable.</li>
                    </ol>
                    <p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-6'>
                <div className='terms-header'>
                    <h2>6. WHAT ARE MY RIGHTS IN THE SERVICES?</h2>
                </div>
                <div className='terms-desc'>
                    <p>The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions (as defined below) and so forth (all of the foregoing, the “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including Wash La!'s) rights.</p>
                    <p>Subject to these Terms, we grant each user of the Services a limited, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Services in Malaysia. Use, reproduction, modification, distribution or storage of any Content for any purpose other than using the Services is expressly prohibited without prior written permission from us. You understand that Wash La! owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services. The Services may allow you to copy or download certain Content, but please remember that even where these functionalities exist, all the restrictions in this section still apply.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-7'>
                <div className='terms-header'>
                    <h2>7. WHAT ABOUT ANYTHING I CONTRIBUTE TO THE SERVICES – DO I HAVE TO GRANT ANY LICENSES TO WASH LA! OR TO OTHER USERS? </h2>
                </div>
                <div className='terms-desc'>
                    <h4>USER SUBMISSIONS</h4>
                    <p>We may, in our sole discretion, permit you to post, upload, input, publish, submit or transmit information or material through the Service. Anything you post, upload, share, store, or otherwise provide through the Services is your “User Submission”. You are solely responsible for all User Submissions you contribute to the Services. You represent that all User Submissions submitted by you are accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.</p>
                    <p>You agree that you will not post, upload, share, store, or otherwise provide through the Services any User Submissions that: (i) infringe any third party's copyrights or other rights (e.g., trademark, privacy rights, etc.); (ii) contain sexually explicit content or pornography; (iii) contain hateful, defamatory, or discriminatory content or incite hatred against any individual or group; (iv) exploit minors; (v) depict unlawful acts or extreme violence; (vi) depict animal cruelty or extreme violence towards animals; (vii) promote fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other dubious money-making ventures; or (viii) that violate any law.</p>

                    <h4>LICENSES</h4>
                    <p>You grant us certain rights in those User Submissions (see below for more information). Please note that all of the following licenses are subject to our&nbsp;<strong>Privacy Policy</strong>&nbsp;to the extent they relate to User Submissions that are also your personally-identifiable information.</p>
                    <p>By submitting User Submissions through the Services, you hereby do and shall grant Wash La! a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Submissions in connection with this site, the Services and our (and our successors’ and assigns’) businesses, including without limitation for promoting and redistributing part or all of this site or the Services (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party websites and feeds), and including after your termination of your account or the Services. For clarity, the foregoing license grants to us does not affect your other ownership or license rights in your User Submissions, including the right to grant additional licenses to your User Submissions, unless otherwise agreed in writing. You represent and warrant that you have all rights to grant such licenses to us without infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights.</p>
                    <p>Certain features of the Services allow you to share information with others, including through your social networks or other Third Party Accounts. When Content is authorized for sharing, we will clearly identify the Content you are authorized to redistribute and the ways you may redistribute it, usually by providing a “share” button on or near the Content. If you share information from the Services with others through your Third Party Accounts, such as your social networks, you authorize Wash La! to share that information with the applicable Third Party Account provider. Please review the policies of any Third Party Account providers you share information with or through for additional information about how they may use your information. If you redistribute Content, you must be able to edit or delete any Content you redistribute, and you must edit or delete it promptly upon our request.</p>
                    <p>Finally, you understand and agree that Wash La!, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-8'>
                <div className='terms-header'>
                    <h2>8. WHAT IF I SEE SOMETHING ON THE SERVICES THAT INFRINGES MY COPYRIGHT? </h2>
                </div>
                <div className='terms-desc'>
                    <p>We respect others’ intellectual property rights, and we reserve the right to delete or disable Content alleged to be infringing, and to terminate the accounts of repeat alleged infringers.</p>
                    <p>If you believe any User Submission accessible on or from the Services infringes your copyright, you may request removal of those materials (or access thereto) from the Services by contacting us (address identified above) and providing the following information: (A) identification of the copyrighted work that you believe to be infringed. Please describe the work, and where possible include a copy or the location (e.g., URL) of an authorized version of the work; (B) identification of the User Submission that you believe to be infringing and its location; (C) your name, address, telephone number, and email address; (D) a statement that you have a good faith belief that the complained of use of the work is not authorized by the copyright owner, its agent, or the law; (E) a statement that the information you have supplied is accurate, and indicating that “under penalty of perjury,” you are the copyright owner or authorized representative; and (F) signature or the electronic equivalent from the copyright holder or authorized representative.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-9'>
                <div className='terms-header'>
                    <h2>9. WHO IS RESPONSIBLE FOR WHAT I SEE AND DO ON THE SERVICES? </h2>
                </div>
                <div className='terms-desc'>
                    <p>Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such Content originated, and you access all such information and Content at your own risk, and we aren’t liable for any errors or omissions in that information or Content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.</p>
                    <p>You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it.</p>
                    <p>The Services may contain links or connections to third-party websites or services that are not owned or controlled by Wash La!. When you access third-party websites or use third-party services, you accept that there are risks in doing so, and that Wash La! is not responsible for such risks.</p>
                    <p>Wash La! has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites or by any third party that you interact with through the Services. In addition, Wash La! will not and cannot monitor, verify, censor or edit the content of any third-party site or service. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third-party website or service.</p>
                    <p>Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that Wash La! shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.</p>
                    <p>If there is a dispute between participants on this site or Services, or between users and any third party, you agree that Wash La! is under no obligation to become involved. In the event that you have a dispute with one or more other users, you release Wash La!, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. You shall and hereby do waive Malaysia Civil Code Section or any similar law of any jurisdiction, which says in substance: “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-10'>
                <div className='terms-header'>
                    <h2>10. WILL Wash LA! EVER CHANGE THE SERVICES? </h2>
                </div>
                <div className='terms-desc'>
                    <p>We’re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We’ll try to give you notice when we make a material change to the Services that would adversely affect you, but this isn’t always practical. We reserve the right to remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-11'>
                <div className='terms-header'>
                    <h2>11. DO THE SERVICES COST ANYTHING? </h2>
                </div>
                <div className='terms-desc'>
                    <p>Our website(s) and app(s) are currently offered free of charge. We may charge a fee for using the Services. If you are using a free version of the Services, we will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using such Services, you must pay all applicable fees for such Services. Note that if you elect to receive text messages through the Services, data and message rates may apply. Any and all such charges, fees or costs are your sole responsibility. You should consult with your wireless carrier to determine what rates, charges, fees or costs may apply to your use of the Services.</p>

                    <ol>
                        <li><span className='t-heading-desc-span-1'>Paid Services.</span> Certain of our Services may be subject to payments now or in the future, such as dry cleaning, shoe wash, laundered shirts, wash &amp; fold, or related services (the “Cleaning Services”, and collectively the “Paid Services”). Please see our&nbsp;<strong>Pricing page</strong>&nbsp;for a description of the current Paid Services. Please note that any payment terms presented to you in the process of using or signing up for a Paid Service are deemed part of these Terms.</li>
                        <li><span className='t-heading-desc-span-1'>Billing.</span> We use a third-party payment processor (the “Payment Processor”) to bill you through a payment account linked to your account on the Services (your “Billing Account”) for use of the Paid Services. The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in addition to these Terms. Currently, we use ipay88 as our Payment Processor. You can access ipay88’s Services Agreement and Privacy Policy at&nbsp;<a href="https://www.ipay88.com/" target='_blank' rel='noreferrer noopener'><strong>https://www.ipay88.com/</strong></a>. We are not responsible for any error by, or other acts or omissions of, the Payment Processor. By choosing to use Paid Services, you agree to pay us, through the Payment Processor, all charges at the prices then in effect for any use of such Paid Services in accordance with the applicable payment terms, and you authorize us, through the Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that the Payment Processor makes even if it has already requested or received payment.</li>
                        <li><span className='t-heading-desc-span-1'>Payment Method.</span> The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or other provider of your chosen Payment Method. If we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on your Billing Account upon demand.</li>
                        <li><span className='t-heading-desc-span-1'>Recurring Billing.</span> Some of the Paid Services may consist of an initial period, for which there is a one-time charge, followed by recurring period charges as agreed to by you. By choosing a recurring payment plan, you acknowledge that such Services have an initial and recurring payment feature and you accept responsibility for all recurring charges prior to cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO&nbsp;<strong>ACCOUNT SETTINGS</strong>.</li>
                        <li><span className='t-heading-desc-span-1'>Current Information Required.</span> YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT&nbsp;<strong>ACCOUNT SETTINGS</strong>. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.</li>
                        <li><span className='t-heading-desc-span-1'>Change in Amount Authorized.</span> If the amount to be charged to your Billing Account varies from the amount you preauthorized (other than due to the imposition or change in the amount of state sales taxes), you have the right to receive, and we shall provide, notice of the amount to be charged and the date of the charge before the scheduled date of the transaction. Any agreement you have with your payment provider will govern your use of your Payment Method. You agree that we may accumulate charges incurred and submit them as one or more aggregate charges during or at the end of each billing cycle.</li>
                        <li><span className='t-heading-desc-span-1'>Auto-Renewal for Paid Services.</span> Unless you opt out of auto-renewal, which can be done through your&nbsp;<strong>account settings</strong>, any Paid Services you have signed up for will be automatically extended for successive renewal periods of the same duration as the subscription term originally selected, at the then-current non-promotional rate. To change or resign your Paid Services at any time, go to&nbsp;<strong>account settings</strong>. If you terminate a Paid Service, you may use your subscription until the end of your then-current term, and your subscription will not be renewed after your then-current term expires. However, you will not be eligible for a prorated refund of any portion of the subscription fee paid for the then-current subscription period. If you do not want to continue to be charged on a recurring monthly basis, you must cancel the applicable Paid Service through your&nbsp;<strong>account settings</strong>&nbsp;or terminate your Wash La! account before the end of the recurring TERM. Paid Services cannot be terminated before the end of the period for which you have already paid, and except as expressly provided in these terms, Wash La! will not refund any fees that you have already paid.</li>
                        <li><span className='t-heading-desc-span-1'>Reaffirmation of Authorization.</span> Your non-termination or continued use of a Paid Service reaffirms that we are authorized to charge your Payment Method for that Paid Service. We may submit those charges for payment and you will be responsible for such charges. This does not waive our right to seek payment directly from you. Your charges may be payable in advance, in arrears, per usage, or as otherwise described when you initially selected to use the Paid Service.</li>
                        <li><span className='t-heading-desc-span-1'>Free Trials and Other Promotions.</span> Any free trial or other promotion that provides access to a Paid Service must be used within the specified time of the trial. You must stop using a Paid Service before the end of the trial period in order to avoid being charged for that Paid Service. If you cancel prior to the end of the trial period and are inadvertently charged for a Paid Service, please contact us at&nbsp;<a href="mailto:cs@washla.my"><strong>cs@washla.my</strong></a>. Any and all offers or promotions advertised on the Services are void where prohibited, and are subject to the posting of any official rules to such offers or promotions.</li>
                        <li><span className='t-heading-desc-span-1'>Promotions and Gift Cards. </span> 
                            Wash La! may, in its sole discretion, offer gift cards from time to time (“Gift Cards”). Gift Cards can be used solely to pay for a Paid Service and/or fees or expenses associated with the Paid Services. The following terms shall apply to Gift Cards: (i) Gift Cards must only be used in a lawful manner; (ii) Gift Cards may not be duplicated, sold or transferred in any manner, or made available to the general public (whether posted to a public forum or otherwise), unless expressly permitted by Wash La!; (iii) Wash La! may in its sole discretion
                            stop the offering of Gift Cards at any time for any reason without liability to Wash La!; (iv) Gift Cards may only be used pursuant to the specific terms that Wash La! establishes for such Gift Cards; (v) Gift Cards are not redeemable for cash, and are not returnable or exchangeable for a cash refund (except to the extent required by law); (vi) Gift Cards cannot be combined with cash; (vii) Gift Cards can be purchased only with online payments, coupons and other discounts may not be used to purchase Gift Cards; (viii) lost, stolen or damaged Gift Cards may be replaced
                            only with valid proof of purchase and to the extent the remaining Gift Cards balance appears in Wash La!’s records; (ix) Gift Cards are not a credit or debit card; (x) there are no expiration dates and no fees for non-use of purchased Gift Cards, except as expressly stated otherwise; (xi) expiration dates or other terms may apply to Gift Cards not purchased directly by a consumer; (xii) except as expressly stated in (viii) above, Wash La! is not responsible for any lost, stolen, or damaged Gift Cards or use without your permission; and (xiii) there are no implied warranties
                            for Gift Cards. Invalid or unidentifiable Gift Cards will not be redeemed. In certain states, after a period of time, Wash La! may be required to remit the cash associated with pre-paid but unused Gift Card balances to such states pursuant to their abandoned property laws. Once we have remitted such cash to a state, if someone tries to redeem such gift card, it may no longer be redeemed and Wash La! may direct them to that state’s government instead. To check the available balance on a Gift Card, please email <a href="mailto:cs@washla.my"><strong>cs@washla.my</strong></a>. Wash La! reserves the right to correct the
                            Gift Card balance if we believe that a clerical, billing or accounting error has occurred. You also agree that we may deactivate, cancel or suspend any Gift Card if we suspect fraud, unlawful activity or improper Gift Card use. Gift Card terms and conditions are subject to change without notice.
                        </li>
                    </ol>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-12'>
                <div className='terms-header'>
                    <h2>12. WHAT IF I WANT TO STOP USING THE SERVICES? </h2>
                </div>
                <div className='terms-desc'>
                    <p>You’re free to do that at any time by contacting us at <span><a href="mailto:cs@washla.my">cs@washla.my</a></span>; please refer to our <span><a href="/privacy-policy" target='_parent'>Privacy Policy</a></span>, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.</p>
                    <p>Wash La! is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion, including your breach of these Terms. Wash La! has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms.</p>
                    <p>Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.</p>
                    <p>If you have deleted your account by mistake, contact us immediately at <span><a href="mailto:cs@washla.my">cs@washla.my</a></span> – we will try to help, but unfortunately, we can’t promise that we can recover or restore anything.</p>
                    <p>Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-13'>
                <div className='terms-header'>
                    <h2>13. WHAT ABOUT MOBILE APPLICATIONS? </h2>
                </div>
                <div className='terms-desc'>
                    <p>You acknowledge and agree that the availability of our mobile application is dependent on the third party stores from which you download the application, e.g., the App Store from Apple or the Android app market from Google (each an “App Store”). Each App Store may have its own terms and conditions to which you must agree before downloading mobile applications from such store, including the specific terms relating to Apple App Store set forth below. You agree to comply with, and your license to use our application is conditioned upon your compliance with, such App Store terms and conditions. To the extent such other terms and conditions from such App Store are less restrictive than, or otherwise conflict with, the terms and conditions of these Terms of Use, the more restrictive or conflicting terms and conditions in these Terms of Use apply.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-14'>
                <div className='terms-header'>
                    <h2>14. I USE THE Wash La! APP AVAILABLE VIA THE APPLE APP STORE – SHOULD I KNOW ANYTHING ABOUT THAT? </h2>
                </div>
                <div className='terms-desc'>
                    <p>These Terms apply to your use of all the Services, including our iOS applications (the “Application”) available via the Apple, Inc. (“Apple”) App Store, but the following additional terms also apply to the Application:</p>
                    <ol>
                        <li>Both you and Wash La! acknowledge that the Terms are concluded between you and Wash La! only, and not with Apple, and that Apple is not responsible for the Application or the Content;</li>
                        <li>The Application is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in connection with the Services for your private, personal, non-commercial use, subject to all the terms and conditions of these Terms as they are applicable to the Services;</li>
                        <li>You will only use the Application in connection with an Apple device that you own or control;</li>
                        <li>You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Application;</li>
                        <li>In the event of any failure of the Application to conform to any applicable warranty, including those implied by law, you may notify Apple of such failure; upon notification, Apple’s sole warranty obligation to you will be to refund to you the purchase price, if any, of the Application;</li>
                        <li>You acknowledge and agree that Wash La!, and not Apple, is responsible for addressing any claims you or any third party may have in relation to the Application;</li>
                        <li>You acknowledge and agree that, in the event of any third-party claim that the Application or your possession and use of the Application infringes that third party’s intellectual property rights, Wash La!, and not Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim;</li>
                        <li>You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country, and that you are not listed on any U.S. Government list of prohibited or restricted parties;</li>
                        <li>Both you and Wash La! acknowledge and agree that, in your use of the Application, you will comply with any applicable third-party terms of agreement which may affect or be affected by such use; and</li>
                        <li>Both you and Wash La! acknowledge and agree that Apple and Apple’s subsidiaries are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.</li>
                    </ol>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-15'>
                <div className='terms-header'>
                    <h2>15. CAN I REFER OTHER USERS? </h2>
                </div>
                <div className='terms-desc'>
                    <p>From time to time Wash La! may offer rewards or incentives for referring others to the Services. For details of any current referral offers, please&nbsp;<strong>see our referral page</strong>. The referring user (“Referrer”) may refer individuals who are neither current customers of Wash La! nor registered users of the Services (“Referee”). A registered user is a person that already has an existing account with Wash La!. There is no limit to the number of referrals that Referrer can make, nor the cumulative rewards or incentives that the Referrer may receive through such special offer, unless otherwise indicated. Referrer will receive the stated reward or incentive for each Referee sent by the Referrer that completes the required action described in that specific offer (such as signing up for an account or making a purchase). All Referees must be first-time recipients of the offer, and multiple referrals to the same individual or entity will be disregarded. Wash La! reserves the right to modify or terminate any special offers at any time and to revoke from Referrer and Referee the special offer at Wash La!'s discretion for any reason or for no reason whatsoever. If Wash La! determines that Referrer or Referee is attempting to obtain unfair advantage or otherwise violate the terms or spirit of such special offer, Wash La! reserves the right to (a) revoke any rewards or incentives issued to either Referrer or Referee and/or (b) charge the Referrer or Referee for any rewards or incentives (1) used by Referrer or Referee prior to such revocation or (2) issued by Wash La! to any ineligible Referrer or Referee. All special offers are subject to any other terms, conditions and restrictions set forth on the Services or presented in connection with the special offer.</p>
                </div>
            </div>

            <div className='terms-container' id='termsofuse-16'>
                <div className='terms-header'>
                    <h2>16. WHAT ELSE DO I NEED TO KNOW? </h2>
                </div>
                <div className='terms-desc' id='termsofuse-16-01'>
                    <h4>1) WARRANTY DISCLAIMER </h4>
                    <p>Wash La! and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract employees, representatives and agents, and each of their respective successors and assigns (Wash La! and all such parties together, the “Wash La! Parties”) make no representations or warranties concerning the Services, including without limitation regarding any Content contained in or accessed through the Services, and the Wash La! Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services or any claims, actions, suits procedures, costs, expenses, damages or liabilities arising out of use of, or in any way related to your participation in, the Services. The Wash La! Parties make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through or in connection with the Services. THE SERVICES AND CONTENT ARE PROVIDED BY Wash La! (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
                </div>
                <div className='terms-desc' id='termsofuse-16-02'>
                    <h4>2) LIMITATION OF LIABILITY </h4>
                    <p>You understand that there is inherent risk in the Cleaning Services and there is potential for clothing and related items to get lost or damaged. Wash La! has implemented measures designed to prevent the situations of lost or damaged items from happening, but Wash La! cannot guarantee that such situations will never happen. In the event that the clothing or other items that you sent to Wash La! for the Cleaning Services is damaged or lost as a result of any fault of Wash La! (including our employees), we will work with you and endeavor to rectify the situation, provided that you provide us with written notification identifying the issues with your order of the Cleaning Services with supporting evidence within fourteen (14) days of our delivery of your cleaned clothes and items for such order.</p>
                    <p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE WASH LA! PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, PRODUCTS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF ONE-THOUSAND (RM1,000) RINGGIT, (D) ANY MATTER BEYOND OUR REASONABLE CONTROL.</p>              
                </div>
                <div className='terms-desc' id='termsofuse-16-03'>
                    <h4>3) INDEMNITY </h4>
                    <p>You agree to indemnify and hold the Wash La! Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a third party using your account), and (b) your violation of these Terms. In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).</p>
                </div>
                <div className='terms-desc' id='termsofuse-16-04'>
                    <h4>4) ASSIGNMENT </h4>
                    <p>You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without Wash La!'s prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</p>
                </div>
                <div className='terms-desc' id='termsofuse-16-05'>
                    <h4>5) CHOICE OF LAW </h4>
                    <p>These Terms are governed by and will be construed under the Malaysia Arbitration Act, applicable federal law, without regard to the conflicts of laws provisions thereof.</p>
                </div>
                <div className='terms-desc' id='termsofuse-16-06'>
                    <h4>6) ARBITRATION AGREEMENT </h4>
                    <p><strong>Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with Wash La! and limits the manner in which you can seek relief from Wash La!.</strong></p>
                    <p>Both you and Wash La! acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter of these Terms and/or Services, Wash La!'s officers, directors, employees and independent contractors (“Personnel”) are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.</p>
                    <p>(a)&nbsp;<em>Arbitration Rules; Applicability of Arbitration Agreement.</em>&nbsp;The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of these Terms and/or Services directly through good-faith negotiations, which shall be a precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration in Malaysia or, if the JAMS Consumer Arbitration Minimum Standards are applicable, near or in the county in which you reside. The arbitration will proceed in the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction. The JAMS Consumer Arbitration Minimum Standards shall apply as required by JAMS. The JAMS Optional Arbitration Appeal Procedures shall apply to disputes and claims having a value of RM250,000 or greater.</p>
                    <p>(b)&nbsp;<em>Costs of Arbitration.</em>&nbsp;The Rules will govern payment of all arbitration fees. Wash La! will pay all arbitration fees for claims less than seventy-five thousand (RM75,000) ringgit. Wash La! will not seek its attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous.</p>
                    <p>(c)&nbsp;<em>Small Claims Court; Infringement.</em>&nbsp;Either you or Wash La! may assert claims, if they qualify, in small claims court in Malaysia. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents or other intellectual property rights.</p>
                    <p>(d)&nbsp;<em>Waiver of Jury Trial.</em>&nbsp;YOU AND WASH LA! WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and Wash La! are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. In any litigation between you and Wash La! over whether to vacate or enforce an arbitration award, YOU AND WASH LA! WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.</p>
                    <p>(e)&nbsp;<em>Waiver of Class or Consolidated Actions.</em>&nbsp;ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor Wash La! is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in (g) below.</p>
                    <p>(f)&nbsp;<em>Opt-out.</em>&nbsp;You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the following address: 60 Jalan SS2/72, SS2, 47300 Petaling Jaya, Selangor postmarked within thirty (30) days of first accepting these Terms. You must include (i) your name and residence address, (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out of these Terms’ arbitration agreement.</p>
                    <p>(g)&nbsp;<em>Exclusive Venue.</em>&nbsp;If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement permits either you or Wash La! to litigate any dispute arising out of or relating to the subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either party, and both you and Wash La! agree that any judicial proceeding (other than small claims actions) will be brought in the state or federal courts located in Malaysia.</p>
                    <p>(h)&nbsp;<em>Severability.</em>&nbsp;If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void. This arbitration agreement will survive the termination of your relationship with Wash La!.</p>
                </div>
                <div className='terms-desc' id='termsofuse-16-07'>
                    <h4>7) MISCELLANEOUS </h4>
                    <p>You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that the Wash La! may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and Wash La! agree that these Terms are the complete and exclusive statement of the mutual understanding between you and Wash La!, and that these Terms supersede and cancel all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Wash La!, and you do not have any authority of any kind to bind Wash La! in any respect whatsoever.</p>
                    <p>Except as expressly set forth in the sections above regarding the Apple Application and the arbitration agreement, you and Wash La! agree there are no third-party beneficiaries intended under these Terms.</p>
                </div>

            </div>

        </div>
    );
}

export default TermsOfUseBody;