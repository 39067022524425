import React from "react";
import "./ContactUs.css";

function ContactUs03() {
  const appstore = require("../images/Landing/Landing-29.jpg");
  const googleplay = require("../images/Landing/Landing-28.jpg");

  return (
    <div className="wrap-contactus" id="contactus">
      <div className="contactus-heading">
        <div className="contactus-title">
          <h2>Contact Us</h2>
        </div>

        <div className="contactus-desc">
          {/* <p>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:</p>  */}
          <p>
            If you have other items that are not stated above, no biggie!
            Contact us at{" "}
            <a href="mailto:cs@washla.my" className="contactus-desc-link">
              cs@washla.my
            </a>
            . We are more than happy to work with you on specific items to see
            if we can clean them, how long it may take, and what the estimated
            cost will be.
          </p>
        </div>
      </div>

      <div className="contactus_info">
        <ul className="contactus-items">
          {/* <li className='contactus-item'>
                                <span aria-hidden="true" data-icon="&#xe90b;" className='contact-icon-1'></span>
                                <div>
                                <p className='contactus-item-p-1'>Call Us</p>
                                <a href='tel:+60146222371'>+6014-6222371</a>
                                </div>
                            </li> */}
          <li className="contactus-item">
            <span aria-hidden="true" className="contact-icon-2">
              <i class="fab fa-whatsapp"></i>
            </span>
            <div>
              <p className="contactus-item-p-1">Customer Service Whatsapp</p>
              <a href="tel:+60135377989">+6013-5377989</a>
            </div>
          </li>
          <li className="contactus-item">
            <span
              aria-hidden="true"
              data-icon="&#xe90a;"
              className="contact-icon-1"
            ></span>
            <div>
              <p className="contactus-item-p-1">Email Us</p>
              <a href="mailto:cs@washla.my">cs@washla.my</a>
            </div>
          </li>
          <li className="contactus-item">
            <span
              aria-hidden="true"
              data-icon="&#xe909;"
              className="contact-icon-2"
            ></span>
            <div>
              <p className="contactus-item-p-1">PJ Branch</p>
              <p className="contactus-item-p-2">
                60 Jalan SS2/72, SS2, 47300 Petaling Jaya, Selangor{" "}
              </p>
              <p className="contactus-item-p-3">
                <a href="tel:+60146222371">+6014-6222371</a>
              </p>
            </div>
          </li>
          <li className="contactus-item lg:w-50">
            <span
              aria-hidden="true"
              data-icon="&#xe909;"
              className="contact-icon-2"
            ></span>
            <div>
              <p className="contactus-item-p-1">Seremban Branch</p>
              <p className="contactus-item-p-2">
                Dataran Centrio, 83, Jalan S2 B19, Seremban 2, 70300 Seremban,
                Negeri Sembilan
              </p>
              <p className="contactus-item-p-3">
                <a href="tel:+601110610083">+6011-10610083</a>
              </p>
            </div>
          </li>
          {/* <li className='contactus-item lg:w-50'>
                                <span aria-hidden="true" data-icon="&#xe909;" className='contact-icon-2'></span>
                                <div>
                                <p className='contactus-item-p-1'>Cheras (Trader Square Cheras)</p>
                                <p className='contactus-item-p-2'>76-1-A, Jln Dataran Cheras 4, Dataran Perniagaan cheras, 43200 Cheras, Selangor</p>
                                <p className='contactus-item-p-3'><a href='tel:+60122333757'>+6012-2333757</a></p>
                                </div>
                            </li> */}
          <div className="contactus-download">
            <p className="contactus-item-p-3">Download Our App Now!</p>
            <div className="cont_downloadapp-img">
              <a
                href="https://apps.apple.com/my/app/wash-la/id1582400036"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={appstore}
                  className="c-d-app-img"
                  alt="WashLa AppStore Download"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.washla.my"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={googleplay}
                  className="c-d-app-img"
                  alt="WashLa Google Download"
                />
              </a>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default ContactUs03;
