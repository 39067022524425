import React from 'react';
import '../App.css';
import './ServicesHero.css';
import './SP All CSS.css';


function SPBagCleanPage() {

    // const bagcleaning1 = require('../images/BagCleaning/bag-cleaning.png');
    // const bagcleaning2 = require('../images/BagCleaning/backpack-cleaning.png');

  return (
        <>
            <div className='wrap-bagcleanhero'>
                <div className='servprichero-container'>
                    <div className='servpric-hero-heading'>
                        <h1>Bag Cleaning Services</h1>
                    </div>

                    <div className='servpric-hero-desc'>
                        {/* <p className='servpric-hero-desc-1'>We are Expert in Washing</p> */}
                        <p className='servpric-hero-desc-2'>At Wash La!, we have a team of skilled and experienced dry cleaning experts to clean your most beloved garments so that they are well taken care of and is returned to your arms looking sparkling clean again!</p>
                    </div>
                </div>
            </div>

            <div className="wrap-serv-tabs">
                <div class="services-tabs">
                    <ol class="tab-list">
                        <a href="/services-and-pricing/" class="tab-list-item" target="_top">Our Services</a>
                        <a href="/services-and-pricing/sneaker-care" class="tab-list-item" target="_top">Sneaker Care</a>
                        <a href="/services-and-pricing/wash-and-fold" class="tab-list-item" target="_top">Wash & Fold</a>
                        <a href="/services-and-pricing/dry-cleaning" class="tab-list-item" target="_top">Dry Cleaning</a>
                        <a href="/services-and-pricing/bag-cleaning" class="tab-list-item tab-list-active" target="_top">Bag Cleaning</a>
                        <a href="/services-and-pricing/other-services" class="tab-list-item" target="_top">Other Services</a>
                    </ol>
                </div>
            </div>

            <div className='wrap-bagcleaning' id='bagcleaning'>
                <div className='serv-bagcleaning-banner'>
                    <div className='serv-body-heading'>
                        <h2>Bag Cleaning, Repair and Restoration Service In The Hands of Experts</h2>
                        <p>No matter what luxurious designer brands - Gucci, LV, Coach, Chanel, Charles & Keith… purse or leather bag, as long as you entrust them with the hands of our
                            skilled and experienced cleaning experts, with our best care and treatment, your most beloved bags will transform to look as pristine as it is like they are brand new!</p>               
                    </div>
                </div>

                <div className='serv-content-container'>
                    <h2>How Do We Clean Your Leather bag? </h2>

                    <p>We clean leather with other speciality materials to ensure your items are cleaned properly. We do not dry clean leather because it has its natural oils that need to be preserved.
                        Exposure to any heat (including dry cleaning) may desiccate the leather and lead to cracking, color bleeding and a general loss of the natural oils.</p>
                    <p>We work with some of the best speciality master cleaners in the business to ensure we take care of your leather bags or garments. Some leather items may require additional care,
                        and in that case, we will notify you if your leather item will require an additional cost.</p>
                </div>

                <div className="serv-content-container">
                    <h2>Bag Cleaning Pricing</h2>
                    <p>Enjoy premium cleaning from the comfort of your home and never have to go to the dry cleaners again!</p>

                    <div className='serv-info-container'>
                        <div className='serv-cards-item'>
                            <div className='serv-info-cards'>
                                <div className='serv-info-heading'>
                                    <img src="../assets/images/BagCleaning/backpack-cleaning.png" className='serv-info-logo' alt='Backpack cleaning'/>
                                    <h3>Backpack</h3>
                                </div>
                                <div className='serv-info-pricing'>
                                    <div className='serv-info-desc'>
                                        <p>Wash Backpack: S Size </p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>100</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Wash Backpack: M Size </p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>130</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Wash Backpack: L Size </p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>150</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Repaint Backpack (Minor)</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>150</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Restore (Various Colours) </p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>150</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='serv-cards-item'>
                            <div className='serv-info-cards'>
                                <div className='serv-info-heading'>
                                    <img src="../assets/images/BagCleaning/bag-cleaning.png" className='serv-info-logo' alt='Bag cleaning'/>
                                    <h3>Bag</h3>
                                </div>
                                <div className='serv-info-pricing'>
                                    <div className='serv-info-desc'>
                                        <p>Wash: XS Size (Card Holder)</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>40</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Wash: S Size (Only Hand-carry)</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>100</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Wash: M Size (Sling + Hand-carry)</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>150</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Wash: L Size (Extra Size)</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>200</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Repaint Bag (Minor)</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>150</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Restore Bag (Various Colours) </p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>150</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    
    );
}

export default SPBagCleanPage;