import React from 'react';
import '../App.css';
import './ServicesHero.css';


function SPOtherServPage() {

    // const otherserv1 = require('../images/OtherServices/cap-cleaning.png');

  return (
        <>
            <div className='wrap-otherservhero'>
                <div className='servprichero-container'>
                    <div className='servpric-hero-heading'>
                        <h1>Other Cleaning Services</h1>
                    </div>

                    <div className='servpric-hero-desc'>
                        {/* <p className='servpric-hero-desc-1'>We are Expert in Washing</p> */}
                        <p className='servpric-hero-desc-2'>At Wash La!, apart from the ordinary laundry services, we take pride in being the one-stop full-fledged cleaning services and solutions in Malaysia for any other special items.</p>
                    </div>
                </div>
            </div>

            <div className="wrap-serv-tabs">
                <div class="services-tabs">
                    <ol class="tab-list">
                        <a href="/services-and-pricing/" class="tab-list-item" target="_top">Our Services</a>
                        <a href="/services-and-pricing/sneaker-care" class="tab-list-item" target="_top">Sneaker Care</a>
                        <a href="/services-and-pricing/wash-and-fold" class="tab-list-item" target="_top">Wash & Fold</a>
                        <a href="/services-and-pricing/dry-cleaning" class="tab-list-item" target="_top">Dry Cleaning</a>
                        <a href="/services-and-pricing/bag-cleaning" class="tab-list-item" target="_top">Bag Cleaning</a>
                        <a href="/services-and-pricing/other-services" class="tab-list-item tab-list-active" target="_top">Other Services</a>
                    </ol>
                </div>
            </div>

            <div className='wrap-otherserv' id='otherserv' label='Other Services'>
                <div className='serv-otherserv-banner'>
                    <div className='serv-body-heading'>
                        <h2>Other Special Cleaning & Care Needs by Experts in KL</h2>
                        <p>Any items or fabrics that you can think of, we can do the magic for you – curtains, golf bags, back bags, backpacks, carpets, tablecloths, duvets and etc. These
                            types of fabric and items require special care and attention. So in the hands of our in-house skilled and experienced cleaning experts, we will take a good look to
                            identify the condition to advice what treatment is needed.</p>               
                    </div>
                </div>

                <div className='serv-content-container'>
                    <h2>Other Cleaning Services Pricing</h2>
                    <p>Check out what services you are looking for! We offer on-demand door-to-door pick-up and delivery services in and around Petaling Jaya and Kuala Lumpur to meet your needs!</p>

                    <div className='serv-info-container'>
                        <div className='serv-cards-item'>
                            <div className='serv-info-cards'>
                                <div className='serv-info-heading'>
                                    <img src="../assets/images/OtherServices/cap-cleaning.png" className='serv-info-logo' alt='Cap cleaning'/>
                                    <h3>Cap & Hat</h3>
                                </div>
                                <div className='serv-info-pricing'>
                                    <div className='serv-info-desc'>
                                        <p>Wet Wash All Materials (Length x Width)</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>30</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Dry Wash All Materials</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>50</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Full Color Restoration</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>60</p> 
                                        {/* <p className='serv-price-2'>60 - 150</p> */}
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Backout Cap</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>120</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        <div className='serv-cards-item'>
                            <div className='serv-info-cards'>
                                <div className='serv-info-heading'>
                                    <h3>Other Cleaning Services</h3>
                                </div>
                                <div className='serv-info-pricing'>
                                    <div className='serv-info-desc'>
                                        <p>Luggage (Length x Width)</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>80 - 250</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Golf Bag (Length x Width)</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>80 - 250</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Doll (Length x Width)</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>20 - 300</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Belt</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>30 - 50</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Scarf</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>30 - 50</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Racing Suit</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>150 - 300</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Hand Glove</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>30 - 50</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Baby Stroller/ Car Seat/ Carrier</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>30 - 150</p>
                                    </div>
                                </div>
                                
                            </div>

                            
                        </div>
                    </div>
                </div>

                <div className='serv-content-container'>
                    <h2>Curtain and Golf Bag Cleaning In The Hands of Experts </h2>

                    <p>Have a bad smell coming from your carpet, curtain, golf bag? Dreadful just thinking about cleaning them? Faced with tough stains that is so hard to remove?</p>
                    <p>Cleaning these items could be a tedious and difficult taks for one to manage at home due to the machine capacity and space restriction. But now, you can have these special items
                        cleaned at ease with Wash La! With our years of experience in the cleaning industry, we can ensure your items will be cleaned with utmost care and attention especially with our
                        signature cleaning detergent and method.</p>
                    <p>Different types of curtain and golf bag requires different types of care and cleaning tailored to their fabrics - cotton, polyester, silk, linen, velvet, lace and more. Wash La! will provide
                        you with tips and advice on how to best clean the type of items you have.<br/>
                        At the end of the day, we want to make laundry simple, so you can spend more time on things that actually matter. Catch us in PJ and KL now!</p>
                </div>

            </div>
        </>
    
    );
}

export default SPOtherServPage;