import React, { useEffect } from 'react';
import '../../App.css';
import SPSneakerCarePage from '../SP SneakerCare Page';
import ContactUs02 from '../ContactUs02';
import DocumentMeta from "react-document-meta";

function SPSneakerCare() {

    useEffect(() => {
        document.title = "Sneaker Cleaning Service Malaysia | Shoe Wash Service";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/services-and-pricing/sneaker-care"
            }
        },

        meta: {
            name: {
                title: "Sneaker Cleaning Service Malaysia | Shoe Wash Service",                
                description: "Wash La! is the Leading One-stop shop for Professional and Premium Shoe Wash & Sneaker cleaning services in Petaling Jaya and Kuala Lumpur.",
                canonical:"https://washla.my/services-and-pricing/sneaker-care",
                keywords: "clean, cleaning, repair, sneaker, cleaning service, shoe, repellant treatment, sneaker care, cleaning restoration, Petaling Jaya",
            },
            property: {
                "og:title": "Sneaker Cleaning Service Malaysia | Shoe Wash Service",
                "og:description": "Wash La! is the Leading One-stop shop for Professional and Premium Shoe Wash & Sneaker cleaning services in Petaling Jaya and Kuala Lumpur.",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <SPSneakerCarePage />
            <ContactUs02 />        
        </>
    );
}

export default SPSneakerCare;