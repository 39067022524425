export const TopQuestions = [ 
  {
    category: "My First Wash La! ",
    question: "How do I schedule my first Wash La!? ",
    answer: <p>You can schedule your first Wash La! pickup on our mobile app (available on iOS and Android). 
      <br/><br/>
      If you have any difficulties, feel free to email us at <a href='mailto:cs@washla.my' >cs@washla.my</a>  - our team are always happy to help!
    </p>
  },
  {
    category: "My First Wash La! ",
    question: "What can I expect to happen on my first Wash La!? ",
    answer: <p>Easy! On the evening of your scheduled pickup, a Valet will arrive between 3pm to 5pm. They will bring a transit bag which will all be tagged wth your information. This will then be delivered to our headquarters where our specialist will clean your items and your Valet will bring everything back to you on your confirmed delivery date. </p>    
  },
  {
    category: "Love Wash La!",
    question: "What is the best way for me to tell my friends about Wash La!?",
    answer: <p>The best way to tell your friends is to login to your account, copy the referral code and share it to your friends.
      <br/> <br/>We are thrilled that you have enjoyed your experience at Wash La! enough to share the love with your friends. You will receive RM35 in Wash La! credit for each friend you get to sign up for Wash La! once they complete their first transaction with us. They will also have RM35 in Wash La! credit to get started thanks to you!
      <br/> <br/>We have found that the most effective ways to spread the word is by sharing your custom QR code to people you know who could benefit from some clean sneakers and peace of mind, or to share more broadly on Facebook or Instagram.
    </p>
  },
  {
    category: "Referral",
    question: "How do I join Wash La!’s referral programme? ",
    answer: <p>In your profile, you will be given a unique referral code to invite your friends. Upon registration, your referrals will need to key in your unique referral code and you will both be registered for the referral programme. </p>
  },
  {
    category: "Our Services ",
    question: "How does Wash La! ensure quality? ",
    answer: <p> We take great pride in providing high-quality cleaning for every items you give us. We have developed several steps in our process to ensure that your item always receive the best care.
        <br/><br/><ul>
          <li><strong>We rely on our experience: </strong>It takes experience to recognize a great cleaner. One of our co-founders have had years of experience in the washing and dry cleaning business. </li>
          <li><strong>We work with the best: </strong>We have a thorough vetting process and always make sure that our cleaning specialist are well-managed, environmentally conscious, and follow industry best practices. </li>
          <li><strong>We provide constant feedback: </strong>We work closely with all our cleaning suppliers and provide them with thorough, detailed, and actionable feedback to ensure that they deliver a consistently great product for our operations. </li>
          <li><strong>We have quality built into our internal process: </strong>We have several additional processes outside of the cleaning process in place to ensure that your items are inspected while they are in our care. No item can pass our “quality control” check and be returned to you without a member of our internal team reviewing it. </li>
          <li><strong>We’ll do what it takes to get everything right: </strong>We are confident your items will receive the best quality cleaning possible. If you find a stain that has slipped through the cracks or your items doesn’t meet our (or your) quality standards, please email <a href='mailto:cs@washla.my' >cs@washla.my</a> and we’ll provide a complimentary re-cleaning within a specific timeframe. </li>
        </ul>
    </p>
  }
];

export const FirstWashLa = [
    {
      category: "My First Wash La! ",
      question: "How do I schedule my first Wash La!? ",
      answer: <p>You can schedule your first Wash La! pickup on our mobile app (available on iOS and Android). 
        <br/><br/>
        If you have any difficulties, feel free to email us at <a href='mailto:cs@washla.my' >cs@washla.my</a>  - our team are always happy to help!
      </p>
    },
    {
      category: "My First Wash La! ",
      question: "What can I expect to happen on my first Wash La!? ",
      answer: <p>Easy! On the evening of your scheduled pickup, a Valet will arrive between 3pm to 5pm. They will bring a transit bag which will all be tagged wth your information. This will then be delivered to our headquarters where our specialist will clean your items and your Valet will bring everything back to you on your confirmed delivery date. </p>    
    },
    {
      category: "My First Wash La! ",
      question: "Will you remind me that you are coming by? ",
      answer: <p>Yes, you can expect the text message to arrive on the day of your scheduled pickup. </p>   
    },
    {
      category: "My First Wash La! ",
      question: "What time will you come by? ",
      answer: <p>Our service pickup hours are between 3pm and 5pm. You will receive a text on the day of your scheduled pickup. </p>    
    },
    {
      category: "My First Wash La! ",
      question: "Can I select a time preference? ",
      answer: <p>This service is currently not available.</p>    
    },
    {
      category: "My First Wash La! ",
      question: "How do I give you special instructions? ",
      answer: <p>For any one-time special instructions, please e-mail them to our Customer Service team at <a href='mailto:cs@washla.my' >cs@washla.my</a>, and we'll make sure they get added to your order. </p>    
    },
    {
      category: "My First Wash La! ",
      question: "How do I point out stains? Do I need to point out stains? ",
      answer: <p>We inspect your order multiple times before cleaning to identify and treat stains, so you are not required to point them out to us. 
        <br/><br/>
        However, it is very useful for our cleaning specialist to know the exact source of each stain, since the best treatment will vary by the type of stain and the item type. If you know the source of the stain, please e-mail us at <a href='mailto:cs@washla.my' >cs@washla.my</a>.
      </p>    
    },
    {
      category: "My First Wash La! ",
      question: "What happens after you take my items away? ",
      answer: <p>After we pick up your items our team initiates the inventory process with your order at our headquarters and prepares it for cleaning. You can expect a confirmation of the pickup via email within 24 hours detailing everything we picked up. </p>    
    },
    {
      category: "My First Wash La! ",
      question: "How will I know the total cost? ",
      answer: <p>Your final cost will vary depending on the type of service you choose and if you opt-in for any additional services after communicating with our cleaning specialists. You can expect to receive an itemized recepit in your e-mail's Inbox as well as in-app receipt. 
        <br/><br/>
        You can view our pricing page for the most up-to-date pricing list for all our services.
        </p>   
    },
    {
      category: "My First Wash La! ",
      question: "How do I pay? ",
      answer: <p>Currently, we accept in-app credit top-up payment via credit or debit card, Alipay, Boost, GrabPay, CIMB Pay, Maybank QR, Touch & Go eWallet and ShopeePay. </p>    
    }

];

export const OurServices = [
  {
    category: "Our Services ",
    question: "What services does Wash La! provide? ",
    answer: <p>Wash La! is a one-stop shop for all your item care needs. Our standard services are Premium Wash/Dry Clean, Blackout, Re-painting, Water Repellant Application, and Full Restoration.</p>
  },
  {
    category: "Our Services ",
    question: "Are there items that Wash La! doesn’t clean? ",
    answer: <p>At this time, there are a handful of items that Wash La! cannot accept for cleaning due to the nature of the item or the risk they may pose to our team. We cannot accept items which are not listed for services in our app.
      <br/><br/>We are also not able to accept the following items for cleaning:
      <br/><br/> <strong>Contaminated Items</strong>
      <br/> We cannot accept items that have been contaminated with:
      <ul> 
          <li>Bodily fluids from a human or pet</li>
          <li>Excessive pet hair</li>
          <li>Moisture if the item is still wet or damp</li>
          <li>Mildew</li>
          <li>Bedbugs</li>
          <li>Poison ivy or poison oak oils</li>
          <li>Hazardous chemical residue</li>
      </ul>
    </p>
  },
  {
    category: "Our Services ",
    question: "How does Wash La! ensure quality? ",
    answer: <p> We take great pride in providing high-quality cleaning for every items you give us. We have developed several steps in our process to ensure that your item always receive the best care.
        <br/><br/><ul>
          <li><strong>We rely on our experience: </strong>It takes experience to recognize a great cleaner. One of our co-founders have had years of experience in the washing and dry cleaning business. </li>
          <li><strong>We work with the best: </strong>We have a thorough vetting process and always make sure that our cleaning specialist are well-managed, environmentally conscious, and follow industry best practices. </li>
          <li><strong>We provide constant feedback: </strong>We work closely with all our cleaning suppliers and provide them with thorough, detailed, and actionable feedback to ensure that they deliver a consistently great product for our operations. </li>
          <li><strong>We have quality built into our internal process: </strong>We have several additional processes outside of the cleaning process in place to ensure that your items are inspected while they are in our care. No item can pass our “quality control” check and be returned to you without a member of our internal team reviewing it. </li>
          <li><strong>We’ll do what it takes to get everything right: </strong>We are confident your items will receive the best quality cleaning possible. If you find a stain that has slipped through the cracks or your items doesn’t meet our (or your) quality standards, please email <a href='mailto:cs@washla.my' >cs@washla.my</a> and we’ll provide a complimentary re-cleaning within a specific timeframe. </li>
        </ul>
    </p>
  },
  {
    category: "Our Services ",
    question: "Do you clean leather?",
    answer: <p> Yes, we are able to clean leather. Although we do not dry clean leather, we use other specialty materials cleaning to ensure your items are clean properly. 
        <br/><br/>
      The reason we (and most other cleaners) do not dry clean leather is that it has natural oils that need to be preserved. Exposure to any heat (including dry cleaning) may desiccate the leather and can lead to cracking, colour bleeding, and a general loss of the natural oils. We work with some of the best speciality master cleaners in the business to ensure we take care of your leather garments.
        <br/><br/>
      Pricing typically starts at RM50 per item, and depends on the type of items. Some leather items may require additional care, and in that case we will notify you if your leather item will require an additional cost. If you’d like a specific quote, please email us at <a href='mailto:cs@washla.my' >cs@washla.my</a> and we will make a note on your account.
    </p>    
  },
  {
    category: "Our Services ",
    question: "Can you clean other specialty items? ",
    answer: <p> Although we may not be able to clean every type of item, we are happy to work with you on specific items to see if we can clean them, how long it may take, and what the estimated cost will be. The best way to start this process is to email <a href='mailto:cs@washla.my' >cs@washla.my</a> and let us know what type of item you are looking to clean. </p>
  },
  {
    category: "Our Services ",
    question: "What areas do you serve? ",
    answer: <p>Wash La! is currently available in West Malaysia and we will look into expanding our service area in the future. </p>    
  }, 
  {
    category: "Our Services ",
    question: "What if I want to offer Wash La! as a perk at my Company? ",
    answer: <p>We are always excited when our customers want to share Rinse with their colleagues and employees. If you are interested in exploring how we might be able to work together, please email us at <a href='mailto:cs@washla.my' >cs@washla.my</a> and we'll take care of you. We have several partnerships set up with companies to help them provide employee perks around our Wash La! services.</p>    
  },
  {
    category: "Our Services ",
    question: "Do you accept sneakers or clothing donations? ",
    answer: <p>While we do typically pick up donations, we have temporarily paused all donation pickups while we navigate COIVD-19. Please contact <a href='mailto:cs@washla.my' >cs@washla.my</a> if you have any questions about our donation policy, and feel free to check back here for future updates about restarting our donation pickups.</p>    
  }

];

export const Referral = [
  {
    category: "Referral",
    question: "How do I join Wash La!’s referral programme? ",
    answer: <p>In your profile, you will be given a unique referral code to invite your friends. Upon registration, your referrals will need to key in your unique referral code and you will both be registered for the referral programme. </p>
  },
  {
    category: "Referral",
    question: "What do I get if I join the referral programme? ",
    answer: <p>Upon a successful transaction by your referral, you will be credited with a one-time only RM20 credit into your account. This one-time only RM20 credit is non-transferrable and non-refundable. Your referral will also be credited with a one-time only RM20 credit upon a successful transaction. </p>
  },
  {
    category: "Referral",
    question: "What is the maximum numbers of referrals I can refer to the programme? ",
    answer: <p>You are free to refer as many friends to Wash La! app as you like! </p>
  }

];

export const MyAccount = [
  {
    category: "My Account",
    question: "How do I update my contact information? ",
    answer: <p> </p>
  },
  {
    category: "My Account",
    question: "How do I reset my password?",
    answer: <p> </p>
  },
  {
    category: "My Account",
    question: "How do I find my custom link to share Wash La! with my friends? ",
    answer: <p> </p>
  },
  {
    category: "My Account",
    question: "Where can I view my Order History?",
    answer: <p> </p>
  }

];

export const PickUp = [
  {
    category: "Pickup and Delivery",
    question: "Do you charge a delivery fee? ",
    answer: <p>A minimum of RM15 will be charged for orders of only one item. No delivery fees will be charged if the order consists of more than one item. </p>
  },
  {
    category: "Pickup and Delivery",
    question: "Can I receive a delivery if I’m not home?",
    answer: <p>If you are not available during our delivery window, our Valet will re-schedule the delivery with you. </p>
  },
  {
    category: "Pickup and Delivery",
    question: "Is it possible to have my items delivered to an alternate address? ",
    answer: <p>Currently, we do not offer this option. </p>
  }

];

export const Billing = [
  {
    category: "Billing and Payments",
    question: "Does Wash La! have a minimum order amount? ",
    answer: <p>Yes. There is a RM35 order minimum that applies to the cost of services, before any discounts, applicable taxes and delivery fees.
        <br/><br/>
      We accept orders under the RM35 minimum but those orders will be subject to a fee that reflects the difference. (In other words, you’ll pay a minimum of RM35 per order, even if the cost of services is less than RM35).
    </p>    
  },
  {
    category: "Billing and Payments",
    question: "Does the minimum order apply to all services? ",
    answer: "Yes. "
  },
  {
    category: "Billing and Payments",
    question: "How is the minimum order adjustment calculated? ",
    answer: <p>Wash La!’s order minimum is RM35, which applies to the cost of services before any discounts, applicable taxes, and delivery fees.
        <br/><br/>
        The adjustment is charged when an order doesn’t meet the minimum. It’s calculated as the difference between the total services amount (before discounts, applicable taxes, and delivery fees) and RM35
    </p>
  },
  {
    category: "Billing and Payments",
    question: "How much does Wash La! cost? ",
    answer: <p>We offer many cleaning services which vary in price. To view specific prices, please visit our Pricing and Services page. Alternatively, you may browse our in-app for more details.</p>
  },
  {
    category: "Billing and Payments",
    question: "Do you have hidden fees? ",
    answer: <p>No, there are no hidden fees associated with Wash La!. Although much of our industry engages in 'upcharging' via hidden fees, we strive to be fully transparent and do not upcharge on any items. Any additional charges on additional services will be communicated to you and will be signed off by you before we proceed. If you ever have any questions on billing, please email us at <a href='mailto:cs@washla.my' >cs@washla.my</a> and we'll take care of you. </p>
  }

];

export const Policies = [
  {
    category: "Policies",
    question: "Does Wash La! re-clean items if the stain doesn’t come out? ",
    answer: <p>Since our items are hands-washed, there are rare occasions that some stains might be missed by our Quality Control department. We do sincerely apologize if such an incidence should occur. If you do find stains within 24 hours, please contact our Customer Service through our app or send an e-mail to <a href='mailto:cs@washla.my' >cs@washla.my</a>  </p>
  },
  {
    category: "Policies",
    question: "What is Wash La!’s policy on items with risk of damage during cleaning?",
    answer: <p>Upon appraisal of the item, if we find the risk of damage to be high, we will contact you immediately to request for your consent to proceed and to release Wash La! of any liabilities if damage should occur. </p>
  },
  {
    category: "Policies",
    question: "What is Wash La!’s policy on items with faux leather? ",
    answer: <p>Before proceeding, we ask that you give us your approval and release Wash La! of any liability if damage should occur as there are some inherent risks to cleaning all leather-like materials.
        <br /><br />
      Although it is not risky as cleaning real leather, faux leather is made of plastics still have risks of cracking, color bleeding, or melting when exposed to heat. We take full precaution to protect all faux leather items depending on the care label. 
        <br /><br />
      If you would like to provide a permanent preference to clean all items with faux leather, regardless of risk, please let us know at <a href='mailto:cs@washla.my' >cs@washla.my</a> and we will make a note on your account. 
     </p>
  },
  {
    category: "Policies",
    question: "What does Wash La! do when it finds items with potential for colour bleeding? ",
    answer: <p>Upon appraisal of the item, if there is a potential for colour bleeding, we will contact you immediately to request for your consent for additional services.  </p>
  },
  {
    category: "Policies",
    question: "What is Wash La!’s policy when it finds pre-existing damages? ",
    answer: <p>We will contact you and advise if it is possible to proceed with cleaning the item. </p>
  },
  {
    category: "Policies",
    question: "What happens if Wash La! loses or damages one of my items? ",
    answer: <p>While we take extreme care of all your items, there are times that a situation where an error may occur out of our control since all items are hands-washed. We do sincerely apologize for such an incidence. In the event this happens, our customer service will contact you within 24 hours to provide a solution. </p>
  }

];

export const LoveWashLa = [
  {
    category: "Love Wash La!",
    question: "What is the best way for me to tell my friends about Wash La!?",
    answer: <p>The best way to tell your friends is to login to your account, copy the referral code and share it to your friends.
      <br/> <br/>We are thrilled that you have enjoyed your experience at Wash La! enough to share the love with your friends. You will receive RM35 in Wash La! credit for each friend you get to sign up for Wash La! once they complete their first transaction with us. They will also have RM35 in Wash La! credit to get started thanks to you!
      <br/> <br/>We have found that the most effective ways to spread the word is by sharing your custom QR code to people you know who could benefit from some clean sneakers and peace of mind, or to share more broadly on Facebook or Instagram.
    </p>
  },
  {
    category: "Love Wash La!",
    question: "What rewards can I earn if I share with friends? ",
    answer: <p>Upon a successful transaction by your referral, you will be credited with a one-time only RM20 credit into your account. This one-time only RM20 credit is non-transferrable and non-refundable. Your referral will also be credited with a one-time only RM20 credit upon a successful transaction. </p>
  },
  {
    category: "Love Wash La!",
    question: "How can I get everyone at my company to use Wash La!? ",
    answer: <p>We are always excited when our customers want to share Wash La! with their colleagues and employees. If you are interested in exploring how we might be able to work together, please e-mail us at <a href='mailto:cs@washla.my' >cs@washla.my</a> and we’ll take care of you. We have several partnerships set up with companies to help them provide employee perks.  </p>
  },
  {
    category: "Love Wash La!",
    question: "How can I get everyone in my apartment building to use Wash La!? ",
    answer: <p>The easiest way to get everyone in your apartment building to use Wash La! is to share your referral code with them so that they will receive RM20 in Wash La! credit to start. For everyone who signs up via your referral code and uses Wash La!, you will earn RM20 in Wash La! credit for yourself after their first transaction is complete.
        <br /><br />
      That said, we are always looking for Apartment Ambassadors who can help us spread the word. Since every apartment building is different, and the norms within those buildings are different, we are open to any suggestions you have. Let us know how we can help you share the love and we will make it happen! E-mail <a href='mailto:cs@washla.my' >cs@washla.my</a> with any ideas or suggestions you have, or if you’re interested in a custom partnership with Wash La! 
     </p>
  },
  {
    category: "Love Wash La!",
    question: "I want to work for Wash La!...what do I have to do? ",
    answer: <p>We are always excited to add amazing people to our team. If you are interested in working for Wash La!, please check out our job openings or e-mail <a href='mailto:cs@washla.my' >cs@washla.my</a> with your resume and tell us why you should work for Wash La!.  </p>
  }

];
