import React from 'react';
import '../App.css';
import './RefundBody.css';



function RefundBody () {

    return (

        <div className='wrap-refund'>
            <div className='refund-heading-container'>
                <div className='refund-heading'>
                    <h1>Sales & Refund Policy</h1>
                </div>
                <div className='refund-date-eff'>
                    <p>Effective Date: July 1, 2021</p>
                </div>

            </div>

            <div className='refund-desc-container'>
                <ol>
                    <li>If the client wishes to proceed with the refund process from Wash La! all communications can be done via our apps or contacting our customer service.</li>
                    <li>Wash La! practices customer’s satisfaction guarantee as our main policy. Wash La! grants all customers with a Onetime free re-wash in the event customers is not satisfied with our initial service. This is only applicable if the customers contact us and inform Wash La! within 24 hours upon receiving the goods.</li>
                    <li>The goods that customers wish to send back to Wash La! for rewashing purpose, the delivery fees will be borne by the customer.</li>
                    <li>Nevertheless, there are rare occasions that the condition of the goods delivered to Wash La! is unable to proceed for any of our premium services, Wash La! will refund the said sum excluding delivery fees back to customer’s in-apps wallet within 1 working day and the goods will be delivered back to the customers along with other goods.</li>
                    <li>The conditions of the goods which are unable to proceed with any of our services includes the following but not limited to,
                        <ul>
                            <li>Pulverization</li>
                            <li>Chalking</li>
                            <li>Cracking</li>
                            <p>In the event these occurs, Wash La! customer service will contact customer for consent either to proceed or return.</p>
                        </ul>
                    </li>
                    <li>All refund will be done back to Wash La! in-app wallet within 24 hours upon confirmation from customers.</li>
                    <li>As for customers that would like to withdraw their in-app wallet balance, they can contact our customer service. Upon request, the wallet balance will be directly debited into customer’s personal bank account within 1 working day.</li>
                </ol>
            </div>
        </div>
    );
}

export default RefundBody;