import React from 'react';
import '../App.css';
import './BlogMain.css';


function BlogItem01 () {

    // const article_img_1 = require('../images/Articles/Gucci-Ophidia.png');
    // const article_img_2 = require('../images/Articles/how-to-clean-gucci-suede-bag.jpg');
    // const article_img_3 = require('../images/Articles/Cleaning-bag.png');
    // const article_img_4 = require('../images/Articles/leather-care-birkin.jpg');
    // const article_img_5 = require('../images/Articles/Clean-gucci-leather.png');
    // const article_img_6 = require('../images/Articles/Square-Gucci-Bag-1-during-2.jpg');
    
    // const bagcleaning1 = require('../images/BagCleaning/bag-cleaning.png');
    // const bagcleaning2 = require('../images/BagCleaning/backpack-cleaning.png');

    // const b_appdownload_1 = require('../images/Landing/Landing-29.jpg');
    // const b_appdownload_2 = require('../images/Landing/Landing-28.jpg');

    return (

        <div className='wrap-blog-1'>

            <div className='blog-banner-container-1'>
                <div className='blog-b-heading'>
                    <h1>6 Steps To Clean Your Gucci Purse or leather bag (Without Ruining It) </h1>
                </div>
            </div>

            <div className="blog-content-container-1">
                <div className="blog-content-item-1">
                    <p>The secret sauce for your luxurious designer purse and leather bags like Gucci, LV, Coach (and many more) to last for years and still look pristine is to give them a proper treatment by pampering and protecting them with professional thorough and regular cleaning.</p>
                    <p>These luxurious designer brands are excellent fashion accessories made from high-quality materials like premium leather and solid metals. Hence they require regular extra care to clean it and keep them free from a daily dose of surface dirt, stains and grime.</p>
                    
                    <p>Let’s talk about <a href="/services-and-pricing/bag-cleaning" target="_top" rel="noreferrer noopener" >cleaning your Gucci purse and bag</a>. 
                        <br />So what can you do to clean your Gucci purse and bag regularly at home?</p>
                    <p><strong>Things You'll Need to Clean a Gucci Bag</strong>
                        <ul>
                            <li>Mild liquid detergent</li>
                            <li>Clean, white absorbent cloth </li>
                            <li>Terry cloth towel</li>
                            <li>Sponge</li>
                            <li>Cotton swab</li>
                            <li>Suede or nylon brush</li>
                            <li>Suede eraser</li>
                            <li>Anti-bacterial wipes</li>
                            <li>Rubbing alcohol</li>
                            <li>Leather conditioner</li>
                            <li>Neutral cream polish</li>
                        </ul>
                    </p>
                </div>

                <div className="blog-content-item-2">
                    <div className="blog-cont-item-2-01">
                        <div className="blog-item-desc-01">
                            <img src="../assets/images/Articles/Gucci-Ophidia.png" alt="Gucci Ophidia"/>
                        </div>

                        <div className="blog-item-desc-02">
                            <p className="blog-desc-p1">1. Remove everything from your Gucci purse </p>
                            <p className="blog-desc-p2">Unzip all the inner pockets to remove everything including the small items. Turn the empty purse upside down to remove loose dust and debris on top of a dustbin.</p>
                        </div>
                    </div>
                    
                    <div className="blog-cont-item-2-02">
                        <div className="blog-item-desc-01">
                            <img src="../assets/images/Articles/how-to-clean-gucci-suede-bag.jpg" alt="How to clean gucci suede bag"/>
                        </div>

                        <div className="blog-item-desc-02">
                            <p className="blog-desc-p1">2. Clean your Gucci purse or bag with a suede or nylon brush </p>
                            <p className="blog-desc-p2">With a small back and forth scrubbing motion, gently brush the surface of the purse or bag to lift the dirt.</p>
                        </div>
                    </div>

                    <div className="blog-cont-item-2-01">
                        <div className="blog-item-desc-01">
                            <img src="../assets/images/Articles/Cleaning-bag.png" alt="Cleaning bag"/>
                        </div>

                        <div className="blog-item-desc-02">
                            <p className="blog-desc-p1">3. Clean your leather purse or bag with cloth dipped in lukewarm water and soap </p>
                            <p className="blog-desc-p2">Add two drops of mild liquid detergent and two cups of water into a bowl to create suds. Dampen a terry cloth towel into the suds and rub across the surface of the bag to clean.</p>
                            <p className="blog-desc-p2">After the surface dries, squeeze a small amount of leather conditioner into a terry cloth towel and rub it on the bag to condition and restore moisture to the leather, preventing it from drying and keeping your leather bag and purse soft and smooth.</p>
                        </div>
                    </div>

                    <div className="blog-cont-item-2-02">
                        <div className="blog-item-desc-01">
                            <img src="../assets/images/Articles/leather-care-birkin.jpg" alt="Leather care birkin"/>
                        </div>

                        <div className="blog-item-desc-02">
                            <p className="blog-desc-p1">4. Clean and disinfect your purse and bag weekly </p>
                            <p className="blog-desc-p2">This is an important step because disease-causing microbes may rest and gather on the handles and bottom of your bags. So make sure to regularly disinfect your leather Gucci purse or bag once a week with anti-bacterial wipes or a terry cloth towel dampened with rubbing alcohol.</p>
                        </div>
                    </div>

                    <div className="blog-cont-item-2-01">
                        <div className="blog-item-desc-01">
                            <img src="../assets/images/Articles/Clean-gucci-leather.png" alt="Clean gucci leather"/>
                        </div>

                        <div className="blog-item-desc-02">
                            <p className="blog-desc-p1">5. Spot clean your canvas, nylon or straw purses </p>
                            <p className="blog-desc-p2">Scrape the surface of your fabric bags with a soft and small-bristled brush to remove the dirt. Then wipe it off with a clean sponge dipped in a bowl of sudsy water – 1 cup of water and a drop of mild liquid soap.</p>
                        </div>
                    </div>

                    <div className="blog-cont-item-2-02">
                        <div className="blog-item-desc-01">
                            <img src="../assets/images/Articles/Square-Gucci-Bag-1-during-2.jpg" alt="Square Gucci Bag 1 during 2"/>
                        </div>

                        <div className="blog-item-desc-02">
                            <p className="blog-desc-p1">6. Remove scuffs off patent leather </p>
                            <p className="blog-desc-p2">Rub the surface of your Gucci purse or bag with a terry cloth towel or microfiber cloth to double clean and remove any dirt. Use it to go in small circular strokes to lift the dark scuff marks off the surface of your purses.</p>
                            <p className="blog-desc-p2">If you find that the scuff marks still remain, take a cotton swab dipped in rubbing alcohol to press and rub it into the scuff marks. Let the alcohol sit for 10 seconds then rub it away with a clean absorbent cloth.</p>
                        </div>
                    </div>

                    <div className="blog-cont-item-2-03">
                        <p>So…. Do you find that cleaning your Gucci purse or leather bag takes too much work?</p>
                        <p>You’re not alone. That’s why there are various professional purse and leather bag cleaning services out there to help your precious ‘babies’ get cleaned by experts for 100% effective and satisfying results.</p>
                    </div>
                </div>
                
                <div className="blog-content-item-3">
                    <h2>We Are Leading Purse & Leather Bag Cleaning, Repair and Colour Restore Service In PJ and KL </h2>

                    <p>Whether it’s your everyday purse or limited-edition Gucci, Coach or LV leather bag design that you spent tons on and lost sleep hunting down, we provide delicate professional care for true purse and leather bag enthusiasts and appreciators just like you. </p>
                    <p>In the hands of capable and experienced <a href="/services-and-pricing/bag-cleaning" target="_top" rel="noreferrer noopener">purses and leather bag cleaning experts</a>, your most treasured purse or leather bag will look (nearly) brand new again.</p>
                    <p>The pricier your purses or leather bags are, the more care it takes to keep them clean and pristine. Though some may argue that they can try to clean up the tough stains and built-up grime on your own at home, you certainly need the ministration of a professional.</p>
                    <p>Wash La! has a team of purses and leather bags cleaning specialists located in and around the Petaling Jaya and Kuala Lumpur to take care of your precious old but cherished ‘babies’ to look refreshed and pristine like they just got out of a “shoecation”.</p>

                    <div className="blog-washla-serv">
                        <h3>Wash La! Services</h3>

                        <div className="blog-washla-serv-item-1">
                            <div className='serv-info-container'>
                                <div className='serv-cards-item'>
                                    <div className='serv-info-cards'>
                                        <div className='serv-info-heading'>
                                            <img src="../assets/images/BagCleaning/backpack-cleaning.png" className='serv-info-logo' alt='Backpack cleaning'/>
                                            <h3>Backpack</h3>
                                        </div>
                                        <div className='serv-info-pricing'>
                                            <div className='serv-info-desc'>
                                                <p>Wash Backpack: S Size </p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>100</p>
                                            </div>
                                        </div>
                                        <div className='serv-info-pricing-2'>
                                            <div className='serv-info-desc'>
                                                <p>Wash Backpack: M Size </p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>130</p>
                                            </div>
                                        </div>
                                        <div className='serv-info-pricing-2'>
                                            <div className='serv-info-desc'>
                                                <p>Wash Backpack: L Size </p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>150</p>
                                            </div>
                                        </div>
                                        <div className='serv-info-pricing-2'>
                                            <div className='serv-info-desc'>
                                                <p>Repaint Backpack (Minor)</p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>150</p>
                                            </div>
                                        </div>
                                        <div className='serv-info-pricing-2'>
                                            <div className='serv-info-desc'>
                                                <p>Restore (Various Colours) </p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>150</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='serv-cards-item'>
                                    <div className='serv-info-cards'>
                                        <div className='serv-info-heading'>
                                            <img src="../assets/images/BagCleaning/bag-cleaning.png" className='serv-info-logo' alt='Bag cleaning'/>
                                            <h3>Bag</h3>
                                        </div>
                                        <div className='serv-info-pricing'>
                                            <div className='serv-info-desc'>
                                                <p>Wash: XS Size (Card Holder)</p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>40</p>
                                            </div>
                                        </div>
                                        <div className='serv-info-pricing-2'>
                                            <div className='serv-info-desc'>
                                                <p>Wash: S Size (Only Hand-carry)</p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>100</p>
                                            </div>
                                        </div>
                                        <div className='serv-info-pricing-2'>
                                            <div className='serv-info-desc'>
                                                <p>Wash: M Size (Sling + Hand-carry)</p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>150</p>
                                            </div>
                                        </div>
                                        <div className='serv-info-pricing-2'>
                                            <div className='serv-info-desc'>
                                                <p>Wash: L Size (Extra Size)</p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>200</p>
                                            </div>
                                        </div>
                                        <div className='serv-info-pricing-2'>
                                            <div className='serv-info-desc'>
                                                <p>Repaint Bag (Minor)</p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>150</p>
                                            </div>
                                        </div>
                                        <div className='serv-info-pricing-2'>
                                            <div className='serv-info-desc'>
                                                <p>Restore (Various Colours) </p>
                                            </div>
                                            <div className='serv-info-price'>
                                                <p className='serv-price-1'>MYR</p>
                                                <p className='serv-price-2'>150</p>
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                            
                        </div>

                        <p>A pick-up and drop-off service can be arranged within the Klang Valley. Our fixed price for delivery is RM15. However, you can qualify for a FREE pick up and delivery with two or more items!</p>
                        <p>See more info about our Pricelist on our mobile app: <strong>Wash La!</strong></p>
                        <div className="sched-b-cont-2-download">
                            <a href='https://apps.apple.com/my/app/wash-la/id1582400036' target='_blank' rel='noreferrer noopener'><img src="../assets/images/Landing/Landing-29.jpg" alt="WashLa! App Download AppStore"/></a>
                            <a href='https://play.google.com/store/apps/details?id=com.washla.my' target='_blank' rel='noreferrer noopener'><img src="../assets/images/Landing/Landing-28.jpg" alt="WashLa! App Download Google Playstore"/></a>
                        </div>
                    
                    </div>
                </div>

            </div>
            
        </div>
    );
}

export default BlogItem01;