import React, { useEffect } from 'react';
import '../../App.css';
import ContactUsContent from '../ContactUsPageContent';
import DocumentMeta from "react-document-meta";

function ContactPage() {

    useEffect(() => {
        document.title = "Laundry Service In Kuala Lumpur | Leather Bag Cleaner Malaysia";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/contact-us"
            }
        },

        meta: {
            name: {
                title: "Laundry Service In Kuala Lumpur | Leather Bag Cleaner Malaysia",                
                description: "Wash La! is Malaysia’s leading laundry and leather bag cleaning service with door-to-door pickup and delivery service in and around PJ and Kuala Lumpur.",
                canonical:"https://washla.my/contact-us",
                keywords: "Laundry, cleaning, cleaning service, wash, restoration, delivery service, malaysia, care, leather bag, KL, Petaling Jaya, Contact",
            },
            property: {
                "og:title": "Laundry Service In Kuala Lumpur | Leather Bag Cleaner Malaysia",
                "og:description": "Wash La! is Malaysia’s leading laundry and leather bag cleaning service with door-to-door pickup and delivery service in and around PJ and Kuala Lumpur.",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 
        
            <ContactUsContent />

        </>
    ); 
}

export default ContactPage;