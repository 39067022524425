import React from 'react';
import '../App.css';
import './BlogMain.css';
import { TiArrowRightThick } from "react-icons/ti";



function BlogMain () {

    // const blog_1 = require('../images/Articles/Gucci-sling-bag.png');

    return (

        <div className='wrap-blog_main'>

            <div className="wrap-blogmhero">
                <div className='blogmhero-container'>
                    <div className='blogm-hero-heading'>
                        <h1>Articles/News</h1>
                    </div>

                    <div className='blogm-hero-desc'>
                        <p>Let's talk about tips on cleaning variety of stuffs from your shoes to your everyday use!</p>
                    </div>
                </div>
            </div>

            <div className="blogm-body-container">
                <div className="blogm-content-1">
                    <div className="blogm-cont-item-1">
                        <a href="/blogs/6-steps-to-clean-your-gucci-purse-or-leather-bag" className="blogm-cont-item-1-01" target="_top" >
                            <img src="assets/images/Articles/Gucci-sling-bag.png" alt="Gucci sling bag" />
                            <div class="blogm-overlay">
                                {/* <div class="blogm-overlay-text">See Article</div> */}
                            </div>
                        </a>
                    </div>

                    <div className="blogm-cont-item-2">
                        <p className="blogm-cont-item-2-date">30 / 05 / 2022</p>
                        <h2>6 Steps To Clean Your Gucci Purse or leather bag (Without Ruining It)</h2>
                        <p>Let’s talk about cleaning your Gucci purse and bag.
                            So what can you do to clean your Gucci purse and bag regularly at home?</p>
                            
                        <a href="/blogs/6-steps-to-clean-your-gucci-purse-or-leather-bag" target="_top" className="blogm-cont-item-2-btn"><button>See Article </button><TiArrowRightThick /></a>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default BlogMain;