import React from "react";
import "../App.css";
import "./SP All CSS.css";

function SPWashFoldPage() {
  // const washandfold1 = require('../images/WashFold/laundry-step-1.png');
  // const washandfold2 = require('../images/WashFold/laundry-step-2.png');
  // const washandfold3 = require('../images/WashFold/laundry-step-3.png');
  // const washandfold4 = require('../images/WashFold/laundry-step-4.png');
  // const washandfold5 = require('../images/WashFold/Rinse-Repeat.png');
  // const washandfold6 = require('../images/WashFold/Pay-As-You-Go.png');

  // const appstore = require('../images/Landing/Landing-29.jpg');
  // const googleplay = require('../images/Landing/Landing-28.jpg');

  return (
    <>
      <div className="wrap-washfoldhero">
        <div className="servprichero-container">
          <div className="servpric-hero-heading">
            <h1>Wash & Fold Laundry Service</h1>
          </div>

          <div className="servpric-hero-desc">
            {/* <p className='servpric-hero-desc-1'>We are Expert in Washing</p> */}
            <p className="servpric-hero-desc-2">
              Wash La! Also provide normal wash, dry and fold service for your
              everyday wear, occasional outfits, comforters, bed sheets and etc
              with personalized care.
            </p>
          </div>
        </div>
      </div>

      <div className="wrap-serv-tabs">
        <div class="services-tabs">
          <ol class="tab-list">
            <a
              href="/services-and-pricing/"
              class="tab-list-item"
              target="_top"
            >
              Our Services
            </a>
            <a
              href="/services-and-pricing/sneaker-care"
              class="tab-list-item"
              target="_top"
            >
              Sneaker Care
            </a>
            <a
              href="/services-and-pricing/wash-and-fold"
              class="tab-list-item tab-list-active"
              target="_top"
            >
              Wash & Fold
            </a>
            <a
              href="/services-and-pricing/dry-cleaning"
              class="tab-list-item"
              target="_top"
            >
              Dry Cleaning
            </a>
            <a
              href="/services-and-pricing/bag-cleaning"
              class="tab-list-item"
              target="_top"
            >
              Bag Cleaning
            </a>
            <a
              href="/services-and-pricing/other-services"
              class="tab-list-item"
              target="_top"
            >
              Other Services
            </a>
          </ol>
        </div>
      </div>

      <div className="wrap-washandfold" id="washandfold">
        <div className="serv-washandfold-banner">
          <div className="serv-body-heading">
            <h2>
              Your Wash & Fold Cleaning Services In Kuala Lumpur and Petaling
              Jaya
            </h2>
            <p>
              At Wash La!, our team of clean freaks will separate our customer’s
              garments for hygienic purposes, so all of your garments will be
              tested for color bleeing before processing them for each cleaning
              cycle. We are once again proven to be the best bet for any laundry
              cleaning services in Kuala Lumpur and Petaling Jaya, with the
              bonus of pick-up and delivery services provided (Coming Soon). All
              these to provide you the easiest and most convenient way to get
              your laundry done and dusted!
            </p>
          </div>
        </div>

        <div className="washfold-container">
          <div className="washfold-content-1">
            <div className="washfold-content-1-h">
              <img
                src="../assets/images/WashFold/laundry-step-1.png"
                className="washfold-img"
                alt="Laundry Step 1"
              />
              <h3>Detailed Inspection</h3>
            </div>
            <div className="Oval-Copy-10"></div>
            <div>
              <p>
                Your pockets and cloths are inspected (so nothing ends up in the
                wash that shouldn’t) before being placed in their own machine.
              </p>
            </div>
          </div>
          <div className="washfold-content-1">
            <div className="washfold-content-1-h">
              <img
                src="../assets/images/WashFold/laundry-step-2.png"
                className="washfold-img"
                alt="Laundry Step 2"
              />
              <h3>Premium Cleaning</h3>
            </div>
            <div className="Oval-Copy-10"></div>
            <div>
              <p>
                Your lights and darks are separated and all your clothes are
                washed using cold water to preserve color (and save energy).
              </p>
            </div>
          </div>
          <div className="washfold-content-1">
            <div className="washfold-content-1-h">
              <img
                src="../assets/images/WashFold/laundry-step-3.png"
                className="washfold-img"
                alt="Laundry Step 3"
              />
              <h3>Your Preferences</h3>
            </div>
            <div className="Oval-Copy-10"></div>
            <div>
              <p>
                Need hypoallergenic detergent? Want fabric softener? No problem
                just select the laundry preferences that are right for you.
              </p>
            </div>
          </div>
          <div className="washfold-content-1">
            <div className="washfold-content-1-h">
              <img
                src="../assets/images/WashFold/laundry-step-4.png"
                className="washfold-img"
                alt="Laundry Step 4"
              />
              <h3>Neatly Folded</h3>
            </div>
            <div className="Oval-Copy-10"></div>
            <div>
              <p>
                Your clothes are crisply folded and your socks are paired, ready
                to be put away upon delivery.
              </p>
            </div>
          </div>
        </div>

        <div className="serv-content-container">
          <h2>Wash & Fold Pricing</h2>
          <p>
            Give yourself some rest! This is the ideal service for your everyday
            laundry needs. Save hours per week and receive them freshly cleaned
            and perfectly folded.
          </p>
          <div className="washfold-content-2">
            <div className="washfold-pricing">
              <div className="washfold-info-cards">
                <div className="washfold-info-heading">
                  <img
                    src="../assets/images/WashFold/Rinse-Repeat.png"
                    className="washfold-info-logo"
                    alt="Rinse Repeat"
                  />
                  <h3>Rinse Repeat</h3>
                  {/* <p>Monthly Subscription</p> */}
                </div>
                <div className="washfold-details">
                  <ul className="washfold-details-list">
                    <li>No Service Fee</li>
                    <li>Free Next-Day Delivery</li>
                    <li>No Minimum Order</li>
                    <li>Unlimited Rollover</li>
                  </ul>
                </div>
                <div className="washfold-info-pricing">
                  <div className="washfold-info-desc">
                    <p>Monthly Subscription Price</p>
                  </div>
                  <div className="washfold-info-price">
                    {/* <p className='washfold-price-1'>MYR</p> */}
                    {/* <p className='washfold-price-2'>5.00</p><span>/KG</span> */}
                    <p className="washfold-price-2">Coming Soon</p>
                  </div>
                </div>
              </div>

              <div className="washfold-info-cards">
                <div className="washfold-info-heading">
                  <img
                    src="../assets/images/WashFold/Pay-As-You-Go.png"
                    className="washfold-info-logo"
                    alt="Pay-As-You-Go"
                  />
                  <h3>Pay-As-You-Go</h3>
                  {/* <p>Per Use</p> */}
                </div>
                <div className="washfold-details">
                  <ul className="washfold-details-list">
                    <li>
                      RM 5.00 Service Fee
                      <span className="peroder">/Per Order</span>
                    </li>
                    <li>
                      + RM 2.00 Next-Day Delivery
                      <span className="peroder">/Per Order</span>
                    </li>
                    <li>RM 30 Minumum Order</li>
                  </ul>
                </div>
                <div className="washfold-info-pricing">
                  <div className="washfold-info-desc">
                    <p>Per Use Price</p>
                  </div>
                  <div className="washfold-info-price">
                    {/* <p className='washfold-price-1'>MYR</p>
                                        <p className='washfold-price-2'>7.00</p><span>/KG</span> */}
                    <p className="washfold-price-2">Coming Soon</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="serv-content-container">
          <h2>More than 10 years of experiences</h2>

          <p>
            The Wash La! team takes pride in being the forefront of providing
            our customers with professional and efficient service, being the
            best laundry services in town! Our cleaning uses our special laundry
            detergent and water to clean machine washable clothes, which is then
            nicely folded and pressed before returning them into your arms. At
            Wash La!, our main goal has always been to fulfill customers’
            cleaning needs and give absolute high satisfaction.
          </p>
          <p>
            Upon receiving your clothes, we will check for any minor defects
            such as loose buttons, broken stitching and so on. We have quality
            built into our internal process for quality control check before
            returning your items to you. We do all the cleaning work in-house to
            guarantee your clothes will be cleaned in a clean environment from
            the time we receive your clothes from our riders until the time our
            riders deliver it nicely to you at your doorsteps.
          </p>
        </div>
      </div>

      <div className="wrap-contactus" id="contactus">
        <div className="contactus-heading">
          <div className="contactus-title">
            <h2>Contact Us</h2>
          </div>

          <div className="contactus-desc">
            {/* <p>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:</p>  */}
            <p>
              With more than 5 years of our combined experience in Wash & Fold
              Laundry Service, we are more and more excited and dedicated to
              helping you bring your clothes to look their best. If you have
              other items that are not stated above, no biggie! Contact us at{" "}
              <a href="mailto:cs@washla.my" className="contactus-desc-link">
                cs@washla.my
              </a>
              . We are more than happy to work with you on specific items to see
              if we can clean them, how long it may take, and what the estimated
              cost will be.
            </p>
          </div>
        </div>

        <div className="contactus_info">
          <ul className="contactus-items">
            {/* <li className='contactus-item'>
                                <span aria-hidden="true" data-icon="&#xe90b;" className='contact-icon-1'></span>
                                <div>
                                <p className='contactus-item-p-1'>Call Us</p>
                                <a href='tel:+60146222371'>+6014-6222371</a>
                                </div>
                            </li> */}
            <li className="contactus-item">
              <span aria-hidden="true" className="contact-icon-2">
                <i class="fab fa-whatsapp"></i>
              </span>
              <div>
                <p className="contactus-item-p-1">Customer Service Whatsapp</p>
                <a href="tel:+60135377989">+6013-5377989</a>
              </div>
            </li>
            <li className="contactus-item">
              <span
                aria-hidden="true"
                data-icon="&#xe90a;"
                className="contact-icon-1"
              ></span>
              <div>
                <p className="contactus-item-p-1">Email Us</p>
                <a href="mailto:cs@washla.my">cs@washla.my</a>
              </div>
            </li>
            <li className="contactus-item">
              <span
                aria-hidden="true"
                data-icon="&#xe909;"
                className="contact-icon-2"
              ></span>
              <div>
                <p className="contactus-item-p-1">PJ Branch</p>
                <p className="contactus-item-p-2">
                  60 Jalan SS2/72, SS2, 47300 Petaling Jaya, Selangor{" "}
                </p>
                <p className="contactus-item-p-3">
                  <a href="tel:+60146222371">+6014-6222371</a>
                </p>
              </div>
            </li>
            <li className="contactus-item lg:w-50">
              <span
                aria-hidden="true"
                data-icon="&#xe909;"
                className="contact-icon-2"
              ></span>
              <div>
                <p className="contactus-item-p-1">Seremban Branch</p>
                <p className="contactus-item-p-2">
                  Dataran Centrio, 83, Jalan S2 B19, Seremban 2, 70300 Seremban,
                  Negeri Sembilan
                </p>
                <p className="contactus-item-p-3">
                  <a href="tel:+601110610083">+6011-10610083</a>
                </p>
              </div>
            </li>
            {/* <li className='contactus-item lg:w-50'>
                                <span aria-hidden="true" data-icon="&#xe909;" className='contact-icon-2'></span>
                                <div>
                                <p className='contactus-item-p-1'>Cheras (Trader Square Cheras)</p>
                                <p className='contactus-item-p-2'>76-1-A, Jln Dataran Cheras 4, Dataran Perniagaan cheras, 43200 Cheras, Selangor</p>
                                <p className='contactus-item-p-3'><a href='tel:+60122333757'>+6012-2333757</a></p>
                                </div>
                            </li> */}
            <div className="contactus-download">
              <p className="contactus-item-p-3">Download Our App Now!</p>
              <div className="cont_downloadapp-img">
                <a
                  href="https://apps.apple.com/my/app/wash-la/id1582400036"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="../assets/images/Landing/Landing-29.jpg"
                    className="c-d-app-img"
                    alt="WashLa AppStore Download"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.washla.my"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="../assets/images/Landing/Landing-28.jpg"
                    className="c-d-app-img"
                    alt="WashLa Google Download"
                  />
                </a>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SPWashFoldPage;
