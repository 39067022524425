import React from "react";
import "../App.css";
import "./DataDeletionBody.css";

function DataDeletionBody() {
  return (
    <div className="wrap-deletion">
      <div className="deletion-heading-container">
        <div className="deletion-heading">
          <h1>Data Deletion Instructions</h1>
        </div>
      </div>

      <div className="deletion-desc-container">
        <p>
          Before requesting deletion of your user account data, please ensure
          that you understand that:
        </p>
        <ol>
          <li>
            We will only retain your user data for 30 days and then it will be
            permanently deleted. You can contact customer service to reactivate
            your account at any point within 30 days after submitting an account
            deletion request.
          </li>
          <li>
            If you have remaining balance in your wallet, please contact
            customer service for settlement.
          </li>
          <li>
            Account deletion is final. There will be no way to restore your
            account.
          </li>
        </ol>
        <p>
          If you are sure that you wish to delete your user account data, you
          have to request us to delete your account. Subject to your Wash La!
          user account is no longer required in the future, please perform
          account deletion in the Wash La! app by navigating to “Profile” &gt;
          "Settings", scroll to the bottom to find “Delete Account” and complete
          the required steps.
        </p>
      </div>
    </div>
  );
}

export default DataDeletionBody;
