import React from "react";
import "../App.css";
import "./SP All CSS.css";
import "./ContactUs.css";

function SPDryCleanPage() {
  // const drycleaning1 = require('../images/DryCleaning/Dry-Cleaning-Step-1.png');
  // const drycleaning2 = require('../images/DryCleaning/Dry-Cleaning-Step-2.png');
  // const drycleaning3 = require('../images/DryCleaning/Dry-Cleaning-Step-3.png');
  // const drycleaning4 = require('../images/DryCleaning/Dry-Cleaning-Step-4.png');

  // const drycleaning5 = require('../images/DryCleaning/Premium-Wash-or-dry-clean.png');
  // const drycleaning6 = require('../images/DryCleaning/Household.png');
  // const drycleaning7 = require('../images/DryCleaning/wash-pant.png');
  // const drycleaning8 = require('../images/DryCleaning/shirt-cleaning.png');
  // const drycleaning9 = require('../images/DryCleaning/Accessories-cleaning.png');

  // const appstore = require('../images/Landing/Landing-29.jpg');
  // const googleplay = require('../images/Landing/Landing-28.jpg');

  const premiumWash = [
    { name: "Baju Melayu", price: "36" },
    { name: "Samping/Songket", price: "24" },
    { name: "Baju Kurung", price: "36" },
    { name: "Punjabi Top", price: "25" },
    { name: "Punjabi Set", price: "50" },
    { name: "Saree", price: "50" },
    { name: "Saree Blouse", price: "20" },
    { name: "Veshti Set", price: "32" },
    { name: "T Shirt", price: "20" },
    { name: "Shirt", price: "20" },
    { name: "Blouse", price: "22" },
    { name: "Trousers/Pant", price: "20" },
    { name: "Skirt", price: "22" },
    { name: "Sweater", price: "24" },
    { name: "Vest", price: "24" },
    { name: "Dress", price: "44" },
    { name: "Blazer", price: "32" },
    { name: "Coat", price: "50" },
    { name: "Suit", price: "52" },
    { name: "Overcoat", price: "76" },
    { name: "Jacket", price: "49" },
    { name: "Winter Jacket", price: "50" },
  ];

  return (
    <>
      <div className="wrap-drycleanhero">
        <div className="servprichero-container">
          <div className="servpric-hero-heading">
            <h1>Dry Cleaning Services</h1>
          </div>

          <div className="servpric-hero-desc">
            {/* <p className='servpric-hero-desc-1'>We are Expert in Washing</p> */}
            <p className="servpric-hero-desc-2">
              At Wash La!, we have a team of skilled and experienced dry
              cleaning experts to clean your most beloved garments so that they
              are well taken care of and is returned to your arms looking
              sparkling clean again.
            </p>
          </div>
        </div>
      </div>

      <div className="wrap-serv-tabs">
        <div class="services-tabs">
          <ol class="tab-list">
            <a
              href="/services-and-pricing/"
              class="tab-list-item"
              target="_top"
            >
              Our Services
            </a>
            <a
              href="/services-and-pricing/sneaker-care"
              class="tab-list-item"
              target="_top"
            >
              Sneaker Care
            </a>
            <a
              href="/services-and-pricing/wash-and-fold"
              class="tab-list-item"
              target="_top"
            >
              Wash & Fold
            </a>
            <a
              href="/services-and-pricing/dry-cleaning"
              class="tab-list-item tab-list-active"
              target="_top"
            >
              Dry Cleaning
            </a>
            <a
              href="/services-and-pricing/bag-cleaning"
              class="tab-list-item"
              target="_top"
            >
              Bag Cleaning
            </a>
            <a
              href="/services-and-pricing/other-services"
              class="tab-list-item"
              target="_top"
            >
              Other Services
            </a>
          </ol>
        </div>
      </div>

      <div className="wrap-drycleaning" id="drycleaning">
        <div className="serv-drycleaning-banner">
          <div className="serv-body-heading">
            <h2>Quality Dry Cleaning and Laundry Service In KL & Selangor</h2>
            <p>
              Wash La!’s dry cleaning services uses a signature special solvent
              to remove dirt and stains effectively yet gently to our human
              bodies. We only use high-standard and advanced, computer-operated
              machine to dry clean your clothes, shirts and other special
              garments. This is the perfect service for items you want
              professionally cleaned and returned pressed and on a hanger.
            </p>
          </div>
        </div>

        <div className="washfold-container">
          <div className="washfold-content-1">
            <div className="washfold-content-1-h">
              <img
                src="../assets/images/DryCleaning/Dry-Cleaning-Step-1.png"
                className="washfold-img"
                alt="Dry Cleaning Step 1"
              />
              <h3>Photo Inventory</h3>
            </div>
            <div className="Oval-Copy-10"></div>
            <div>
              <p>
                After picking up your clothes, we’ll email you an itemized list
                with a photo of each item in our care.
              </p>
            </div>
          </div>
          <div className="washfold-content-1">
            <div className="washfold-content-1-h">
              <img
                src="../assets/images/DryCleaning/Dry-Cleaning-Step-2.png"
                className="washfold-img"
                alt="Dry Cleaning Step 2"
              />
              <h3>Stain Inspection</h3>
            </div>
            <div className="Oval-Copy-10"></div>
            <div>
              <p>
                Our “spotters” have decades of experience in identifying and
                treating stains with their acquired knowledge of organic
                chemistry so your garments are returned pristine.
              </p>
            </div>
          </div>
          <div className="washfold-content-1">
            <div className="washfold-content-1-h">
              <img
                src="../assets/images/DryCleaning/Dry-Cleaning-Step-3.png"
                className="washfold-img"
                alt="Dry Cleaning Step 3"
              />
              <h3>Expert Care</h3>
            </div>
            <div className="Oval-Copy-10"></div>
            <div>
              <p>
                We follow the care label (and know what all the symbols mean!)
                so your clothes receive the optimal cleaning treatment and last
                of years to come.
              </p>
            </div>
          </div>
          <div className="washfold-content-1">
            <div className="washfold-content-1-h">
              <img
                src="../assets/images/DryCleaning/Dry-Cleaning-Step-4.png"
                className="washfold-img"
                alt="Dry Cleaning Step 4"
              />
              <h3>Pressed and Hung</h3>
            </div>
            <div className="Oval-Copy-10"></div>
            <div>
              <p>
                Your clothes ans crisply pressed, put on hangers, and placed in
                your protective garment bags, ready to wear upon delivery.
              </p>
            </div>
          </div>
        </div>

        <div className="serv-content-container">
          <h2>Dry Cleaning Pricing</h2>
          <p>
            Enjoy premium cleaning from the comfort of your home and never have
            to go to the dry cleaners again!
          </p>

          <div className="serv-info-container">
            <div className="serv-cards-item">
              <div className="serv-info-cards">
                <div className="serv-info-heading-2">
                  <img
                    src="../assets/images/DryCleaning/Premium-Wash-or-dry-clean.png"
                    className="serv-info-logo"
                    alt="Premium Wash or dry clean"
                  />
                  <div className="serv-info-heading-subheading">
                    <h3>Premium Wash/Dry Clean</h3>
                    <p class="serv-info-subheading">
                      Price is for walk-in only (app coming soon)
                    </p>
                  </div>
                </div>
                {premiumWash.map((item, index) => (
                  <div className="serv-info-pricing-2">
                    <div className="serv-info-desc">
                      <p>{item.name}</p>
                    </div>
                    <div className="serv-info-price">
                      <p className="serv-price-1">MYR</p>
                      <p className="serv-price-2">{item.price}</p>
                    </div>
                  </div>
                ))}
              </div>

              {/* <div className='serv-info-cards'>
                                <div className='serv-info-heading'>
                                    <img src="../assets/images/DryCleaning/Household.png" className='serv-info-logo' alt='Household'/>
                                    <h3>Household</h3>
                                </div>
                                <div className='serv-info-pricing'>
                                    <div className='serv-info-desc'>
                                        <p>Bath Mat</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>25</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Sheets/Blanket</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>25</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Duvet Cover</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>25</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Comforter/Duvet</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>25</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Tablecloth</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>25</p>
                                    </div>
                                </div>
                            </div> */}
            </div>

            {/* <div className="serv-cards-item">
              <div className="serv-info-cards">
                <div className="serv-info-heading">
                  <img
                    src="../assets/images/DryCleaning/wash-pant.png"
                    className="serv-info-logo"
                    alt="Wash pant"
                  />
                  <div className="serv-info-heading-subheading">
                    <h3>Bottom</h3>
                    <p class="serv-info-subheading">
                      Price is for walk-in only (app coming soon)
                    </p>
                  </div>
                </div>
                <div className="serv-info-pricing">
                  <div className="serv-info-desc">
                    <p>Pants</p>
                  </div>
                  <div className="serv-info-price">
                    <p className="serv-price-1">MYR</p>
                    <p className="serv-price-2">30</p>
                  </div>
                </div>
                <div className="serv-info-pricing-2">
                  <div className="serv-info-desc">
                    <p>Skirts</p>
                  </div>
                  <div className="serv-info-price">
                    <p className="serv-price-1">MYR</p>
                    <p className="serv-price-2">50</p>
                  </div>
                </div>
                <div className="serv-info-pricing-2">
                  <div className="serv-info-desc">
                    <p>Shorts</p>
                  </div>
                  <div className="serv-info-price">
                    <p className="serv-price-1">MYR</p>
                    <p className="serv-price-2">50</p>
                  </div>
                </div>
              </div>

              <div className="serv-info-cards">
                <div className="serv-info-heading">
                  <img
                    src="../assets/images/DryCleaning/shirt-cleaning.png"
                    className="serv-info-logo"
                    alt="Shirt cleaning"
                  />
                  <div className="serv-info-heading-subheading">
                    <h3>Full Body</h3>
                    <p class="serv-info-subheading">
                      Price is for walk-in only (app coming soon)
                    </p>
                  </div>
                </div>
                <div className="serv-info-pricing">
                  <div className="serv-info-desc">
                    <p>Casual Dress</p>
                  </div>
                  <div className="serv-info-price">
                    <p className="serv-price-1">MYR</p>
                    <p className="serv-price-2">30</p>
                  </div>
                </div>
                <div className="serv-info-pricing-2">
                  <div className="serv-info-desc">
                    <p>Formal Dress</p>
                  </div>
                  <div className="serv-info-price">
                    <p className="serv-price-1">MYR</p>
                    <p className="serv-price-2">50</p>
                  </div>
                </div>
                <div className="serv-info-pricing-2">
                  <div className="serv-info-desc">
                    <p>Coat</p>
                  </div>
                  <div className="serv-info-price">
                    <p className="serv-price-1">MYR</p>
                    <p className="serv-price-2">50</p>
                  </div>
                </div>
                <div className="serv-info-pricing-2">
                  <div className="serv-info-desc">
                    <p>Suit (Jacket & Pants)</p>
                  </div>
                  <div className="serv-info-price">
                    <p className="serv-price-1">MYR</p>
                    <p className="serv-price-2">50</p>
                  </div>
                </div>
              </div>

              <div className="serv-info-cards">
                <div className="serv-info-heading">
                  <img
                    src="../assets/images/DryCleaning/Accessories-cleaning.png"
                    className="serv-info-logo"
                    alt="Accessories cleaning"
                  />
                  <div className="serv-info-heading-subheading">
                    <h3>Accessories</h3>
                    <p class="serv-info-subheading">
                      Price is for walk-in only (app coming soon)
                    </p>
                  </div>
                </div>
                <div className="serv-info-pricing">
                  <div className="serv-info-desc">
                    <p>Napkin</p>
                  </div>
                  <div className="serv-info-price">
                    <p className="serv-price-1">MYR</p>
                    <p className="serv-price-2">30</p>
                  </div>
                </div>
                <div className="serv-info-pricing-2">
                  <div className="serv-info-desc">
                    <p>Pillowcase</p>
                  </div>
                  <div className="serv-info-price">
                    <p className="serv-price-1">MYR</p>
                    <p className="serv-price-2">50</p>
                  </div>
                </div>
                <div className="serv-info-pricing-2">
                  <div className="serv-info-desc">
                    <p>Tie/Scarf</p>
                  </div>
                  <div className="serv-info-price">
                    <p className="serv-price-1">MYR</p>
                    <p className="serv-price-2">50</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="serv-content-container">
          <h2>More than 10 years of experiences</h2>

          <p>
            The Wash La! team takes pride in being the forefront of providing
            our customers with professional and efficient service, being the
            best laundry services in town! Our cleaning uses our special laundry
            detergent and water to clean machine washable clothes, which is then
            nicely folded and pressed before returning them into your arms. At
            Wash La!, our main goal has always been to fulfill customers’
            cleaning needs and give absolute high satisfaction.
          </p>
          <p>
            Upon receiving your clothes, we will check for any minor defects
            such as loose buttons, broken stitching and so on. We have quality
            built into our internal process for quality control check before
            returning your items to you. We do all the cleaning work in-house to
            guarantee your clothes will be cleaned in a clean environment from
            the time we receive your clothes from our riders until the time our
            riders deliver it nicely to you at your doorsteps.
          </p>
        </div>
      </div>

      <div className="wrap-contactus" id="contactus">
        <div className="contactus-heading">
          <div className="contactus-title">
            <h2>Contact Us</h2>
          </div>

          <div className="contactus-desc">
            {/* <p>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:</p>  */}
            <p>
              With more than 5 years of our combined experience in Dry Cleaning,
              we are more and more excited and dedicated to helping you bring
              your clothes, shirts and accessories to look their best. If you
              have other items that are not stated above, no biggie! Contact us
              at{" "}
              <a href="mailto:cs@washla.my" className="contactus-desc-link">
                cs@washla.my
              </a>
              . We are more than happy to work with you on specific items to see
              if we can clean them, how long it may take, and what the estimated
              cost will be.
            </p>
          </div>
        </div>

        <div className="contactus_info">
          <ul className="contactus-items">
            {/* <li className='contactus-item'>
                                <span aria-hidden="true" data-icon="&#xe90b;" className='contact-icon-1'></span>
                                <div>
                                <p className='contactus-item-p-1'>Call Us</p>
                                <a href='tel:+60146222371'>+6014-6222371</a>
                                </div>
                            </li> */}
            <li className="contactus-item">
              <span aria-hidden="true" className="contact-icon-2">
                <i class="fab fa-whatsapp"></i>
              </span>
              <div>
                <p className="contactus-item-p-1">Customer Service Whatsapp</p>
                <a href="tel:+60135377989">+6013-5377989</a>
              </div>
            </li>
            <li className="contactus-item">
              <span
                aria-hidden="true"
                data-icon="&#xe90a;"
                className="contact-icon-1"
              ></span>
              <div>
                <p className="contactus-item-p-1">Email Us</p>
                <a href="mailto:cs@washla.my">cs@washla.my</a>
              </div>
            </li>
            <li className="contactus-item">
              <span
                aria-hidden="true"
                data-icon="&#xe909;"
                className="contact-icon-2"
              ></span>
              <div>
                <p className="contactus-item-p-1">PJ Branch</p>
                <p className="contactus-item-p-2">
                  60 Jalan SS2/72, SS2, 47300 Petaling Jaya, Selangor{" "}
                </p>
                <p className="contactus-item-p-3">
                  <a href="tel:+60146222371">+6014-6222371</a>
                </p>
              </div>
            </li>
            <li className="contactus-item lg:w-50">
              <span
                aria-hidden="true"
                data-icon="&#xe909;"
                className="contact-icon-2"
              ></span>
              <div>
                <p className="contactus-item-p-1">Seremban Branch</p>
                <p className="contactus-item-p-2">
                  Dataran Centrio, 83, Jalan S2 B19, Seremban 2, 70300 Seremban,
                  Negeri Sembilan
                </p>
                <p className="contactus-item-p-3">
                  <a href="tel:+601110610083">+601110610083</a>
                </p>
              </div>
            </li>
            <li className="contactus-item lg:w-50">
              <span
                aria-hidden="true"
                data-icon="&#xe909;"
                className="contact-icon-2"
              ></span>
              {/* <div>
                <p className="contactus-item-p-1">
                  Cheras (Trader Square Cheras)
                </p>
                <p className="contactus-item-p-2">
                  76-1-A, Jln Dataran Cheras 4, Dataran Perniagaan cheras, 43200
                  Cheras, Selangor
                </p>
                <p className="contactus-item-p-3">
                  <a href="tel:+60122333757">+6012-2333757</a>
                </p>
              </div> */}
            </li>
            <div className="contactus-download">
              <p className="contactus-item-p-3">Download Our App Now!</p>
              <div className="cont_downloadapp-img">
                <a
                  href="https://apps.apple.com/my/app/wash-la/id1582400036"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="../assets/images/Landing/Landing-29.jpg"
                    className="c-d-app-img"
                    alt="WashLa AppStore Download"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.washla.my"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="../assets/images/Landing/Landing-28.jpg"
                    className="c-d-app-img"
                    alt="WashLa Google Download"
                  />
                </a>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SPDryCleanPage;
