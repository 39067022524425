import React, { useEffect } from 'react';
import '../../App.css';
import ContactUs from '../ContactUs';
import RefundBody from '../RefundBody';
import DocumentMeta from "react-document-meta";

function RefundPolicy() {

    useEffect(() => {
        document.title = "Sales & Refund | Wash La!";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/refund-policy"
            }
        },

        meta: {
            name: {
                title: "Sales & Refund  | Wash La!",                
                description: "If the client wishes to proceed with the refund process from Wash La! all communications can be done via our apps or contacting our customer service.",
                canonical:"https://washla.my/refund-policy",
                keywords: "clean, cleaning, cleaning service, bag, delivery, privacy, policy, refund policy, data, personal data, sales and refund",
            },
            property: {
                "og:title": "Sales & Refund  | Wash La!",
                "og:description": "If the client wishes to proceed with the refund process from Wash La! all communications can be done via our apps or contacting our customer service.",
            }
        }
    };


    return (
        <>
            <DocumentMeta {...meta} /> 

            <RefundBody />
            <ContactUs />    
        </>
    );
}

export default RefundPolicy;