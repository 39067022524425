import React, { useState } from 'react';
import '../App.css';
import './LocationHero.css';


function LocationHero() {

  return (
    <div className='wrap-locationhero'>
      <div className='locationhero-container'>
        <div className='loca-hero-heading'>
            <h1>Locations</h1>
        </div>

        <div className='loca-hero-desc'>
            <p className='loca-hero-desc-1'>We are currently expanding our services in Klang Valley</p>
            <p className='loca-hero-desc-2'>PJ folks! Looking for the nearest Laundry to you in PJ? Wash La! is your best bet for Dry Cleaning & Laundry service in Petaling Jaya and Kuala Lumpur.</p>
        </div>
      </div>
        
    </div>
  );
}

export default LocationHero;