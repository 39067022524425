import React  from 'react';
import '../App.css';
import './ScheduleAPickup.css';

import { Accordion } from 'react-bootstrap';

function HowToSchedule(props) {

    return (
        <>
            <div className="howto-sched-item">
                <div className="howto-sched-item-1">
                    <img src={props.howto_img} alt={props.howto_imgalttext} />
                </div>

                <div className="howto-sched-item-2">
                    <h3>{props.howto_title}</h3>
                    <p>{props.howto_desc}</p>
                </div>
            </div>
            
        </>
    );
}


function SchedulePage() {

    // const schedbody_1_01 = require("../images/schedule-pickup/shoe-cleaning-apps.png");
    // const schedbody_1_02 = require("../images/schedule-pickup/laundry-delivery-step-1.png");
    // const schedbody_1_03 = require("../images/schedule-pickup/laundry-delivery-step-2.png");
    // const schedbody_1_04 = require("../images/schedule-pickup/laundry-delivery-step-3.png");
    
    // const schedbody_2_01 = require("../images/schedule-pickup/Laundry-Services-In-Malaysia.png");
    // const schedbody_2_02 = require('../images/Landing/Landing-29.jpg');
    // const schedbody_2_03 = require('../images/Landing/Landing-28.jpg');

    return (

        <div className='wrap-schedule'>

            <div className="wrap-schedhero">
                <div className='schedhero-container'>
                    <div className='sched-hero-heading'>
                        <h1>Schedule A Pickup</h1>
                    </div>

                    <div className='sched-hero-desc'>
                        <p>Give yourself a break. We ride to your doorsteps so you don’t have to go through the trouble and hassle of carrying heavy loads of laundry or dry cleaning items</p>
                    </div>
                </div>
            </div>

            <div className="sched-body-container">
                <div className="sched-body-content-1">
                    <div className="sched-b-cont-1-01">
                        <h2>Made Easy Laundry Pickup & Delivery Service in PJ and KL</h2>
                        <p>Rain or shine, Wash La! is the professional and ultimate expert cleaner nearest to your home or office to do your laundry for any kind of fabric. So just sit back, relax and book a pick
                            up from the comfort of your home. Link to download app on AppStore or Google Playstore and also a “Find if we serve your neighbourhood” postcode entry which will list nearest
                            locations to a particular postcode.
                        </p>
                    </div>

                    <div className="sched-b-cont-1-02">
                        <HowToSchedule
                            howto_img = "assets/images/schedule-pickup/shoe-cleaning-apps.png"
                            howto_imgalttext = "Shoe Cleaning Apps"
                            howto_title = "Book via our mobile app"
                            howto_desc = "Choose the category and services. Then select the date, time and location. Here’s the coverage area where our riders can look for you: Kuala Lumpur, Klang, Kajang, Subang Jaya, Petaling Jaya, Selayang, Shah Alam, Ampang Jaya, Putrajaya, Sepang."
                        />
                        <HowToSchedule
                            howto_img = "assets/images/schedule-pickup/laundry-delivery-step-1.png"
                            howto_imgalttext = "Laundry Delivery Step 1"
                            howto_title = "Pickup"
                            howto_desc = "Between 3pm to 5pm on the evening of your scheduled pickup, you'll receive a text message with a 30-minute estimated time of arrival for your Valet. Your Valet will bring your personalized Wash La! Bags so you have everything you need to use our services. All you have to do is have your shoes, clothes, bags or caps ready in a bag by the door. All pickups and deliveries are no-contact. Your Valet will walk you through your first socially-distanced pickup and answer any questions you may have."
                        />
                        <HowToSchedule
                            howto_img = "assets/images/schedule-pickup/laundry-delivery-step-2.png"
                            howto_imgalttext = "Laundry Delivery Step 2"
                            howto_title = "Cleaning"
                            howto_desc = "Once we pick up your items, we'll take them 10 the Wash La! Headquarters. You'll receive a detailed email including a list of items you've entrusted to our care. Your items will then be inspected for stains and items that shouldn't be washed and professionally cleaned according to the service you selected."
                        />
                        <HowToSchedule
                            howto_img = "assets/images/schedule-pickup/laundry-delivery-step-3.png"
                            howto_imgalttext = "Laundry Delivery Step 3"
                            howto_title = "Delivery"
                            howto_desc = "We'll send you a text message on the morning of your scheduled delivery to confirm your delivery between 10am to 2pm. Once confirmed, you'll receive another text message with an estimated time of arrival for your Valet. Your Valet will deliver your clean items, ready to wear! Our standard turnaround for orders is 7 - 10 days."
                        />
                    </div>

                    <div className="sched-b-cont-1-03">
                        <a href="/schedule-a-pickup/#downloadapp" className="schred-downloadapp" role="button">
                            Download App
                        </a>
                    </div>
                </div>

                <div className="sched-body-content-2">
                    <div className="sched-b-cont-2-01">
                        <div className="sched-b-cont-2-item-1">
                            <img src="assets/images/schedule-pickup/Laundry-Services-In-Malaysia.png" alt="Laundry-Services-In-Malaysia" />
                        </div>

                        <div className="sched-b-cont-2-item-2">
                            <h2>Laundry Services Tailored For You</h2>
                            <p>Wash La! cares for all garments, shoes, caps, bags and backpacks the way it needs to with our signature cleaning liquid and services and according to their individual types of fabric.</p>
                            <p>We have two special ingredients for Wash La! signature cleaning liquid:
                                <br/>1. Mild cleaning liquid - for materials that are easier to dye
                                <br/>2. Neutral cleaning liquid - for stubborn stain
                            </p>
                            <p>Both are designed to effectively yet gently disinfect and remove bacteria, germs and moulds, so rest assured it is safe for your items and our hands.</p>
                            <p>We Provide door-to-door laundry pick up and delivery service near you has become a whole lot easier and more convenient with Wash La! latest mobile app (available on iOS and Android). Get instant real-time tracking of your shoe status from our mobile app. We ensure you will always be in the know from the moment you book a pickup.</p>
                            
                            <p className="sched-b-cont-2-item-2-p2">We got you covered with Wash La! Download Our Apps Now</p>
                            <div className="sched-b-cont-2-download">
                                <a href='https://apps.apple.com/my/app/wash-la/id1582400036' target='_blank' rel='noreferrer noopener'><img src="assets/images/Landing/Landing-29.jpg" alt="WashLa! App Download AppStore"/></a>
                                <a href='https://play.google.com/store/apps/details?id=com.washla.my' target='_blank' rel='noreferrer noopener'><img src="assets/images/Landing/Landing-28.jpg" alt="WashLa! App Download Google Playstore"/></a>
                            </div>

                        </div>
                    </div>
                </div>

                
                <div className="sched-body-content-3">
                    <div className="sched-b-cont-3-01">
                        <h2>FAQs</h2>
                    </div>

                    
                    <div className="sched-b-cont-3-02">
                        <div className="sched-b-cont-3-item-1">
                            <Accordion id="sched_accordion" flush>
                                <Accordion.Item className="sched-accordion" eventKey="0">
                                    <Accordion.Header>
                                        <div className='sched-accd-heading'>
                                            <h3>1. What is the delivery fee like</h3>
                                        </div>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <p>Our fixed price for delivery is RM15. You can qualify for a FREE pick up and delivery with two or more items.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion id="sched_accordion" flush>
                                <Accordion.Item className="sched-accordion" eventKey="1">
                                    <Accordion.Header>
                                        <div className='sched-accd-heading'>
                                            <h3>2. What happens after I book a pickup?</h3>
                                        </div>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <p>Our riders will contact you before going to your selected location. Say our riders have waited more than 15mins, they will pass the items to the security guard and snap a photo to send to you.</p>
                                        <p>If for some reason, you are still unreachable. Our riders will try contacting you another time to re-arrange your pickup for another timeslot.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </div>

                        <div className="sched-b-cont-3-item-1">
                            <Accordion id="sched_accordion" flush>
                                <Accordion.Item className="sched-accordion" eventKey="2">
                                    <Accordion.Header>
                                        <div className='sched-accd-heading'>
                                            <h3>3. How long does the whole process take?</h3>
                                        </div>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        <p>It takes 3-5 working days (excluding the delivery pickup return period) to ensure we provide you with our highest-quality service and personalized care.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>

                </div>

            </div>
            
            
        </div>
    );
}

export default SchedulePage;