import React, { useEffect } from 'react';
import '../../App.css';
import '../NotFoundPage.css';
import DocumentMeta from "react-document-meta";

function NotFoundPage() {

    useEffect(() => {
        document.title = "404: Page not found | Wash La!";  
    }, []);

    const meta = {

        meta: {
            name: {
                title: "WashLa! - Shoe Wash Service | Sneaker Cleaning Kuala Lumpur",                
                description: "Wash La! is the Leading One-stop solution for Professional Shoe Wash & Sneaker cleaning service with door-to-door pickup and delivery within Klang Valley.",
                keywords: "clean, cleaning, repair, sneaker, cleaning service, shoe, repellant treatment, sneaker care, cleaning restoration, laundry, dry cleaning, bag cleaning, Kuala Lumpur, Petaling Jaya",
            },
            property: {
                "og:title": "WashLa! - Shoe Wash Service | Sneaker Cleaning Kuala Lumpur",
                "og:description": "Wash La! is the Leading One-stop solution for Professional Shoe Wash & Sneaker cleaning service with door-to-door pickup and delivery within Klang Valley.",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <div className="PageError">
                <h1>404</h1>
                <p>Page not found.</p>
            </div>
        </>
    );
}

export default NotFoundPage;