import React, { useEffect } from 'react';
import '../../App.css';
import ContactUs from '../ContactUs';
import BlogItem01 from '../BlogItem_01';
import DocumentMeta from "react-document-meta";

function BlogPage01() {

    useEffect(() => {
        document.title = "Gucci Purse Cleaning | Leather Bag Repair Petaling Jaya";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/blogs/6-steps-to-clean-your-gucci-purse-or-leather-bag"
            }
        },

        meta: {
            name: {
                title: "Gucci Purse Cleaning | Leather Bag Repair Petaling Jaya",                
                description: "Let’s talk about cleaning your Gucci purse and bag. So what can you do to clean your Gucci purse and bag regularly at home? ",
                canonical:"https://washla.my/blogs/6-steps-to-clean-your-gucci-purse-or-leather-bag",
                keywords: "clean, cleaning, cleaning service, bag ,restoration, delivery service, malaysia, care, gucci, LV, coach,  leather bag, treatment",
            },
            property: {
                "og:title": "Gucci Purse Cleaning | Leather Bag Repair Petaling Jaya",
                "og:description": "Let’s talk about cleaning your Gucci purse and bag. So what can you do to clean your Gucci purse and bag regularly at home?",
                "og:type": "article",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <BlogItem01 />
            <ContactUs />    
        </>
    );
}

export default BlogPage01;