import React, { useEffect } from 'react';
import '../../App.css';
import SchedulePage from '../ScheduleAPickup';
import DownloadApp from '../DownloadApp';
import ContactUs04 from '../ContactUs04';
import DocumentMeta from "react-document-meta";

function ScheduleAPickup() {

    useEffect(() => {
        document.title = "Laundry Pick Up Service KL | Laundry Delivery Service Malaysia";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/schedule-a-pickup"
            }
        },

        meta: {
            name: {
                title: "Laundry Pick Up Service KL | Laundry Delivery Service Malaysia",                
                description: "Looking for a door-to-door laundry pick up and delivery service near you? Download our apps and our riders will come to your doorstep to pick up and deliver to you!",
                canonical:"https://washla.my/schedule-a-pickup",
                keywords: "Pick Up Service, repair, sneaker, cleaning service, shoe, repellant treatment, sneaker care, Laundry, Kuala Lumpur, Petaling Jaya, Delivery",
            },
            property: {
                "og:title": "Laundry Pick Up Service KL | Laundry Delivery Service Malaysia",
                "og:description": "Looking for a door-to-door laundry pick up and delivery service near you? Download our apps and our riders will come to your doorstep to pick up and deliver to you!",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <SchedulePage />  
            <DownloadApp />
            <ContactUs04 />

        </>
    ); 
}

export default ScheduleAPickup;