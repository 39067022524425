import React from "react";
import "../App.css";
import "./ContactUs.css";
import "./ContactUsPageContent.css";

function ContactUsContent() {
  // const contusp_2_01 = require("../images/Home/Washla-favicon.png");
  // const contusp_2_02 = require("../images/Home/washla-laundry-mobile-apps.png");

  // const appstore = require('../images/Landing/Landing-29.jpg');
  // const googleplay = require('../images/Landing/Landing-28.jpg');

  return (
    <div className="wrap-contactus_p">
      <div className="wrap-contusphero">
        <div className="contusphero-container">
          <div className="contusp-hero-heading">
            <h1>Contact Us</h1>
          </div>

          <div className="contusp-hero-desc">
            <p>
              If you have other items that are not stated above, no biggie!
              Contact us now and we are more than happy to work with you on
              specific items to see if we can clean them, how long it may take,
              and what the estimated cost will be.
            </p>
          </div>
        </div>
      </div>

      <div className="contusp-body-container">
        <div className="contusp-body-content-1">
          <div className="contactus-heading">
            <div className="contactus-title">
              <h2>
                Trusted Laundry Service, Sneaker and Leather Bag Cleaning
                Service in Kuala Lumpur
              </h2>
            </div>

            <div className="contactus-desc">
              <p>
                Wash La! is Malaysia’s leading laundry and leather bag cleaning
                service with door-to-door pickup and delivery service in and
                around Petaling Jaya and Kuala Lumpur. In the hands of our
                skilled and experienced cleaning experts, your most treasured
                old but cherished ‘babies’ will be well taken care of with
                personalised care to look (nearly) brand new again! Contact us
                to find out more!
              </p>
            </div>
          </div>

          <div className="contactus_info">
            <ul className="contactus-items">
              {/* <li className='contactus-item'>
                                <span aria-hidden="true" data-icon="&#xe90b;" className='contact-icon-1'></span>
                                <div>
                                <p className='contactus-item-p-1'>Call Us</p>
                                <a href='tel:+60146222371'>+6014-6222371</a>
                                </div>
                            </li> */}
              <li className="contactus-item">
                <span aria-hidden="true" className="contact-icon-2">
                  <i class="fab fa-whatsapp"></i>
                </span>
                <div>
                  <p className="contactus-item-p-1">
                    Customer Service Whatsapp
                  </p>
                  <a href="tel:+60135377989">+6013-5377989</a>
                </div>
              </li>
              <li className="contactus-item">
                <span
                  aria-hidden="true"
                  data-icon="&#xe90a;"
                  className="contact-icon-1"
                ></span>
                <div>
                  <p className="contactus-item-p-1">Email Us</p>
                  <a href="mailto:cs@washla.my">cs@washla.my</a>
                </div>
              </li>
              <li className="contactus-item">
                <span
                  aria-hidden="true"
                  data-icon="&#xe909;"
                  className="contact-icon-2"
                ></span>
                <div>
                  <p className="contactus-item-p-1">PJ Branch</p>
                  <p className="contactus-item-p-2">
                    60 Jalan SS2/72, SS2, 47300 Petaling Jaya, Selangor{" "}
                  </p>
                  <p className="contactus-item-p-3">
                    <a href="tel:+60146222371">+6014-6222371</a>
                  </p>
                </div>
              </li>
              <li className="contactus-item lg:w-50">
                <span
                  aria-hidden="true"
                  data-icon="&#xe909;"
                  className="contact-icon-2"
                ></span>
                <div>
                  <p className="contactus-item-p-1">Seremban Branch</p>
                  <p className="contactus-item-p-2">
                    Dataran Centrio, 83, Jalan S2 B19, Seremban 2, 70300
                    Seremban, Negeri Sembilan
                  </p>
                  <p className="contactus-item-p-3">
                    <a href="tel:+601110610083">+6011-10610083</a>
                  </p>
                </div>
              </li>
              {/* <li className='contactus-item lg:w-50'>
                                <span aria-hidden="true" data-icon="&#xe909;" className='contact-icon-2'></span>
                                <div>
                                <p className='contactus-item-p-1'>Cheras (Trader Square Cheras)</p>
                                <p className='contactus-item-p-2'>76-1-A, Jln Dataran Cheras 4, Dataran Perniagaan cheras, 43200 Cheras, Selangor</p>
                                <p className='contactus-item-p-3'><a href='tel:+60122333757'>+6012-2333757</a></p>
                                </div>
                            </li> */}
            </ul>
          </div>
        </div>

        <div className="contusp-body-content-2">
          <div className="contusp-cont2-download">
            <div className="contusp-download-1">
              <div className="contusp-down-item">
                <div>
                  <div className="contusp-down-item-1">
                    <div className="contus-down-heading">
                      <div className="contus-down-heading-1">
                        <img
                          src="../assets/images/Home/Washla-favicon.png"
                          alt="Washla favicon"
                        />
                      </div>
                      <div className="contus-down-heading-2">
                        <h2>Interested with us?</h2>
                        <p>Download our app and experience now!</p>
                      </div>
                    </div>

                    <div className="contus-down-desc">
                      <p>
                        Washla App now is available on Washla iOS and Android
                        download, let’s get started!
                      </p>
                      <div className="contus-down-body-img">
                        <a
                          href="https://apps.apple.com/my/app/wash-la/id1582400036"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <img
                            src="../assets/images/Landing/Landing-29.jpg"
                            alt="WashLa AppStore Download"
                          />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.washla.my"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <img
                            src="../assets/images/Landing/Landing-28.jpg"
                            alt="WashLa Google Download"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="contusp-down-item-2">
                  <img
                    src="../assets/images/Home/washla-laundry-mobile-apps.png"
                    alt="WashLa laundry mobile apps"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsContent;
