import React, { useEffect } from 'react';
import '../../App.css';
import LocationHero from '../LocationHero';
import LocationBody from '../LocationBody';
import DownloadApp from '../DownloadApp';
import ContactUs04 from '../ContactUs04';
import DocumentMeta from "react-document-meta";

function Locations() {

    useEffect(() => {
        document.title = "Dry Cleaning PJ |  Nearest Laundry To Me In PJ";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/locations"
            }
        },

        meta: {
            name: {
                title: "Dry Cleaning PJ |  Nearest Laundry To Me In PJ",                
                description: "We are a one-stop dry cleaning and clothing care brand offering a full-fledged laundry service in Petaling Jaya and Kuala Lumpur.",
                canonical:"https://washla.my/locations",
                keywords: "Dry Cleaning, repair, sneaker, cleaning service, shoe, repellent treatment, sneaker care, Laundry, Kuala Lumpur, Petaling Jaya, near me ",
            },
            property: {
                "og:title": "Dry Cleaning PJ |  Nearest Laundry To Me In PJ",
                "og:description": "We are a one-stop dry cleaning and clothing care brand offering a full-fledged laundry service in Petaling Jaya and Kuala Lumpur.",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <LocationHero />
            <LocationBody />
            <DownloadApp />
            <ContactUs04 />
        </>
    );
}

export default Locations;