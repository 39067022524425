import React, { useEffect } from 'react';
import '../../App.css';
import FAQ from '../FAQ';
import ContactUs from '../ContactUs';
import DocumentMeta from "react-document-meta";

// import FaqTesting from '../FAQTesting';

function FAQpage() {

    useEffect(() => {
        document.title = "FAQs | Wash La!";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/faq"
            }
        },

        meta: {
            name: {
                title: "FAQs | Wash La!",                
                description: "Not sure how our services work? Find out and search for the answers to your question in our Frequently Asked Questions (FAQ) page, from Our Services to Policies.",
                canonical:"https://washla.my/faq",
                keywords: "clean, cleaning, cleaning service, bag, restoration, delivery service, malaysia, care, leather bag, treatment, blog, article, news",
            },
            property: {
                "og:title": "FAQs | Wash La!",
                "og:description": "Not sure how our services work? Find out and search for the answers to your question in our Frequently Asked Questions (FAQ) page, from Our Services to Policies.",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <FAQ />    
            <ContactUs /> 
        </>
    );
}

export default FAQpage;