import React, { useEffect }  from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import LandingCard from '../LandingCard';
import DownloadApp from '../DownloadApp';
import ContactUs04 from '../ContactUs04';
// import DocumentMeta from "react-document-meta";
import {Helmet} from "react-helmet";

function Home() {

    useEffect(() => {
        document.title = "WashLa! - Shoe Wash Service | Sneaker Cleaning Kuala Lumpur";  
    }, []);

    // const meta = {

    //     link: {
    //         rel: {
    //             canonical:"https://washla.my/"
    //         }
    //     },
        
    //     meta: {
    //         name: {
    //             title: "WashLa! - Shoe Wash Service | Sneaker Cleaning Kuala Lumpur",                
    //             description: "Wash La! is the Leading One-stop solution for Professional Shoe Wash & Sneaker cleaning service with door-to-door pickup and delivery within Klang Valley.",
    //             canonical:"https://washla.my/",
    //             keywords: "clean, cleaning, repair, sneaker, cleaning service, shoe, repellant treatment, sneaker care, cleaning restoration, laundry, dry cleaning, bag cleaning, Kuala Lumpur, Petaling Jaya",
    //         },
    //         property: {
    //             "og:title": "WashLa! - Shoe Wash Service | Sneaker Cleaning Kuala Lumpur",
    //             "og:description": "Wash La! is the Leading One-stop solution for Professional Shoe Wash & Sneaker cleaning service with door-to-door pickup and delivery within Klang Valley.",
    //         }
    //     }
    // };


    return (
        <>
            {/* <DocumentMeta {...meta} />  */}

            <Helmet>
                <meta name="title" content="WashLa! - Shoe Wash Service | Sneaker Cleaning Kuala Lumpur" />
                <meta name="description" content="Wash La! is the Leading One-stop solution for Professional Shoe Wash & Sneaker cleaning service with door-to-door pickup and delivery within Klang Valley." />
                <meta name="canonical" content="https://washla.my/" />
                <meta name="keywords" content="clean, cleaning, repair, sneaker, cleaning service, shoe, repellant treatment, sneaker care, cleaning restoration, laundry, dry cleaning, bag cleaning, Kuala Lumpur, Petaling Jaya" />
                
                <meta property="og:title" content="WashLa! - Shoe Wash Service | Sneaker Cleaning Kuala Lumpur" />
                <meta property="og:description" content="Wash La! is the Leading One-stop solution for Professional Shoe Wash & Sneaker cleaning service with door-to-door pickup and delivery within Klang Valley." />
                <meta property="og:url" content="https://washla.my/" />
            </Helmet>

            <HeroSection />  
            <LandingCard /> 
            <DownloadApp /> 
            <ContactUs04 />    

        </>
    ); 
}

export default Home;