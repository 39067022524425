import React, { useEffect } from 'react';
import '../../App.css';
import SPWashFoldPage from '../SP WashFold Page';
import DocumentMeta from "react-document-meta";

function SPWashAndFold() {

    useEffect(() => {
        document.title = "Cleaning Services Kuala Lumpur, Petaling Jaya";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/services-and-pricing/wash-and-fold"
            }
        },

        meta: {
            name: {
                title: "Cleaning Services Kuala Lumpur, Petaling Jaya",                
                description: "The Wash La! team takes pride in being the forefront of providing our customers with professional service, being the best laundry cleaning services in KL, PJ!",
                canonical:"https://washla.my/services-and-pricing/wash-and-fold",
                keywords: "clean, cleaning, cleaning service, shirt, wash, fold, laundry, Petaling Jaya, Kuala Lumpur,  delivery service",
            },
            property: {
                "og:title": "Cleaning Services Kuala Lumpur, Petaling Jaya",
                "og:description": "The Wash La! team takes pride in being the forefront of providing our customers with professional service, being the best laundry cleaning services in KL, PJ! ",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <SPWashFoldPage />   
        </>
    );
}

export default SPWashAndFold;