import React from 'react';
import '../App.css';
import './ServicesBody.css';
import './SP All CSS.css';


function SPSneakerCarePage() {

    // const sneakercare1 = require('../images/SneakerCare/Premium-Wash.png');
    // const sneakercare2 = require('../images/SneakerCare/Blackout.png');
    // const sneakercare3 = require('../images/SneakerCare/Restoration.png');
    // const sneakercare4 = require('../images/SneakerCare/Repainting.png');
    // const sneakercare5 = require('../images/SneakerCare/Water-Repellant-Application.png');

    return (
        <>
            <div className='wrap-snkcarehero'>
                <div className='servprichero-container'>
                    <div className='servpric-hero-heading'>
                        <h1>Sneaker Care and Restoration</h1>
                    </div>

                    <div className='servpric-hero-desc'>
                        {/* <p className='servpric-hero-desc-1'>We are Expert in Washing</p> */}
                        <p className='servpric-hero-desc-2'>We are dedicated in transforming your beloved shoes by providing them with our signature cleaning services.</p>
                    </div>
                </div>
            </div>

            <div className="wrap-serv-tabs">
                <div class="services-tabs">
                    <ol class="tab-list">
                        <a href="/services-and-pricing/" class="tab-list-item">Our Services</a>
                        <a href="/services-and-pricing/sneaker-care" class="tab-list-item tab-list-active" target="_top">Sneaker Care</a>
                        <a href="/services-and-pricing/wash-and-fold" class="tab-list-item" target="_top">Wash & Fold</a>
                        <a href="/services-and-pricing/dry-cleaning" class="tab-list-item" target="_top">Dry Cleaning</a>
                        <a href="/services-and-pricing/bag-cleaning" class="tab-list-item" target="_top">Bag Cleaning</a>
                        <a href="/services-and-pricing/other-services" class="tab-list-item" target="_top">Other Services</a>
                    </ol>
                </div>
            </div>

            <div className='wrap-sneakercare' id='sneakercare' >

                <div className='serv-sneakercare-banner'>
                    <div className='serv-body-heading'>
                        <h2>Professional Sneaker Cleaning & Shoe Wash Service In Malaysia for more than 10 years</h2>
                        <p>Wash La! is the Leading One-stop shop for Professional and Premium Shoe Wash & Sneaker cleaning services in Petaling Jaya and Kuala Lumpur. Deep clean your shoes and sneakers to make them beautiful again!</p>
                    </div>
                </div>

                <div className='serv-content-container'>
                    <h2>The Wash La! Shoe Care Process</h2>
                    <p>The laces are removed from the shoes, soaked in warm water and our specialty cleaner. They are swirled around, rinsed, and repeat in the bowl. Next, excess dirt are removed from your shoes gently by using a soft brush or damp towel. A specialty solution is then applied onto the bristles of a wet brush which is then dipped in the water. The shoes are then scrubbed until the cleaner start to foam up. Our specialist will remove excess soap and suds with a premium microfiber towel.</p>
                    <p>We use different cleaning liquids and different cleaning methods depending on the shoes and sneakers’ (boots, high heels, sandals, slippers, and more.) to ensure your shoes get personalized and thorough cleaning. We use only 100% handwash standardized processes to take the best care of your shoes so they can have a sparkling clean and refreshed look.</p>
                </div>

                <div className='serv-content-container'>
                    <h2>Quality Control</h2>
                    <p>All cleaned shoes will be sent to our Quality Control team. The shoes are then thoroughly inspected for any spots that might have been missed. If a spot has been missed, the shoes will be sent back to the cleaning station for a second wash. Once the shoes meet our strict quality controls, it will then be wrapped and sent out for delivery.</p>
                </div>

                <div className='serv-content-container'>
                    <h2>Sneaker Care Pricing</h2>
                    <p>We specialize in cleaning all types of footwear - shoes and sneakers, you name it. We take pride in providing our customers with high-quality service and personalized care to achieve the highest customer satisfaction.</p>

                    <div className='serv-info-container'>
                        <div className='serv-cards-item'>
                            <div className='serv-info-cards'>
                                <div className='serv-info-heading'>
                                    <img src="../assets/images/SneakerCare/Premium-Wash.png" className='serv-info-logo' alt='Premium Wash'/>
                                    <h3>Premium Wash/Dry Clean</h3>
                                </div>
                                <div className='serv-info-pricing'>
                                    <div className='serv-info-desc'>
                                        <p>Premium Wash/Dry Clean</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>35</p>
                                    </div>
                                </div>
                            </div>

                            <div className='serv-info-cards'>
                                <div className='serv-info-heading'>
                                    <img src="../assets/images/SneakerCare/Restoration.png" className='serv-info-logo' alt='Restoration'/>
                                    <h3>Restoration</h3>
                                </div>
                                <div className='serv-info-pricing'>
                                    <div className='serv-info-desc'>
                                        <p>Restoration</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>150</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>• Our specialist will contact you for further information upon order confirmation </p>
                                    </div>
                                </div>
                            </div>

                            <div className='serv-info-cards'>
                                <div className='serv-info-heading'>
                                    <img src="../assets/images/SneakerCare/Blackout.png" className='serv-info-logo' alt='Blackout'/>
                                    <h3>Blackout</h3>
                                </div>
                                <div className='serv-info-pricing'>
                                    <div className='serv-info-desc'>
                                        <p>Full Blackout</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>200</p>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        
                        <div className='serv-cards-item'>
                            <div className='serv-info-cards'>
                                <div className='serv-info-heading'>
                                    <img src="../assets/images/SneakerCare/Repainting.png" className='serv-info-logo' alt='Repainting'/>
                                    <h3>Repainting</h3>
                                </div>
                                <div className='serv-info-pricing'>
                                    <div className='serv-info-desc'>
                                        <p>Repaint: Sole Re-Paint</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>20</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Repaint: Minor Touch-Up</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>80</p>
                                    </div>
                                </div>
                                <div className='serv-info-pricing-2'>
                                    <div className='serv-info-desc'>
                                        <p>Full Repainting</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>120</p>
                                    </div>
                                </div>
                            </div>

                            <div className='serv-info-cards'>
                                <div className='serv-info-heading'>
                                    <img src="../assets/images/SneakerCare/Water-Repellant-Application.png" className='serv-info-logo' alt='Water Repellant Application'/>
                                    <h3>Water Repellant Application</h3>
                                </div>
                                <div className='serv-info-pricing'>
                                    <div className='serv-info-desc'>
                                        <p>Water Repellant Application</p>
                                    </div>
                                    <div className='serv-info-price'>
                                        <p className='serv-price-1'>MYR</p>
                                        <p className='serv-price-2'>30</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    
    );
}

export default SPSneakerCarePage;