import React, { useEffect } from 'react';
import '../../App.css';
import SPOtherServPage from '../SP OtherServ Page';
import ContactUs03 from '../ContactUs03';
import DocumentMeta from "react-document-meta";

function SPOtherServices() {

    useEffect(() => {
        document.title = "Curtain Cleaning Service KL | Golf Bag Cleaning Malaysia";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/services-and-pricing/other-services"
            }
        },

        meta: {
            name: {
                title: "Curtain Cleaning Service KL | Golf Bag Cleaning Malaysia",                
                description: "Any items or fabrics that you can think of, we can do the magic cleaning for you – curtains, golf bags, back bags, backpacks, carpets, tablecloths, duvets and etc. ",
                canonical:"https://washla.my/services-and-pricing/other-services",
                keywords: "clean, cleaning, cleaning service, back bag, wash, restoration, delivery service, Malaysia, care, golf bag, curtain, carpet, KL, backpack, bag",
            },
            property: {
                "og:title": "Cleaning Services Kuala Lumpur, Petaling Jaya",
                "og:description": "Any items or fabrics that you can think of, we can do the magic cleaning for you – curtains, golf bags, back bags, backpacks, carpets, tablecloths, duvets and etc. ",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <SPOtherServPage />
            <ContactUs03 />        
        </>
    );
}

export default SPOtherServices;