import React, { useEffect } from 'react';
import '../../App.css';
import SPMainPage from '../SP Main Page';
import ContactUs02 from '../ContactUs02';
import DocumentMeta from "react-document-meta";

function Services() {

    useEffect(() => {
        document.title = "Shoe Cleaning Services In Petaling Jaya";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/services-and-pricing"
            }
        },
        
        meta: {
            name: {
                title: "Shoe Cleaning Services In Petaling Jaya",                
                description: "Wash La! has a team of in-house specialists and experts to provide you with the best shoe cleaning and sneaker care service in and around Petaling Jaya and KL.",
                canonical:"https://washla.my/services-and-pricing",
                keywords: "cleaning, repair, sneaker, cleaning service, shoe, dry cleaning, bag cleaning, sneaker care, cleaning restoration, Malaysia",
            },
            property: {
                "og:title": "Shoe Cleaning Services In Petaling Jaya",
                "og:description": "Wash La! has a team of in-house specialists and experts to provide you with the best shoe cleaning and sneaker care service in and around Petaling Jaya and KL.",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <SPMainPage />
            <ContactUs02 />        
        </>
    );
}

export default Services;