import React from 'react';
import './DownloadApp.css';


// const applogo = require('../images/Home/Washla-favicon.png');
// const appstore = require('../images/Landing/Landing-29.jpg');
// const googleplay = require('../images/Landing/Landing-28.jpg');
// const appgallery = require('../images/Landing/Landing-30.jpg');
// const displayimg = require('../images/Home/washla-laundry-mobile-apps.png');


function DownloadApp() {
    return (
        <div className='wrap-downloadapp' id='downloadapp'>
            <div className='downloadapp-heading'>
                <div className='downloadapp-logo'>
                    <img src="assets/images/Home/Washla-favicon.png" className='d-app-logo' alt='Washla favicon' />
                </div>

                <div className='downloadapp-desc'>
                    <h2>Interested with us?</h2>
                    <p>Download our app and experience now!</p>
                </div>

                {/* <div className='downloadapp-btn-item'>
                    <button className='download-btn'>Download App</button>
                </div> */}
            </div>

            <div className='downloadapp-contents'>
                <div className='downloadapp-info'>
                    <p>Washla App now is available on Washla iOS and Android download, let’s get started!</p>
                </div>
                
                <div className='downloadapp-img'>
                    <a href='https://apps.apple.com/my/app/wash-la/id1582400036' target='_blank' rel='noreferrer noopener'><img src="../assets/images/Landing/Landing-29.jpg" className='d-app-img' alt='WashLa AppStore Download'/></a>
                    <a href='https://play.google.com/store/apps/details?id=com.washla.my' target='_blank' rel='noreferrer noopener'><img src="../assets/images/Landing/Landing-28.jpg" className='d-app-img' alt='WashLa Google Download'/></a>
                    {/* <a href='/' target='blank'><img src="assets/images/Landing/Landing-29.jpg" className='d-app-img' /></a> */}
                </div>
            </div>

            <div className='downloadapp-display'>
                <div className='display-bg'>
                    <img src="assets/images/Home/washla-laundry-mobile-apps.png" className='display-img' alt='Washla laundry mobile apps'/> 
                </div>
            </div>
        </div>
      );
}

export default DownloadApp;