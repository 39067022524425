import React, { useEffect } from 'react';
import '../../App.css';
import ContactUs from '../ContactUs';
import CareersBody from '../CareersBody';
import DocumentMeta from "react-document-meta";

function Careers() {

    useEffect(() => {
        document.title = "Jobs at WashLa! | Wash La! Careers";  
    }, []);

    const meta = {
        link: {
            rel: {
                canonical:"https://washla.my/careers"
            }
        },

        meta: {
            name: {
                title: "Jobs at WashLa! | Wash La! Careers",                
                description: "Find out all about careers at WashLa!, for everyone from fresh graduates to those with years of experience. ",
                canonical:"https://washla.my/careers",
                keywords: "clean, cleaning, cleaning service, bag, restoration, delivery service, malaysia, care, leather bag, treatment, blog, article, news",
            },
            property: {
                "og:title": "Jobs at WashLa! | Wash La! Careers",
                "og:description": "Find out all about careers at WashLa!, for everyone from fresh graduates to those with years of experience. ",
            }
        }
    };

    return (
        <>
            <DocumentMeta {...meta} /> 

            <CareersBody />
            <ContactUs />    
        </>
    );
}

export default Careers;